import "./index.css";
import { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  BankOutlined,
  ProjectOutlined,
  TagOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, theme, Flex } from "antd";
import { routeData } from "../../services/routers";
const { Header, Sider, Content, Footer } = Layout;

const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(true);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const { pathname } = useLocation();
  const [selectedKeys, setSelectedKeys] = useState([pathname]);

  const navigate = useNavigate();
  const handleClickMenu = ({ key }) => {
    navigate(`${key}`);
  };

  useEffect(() => {
    setSelectedKeys([pathname]);
  }, [pathname]);

  const menus = [
    {
      key: `/${routeData.dashboard}`,
      icon: <HomeOutlined />,
      label: "Dashboard",
    },
    {
      key: `/${routeData.stocks}`,
      icon: <TagOutlined />,
      label: "Cổ phiếu",
    },
    {
      key: `/${routeData.funds}`,
      icon: <BankOutlined />,
      label: "Quỹ đầu tư",
    },
    {
      key: `/${routeData.fundsPortfolio}`,
      icon: <ProjectOutlined />,
      label: "Danh mục quỹ",
    },
  ];

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          background: colorBgContainer,
          position: "fixed",
          top: 0,
          bottom: 0,
          zIndex: 100,
        }}>
        <Flex style={{ paddingLeft: "0.3rem" }} align="center">
          <img style={{ width: "4rem" }} alt="" src="./fifree-logo.png" />
          {!collapsed && <h1 style={{ margin: 0, fontSize: "1.6rem" }}>Fifree</h1>}
        </Flex>
        <Menu theme="light" mode="inline" selectedKeys={selectedKeys} onClick={handleClickMenu} items={menus} />
      </Sider>
      <Layout style={{ paddingLeft: collapsed ? "80px" : "200px" }}>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
        </Header>
        <Content
          className="fifree-content-container"
          style={{
            background: "none",
            borderRadius: borderRadiusLG,
          }}>
          <Outlet />
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}>
          Fifree ©{new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};
export default MainLayout;
