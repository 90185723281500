import { createContext, useState, useContext, useEffect, useCallback } from "react";
import { fetchDataWithGroupOrder } from "../services/realmServices";

const FundsContext = createContext();

export const FundsProvider = ({ children }) => {
  const [fundsContext, setFundsContext] = useState();

  const fetchDataFundsFromRealm = useCallback(async () => {
    try {
      const queryObject = [{ $match: { status: 1 } }];
      const result = await fetchDataWithGroupOrder("funds", queryObject);
      const converedData = result.map((e) => {
        return {
          value: e.fund_code,
          label: `${e.organization_name} - ${e.fund_code}`,
          ...e,
        };
      });
      setFundsContext(converedData);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchDataFundsFromRealm();
  }, [fetchDataFundsFromRealm]);

  return (
    <FundsContext.Provider
      value={{
        fundsContext,
      }}>
      {children}
    </FundsContext.Provider>
  );
};

export const useFundsContext = () => useContext(FundsContext);
