import ReactECharts from "echarts-for-react";

const EChartCustom = ({ data, type = "line", barWidth = 3, ...props }) => {
  const options = {
    tooltip: { trigger: "item", z: 1000 },
    xAxis: {
      data: data ? data.xAxis : [],
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    series: [
      {
        type: type,
        areaStyle: {},
        data: data ? data.series : [0],
        barWidth: barWidth,
      },
    ],
  };

  return <ReactECharts option={options} {...props} />;
};

export default EChartCustom;
