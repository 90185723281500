import "./App.css";
import { Routes, Route } from "react-router-dom";
import { routeData } from "./services/routers";
import MainLayout from "./components/layout";
import Dashboard from "./pages/Dashboard";
import Funds from "./pages/Funds";
import FundsPortfolio from "./pages/FundsPortfolio";
import Stocks from "./pages/Stocks";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path={`/`} element={<Dashboard />} />
        <Route path={`/${routeData.dashboard}`} element={<Dashboard />} />
        <Route path={`/${routeData.stocks}`} element={<Stocks />} />
        <Route path={`/${routeData.fundsPortfolio}`} element={<FundsPortfolio />} />
        <Route path={`/${routeData.funds}`} element={<Funds />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
