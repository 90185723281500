export const mongoCollections = {
  funds: "funds",
  fundsPortfolio: "funds_portfolio",
};

export const mongoPivotScript = [
  { $match: { year: 2024, "data_fund.fund_code": "DCDS" } },
  {
    $group: {
      _id: {
        stock_code: "$stock_code",
        fund_code: "$data_fund.fund_code",
        month: "$month",
      },
      totalQuantity: { $sum: "$quantity" },
    },
  },

  {
    $group: {
      _id: {
        stock_code: "$_id.stock_code",
        fund_code: "$_id.fund_code",
      },
      fundData: {
        $push: {
          month: { $toString: "$_id.month" },
          quantity: "$totalQuantity",
        },
      },
    },
  },

  {
    $project: {
      _id: 0,
      stock_code: "$_id.stock_code",
      fund_code: "$_id.fund_code",
      fundData: {
        $arrayToObject: {
          $map: {
            input: "$fundData",
            as: "fd",
            in: { k: "$$fd.month", v: "$$fd.quantity" },
          },
        },
      },
    },
  },

  {
    $replaceRoot: {
      newRoot: {
        $mergeObjects: ["$$ROOT", "$fundData"],
      },
    },
  },

  {
    $addFields: {
      1: { $ifNull: ["$1", null] },
      2: { $ifNull: ["$2", null] },
      3: { $ifNull: ["$3", null] },
      4: { $ifNull: ["$4", null] },
      5: { $ifNull: ["$5", null] },
      6: { $ifNull: ["$6", null] },
      7: { $ifNull: ["$7", null] },
      8: { $ifNull: ["$8", null] },
      9: { $ifNull: ["$9", null] },
      10: { $ifNull: ["$10", null] },
      11: { $ifNull: ["$11", null] },
      12: { $ifNull: ["$12", null] },
    },
  },

  {
    $project: { fundData: 0 },
  },
];

export const mongoFundPortfoliosPivotScript = [
  {
    $group: {
      _id: {
        stock_code: "$stock_code",
        fund_code: "$data_fund.fund_code",
        month: "$month",
      },
      totalQuantity: { $sum: "$quantity" },
      totalPrice: { $sum: "$market_price" },
      totalPortfolioRate: { $sum: "$portfolio_rate" },
    },
  },
  {
    $group: {
      _id: {
        stock_code: "$_id.stock_code",
        fund_code: "$_id.fund_code",
      },
      fundData: {
        $push: {
          month: { $toString: "$_id.month" },
          quantity: "$totalQuantity",
          market_price: "$totalPrice",
          portfolio_rate: "$totalPortfolioRate",
        },
      },
    },
  },
  {
    $project: {
      _id: 0,
      stock_code: "$_id.stock_code",
      fund_code: "$_id.fund_code",
      fundData: {
        $arrayToObject: {
          $map: {
            input: "$fundData",
            as: "fd",
            in: {
              k: "$$fd.month",
              v: {
                quantity: "$$fd.quantity",
                market_price: "$$fd.market_price",
                portfolio_rate: "$$fd.portfolio_rate",
              },
            },
          },
        },
      },
    },
  },
  {
    $replaceRoot: {
      newRoot: {
        $mergeObjects: ["$$ROOT", "$fundData"],
      },
    },
  },
  {
    $addFields: {
      1: { $ifNull: ["$1", null] },
      2: { $ifNull: ["$2", null] },
      3: { $ifNull: ["$3", null] },
      4: { $ifNull: ["$4", null] },
      5: { $ifNull: ["$5", null] },
      6: { $ifNull: ["$6", null] },
      7: { $ifNull: ["$7", null] },
      8: { $ifNull: ["$8", null] },
      9: { $ifNull: ["$9", null] },
      10: { $ifNull: ["$10", null] },
      11: { $ifNull: ["$11", null] },
      12: { $ifNull: ["$12", null] },
    },
  },
  {
    $project: { fundData: 0 },
  },
];

export const mongoFundsWithCountStockScript = [
  {
    $group: {
      _id: {
        fund_code: "$data_fund.fund_code",
        month: "$month",
      },
      countStock: { $sum: 1 },
      totalQuantity: { $sum: "$quantity" },
      totalPrice: { $sum: "$market_price" },
      totalPortfolioRate: {
        $sum: "$portfolio_rate",
      },
    },
  },

  {
    $group: {
      _id: {
        stock_code: "$_id.stock_code",
        fund_code: "$_id.fund_code",
      },
      fundData: {
        $push: {
          month: { $toString: "$_id.month" },
          count_stock: "$countStock",
          quantity: "$totalQuantity",
          market_price: "$totalPrice",
          portfolio_rate: "$totalPortfolioRate",
        },
      },
    },
  },

  {
    $project: {
      _id: 0,
      stock_code: "$_id.stock_code",
      fund_code: "$_id.fund_code",
      fundData: {
        $arrayToObject: {
          $map: {
            input: "$fundData",
            as: "fd",
            in: {
              k: "$$fd.month",
              v: {
                count_stock: "$$fd.count_stock",
                quantity: "$$fd.quantity",
                market_price: "$$fd.market_price",
                portfolio_rate: "$$fd.portfolio_rate",
              },
            },
          },
        },
      },
    },
  },

  {
    $replaceRoot: {
      newRoot: {
        $mergeObjects: ["$$ROOT", "$fundData"],
      },
    },
  },

  {
    $addFields: {
      1: { $ifNull: ["$1", null] },
      2: { $ifNull: ["$2", null] },
      3: { $ifNull: ["$3", null] },
      4: { $ifNull: ["$4", null] },
      5: { $ifNull: ["$5", null] },
      6: { $ifNull: ["$6", null] },
      7: { $ifNull: ["$7", null] },
      8: { $ifNull: ["$8", null] },
      9: { $ifNull: ["$9", null] },
      10: { $ifNull: ["$10", null] },
      11: { $ifNull: ["$11", null] },
      12: { $ifNull: ["$12", null] },
    },
  },

  {
    $project: { fundData: 0 },
  },
];
