const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const formatToPercentage = (number) => {
  return `${(number * 100).toFixed(2)}`;
};

const getStocksDataFromCafeF = async () => {
  const totalPages = 20;
  const data = [];

  let headers = {
    Accept: "*/*",
    "Content-Type": "application/json",
    Origin: "https://s.cafef.vn",
    Referer: "https://s.cafef.vn",
    credentials: "include",
  };

  try {
    for (let i = 0; i < totalPages; i++) {
      const skip = i * 20;
      const url = `https://s.cafef.vn/ajax/pagenew/databusiness/congtyniemyet.ashx?centerid=1&skip=${skip}&take=20&major=0`;

      const { Data, Error } = await fetch(url, {
        method: "GET",
        headers: headers,
      });

      if (Error) {
        console.log(Error);
      } else {
        data.push(Data);
      }
    }

    console.log(data);
  } catch (error) {
    console.log(error);
  }
};

export { formatNumberWithCommas, formatToPercentage, getStocksDataFromCafeF };
