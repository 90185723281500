export const colors = [
  "green",
  "blue",
  "purple",
  "geekblue",
  "magenta",
  "pink",
  "red",
  "yellow",
  "orange",
  "cyan",
  "volcano",
  "gold",
  "lime",
];
