import { useState, useEffect, useCallback } from "react";
import { Table, Select, Space, Spin, Flex, InputNumber } from "antd";
import { fetchDataWithGroupOrder } from "../../../services/realmServices";
import { formatNumberWithCommas, formatToPercentage } from "../../../services/common";
import { useFundsContext } from "../../../context/FundsContext";
import { mongoCollections, mongoFundsWithCountStockScript } from "../../../utils/mongoScript";
const queryTypes = [
  {
    value: "count_stock",
    label: "SLCP",
  },
  {
    value: "quantity",
    label: "Tổng SL CP (n)",
  },
  {
    value: "market_price",
    label: "Gía thị trường (n)",
  },
  {
    value: "portfolio_rate",
    label: "Tỉ trọng (%)",
  },
];

const AdvanceFunds = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const { fundsContext } = useFundsContext();
  const [sortedInfo, setSortedInfo] = useState({});

  const defaultYear = new Date().getFullYear();
  const [year, setYear] = useState(defaultYear);
  const [fundSelected, setFundSelected] = useState(null);
  const [type, setType] = useState("count_stock");

  const fetchDataFromRealm = useCallback(async (year, fundCode) => {
    try {
      let queryObject = mongoFundsWithCountStockScript;

      const filterBy = { $match: {} };
      let hasFilter = false;

      if (year) {
        filterBy.$match.year = year;
        hasFilter = true;
      }

      if (fundCode) {
        filterBy.$match[`data_fund.fund_code`] = fundCode;
        hasFilter = true;
      }

      if (hasFilter) queryObject = [filterBy, ...queryObject];

      setLoading(true);
      const result = await fetchDataWithGroupOrder(mongoCollections.fundsPortfolio, queryObject);
      if (result.length === 0) {
        setData([]);
      }

      const converedData = result.map((e, index) => {
        for (let i = 1; i <= 12; i++) {
          if (e[`${i}`] && i > 0 && e[`${i - 1}`]) {
            e[`${i}`].prev = e[`${i - 1}`];
          }
        }

        return {
          ...e,
          key: index.toString(),
          index: index + 1,
        };
      });
      setData(converedData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDataFromRealm(year, fundSelected);
  }, [year, fundSelected, fetchDataFromRealm]);

  const handleChange = (_pagination, _filters, sorter) => {
    setSortedInfo(sorter);
  };

  const getMonthsHeader = () => {
    const header = [];
    for (let i = 1; i <= 12; i++) {
      header.push({
        title: `T${i.toString()}`,
        dataIndex: `${i.toString()}`,
        key: `${i.toString()}`,
        align: "center",
        ellipsis: true,
        width: "7%",
        render: (data) => {
          if (type === "count_stock") {
            return data ? <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>{data[`${type}`]}</span> : "x";
          }

          if (!data)
            return (
              <Flex vertical>
                <span>0</span>
                <span style={{ color: "white", fontSize: "0.6rem" }}>0</span>
              </Flex>
            );
          let dataShow = data[`${type}`];

          if (type === "quantity") dataShow = formatNumberWithCommas(Math.floor(dataShow / 1000));
          else if (type === "portfolio_rate") {
            dataShow = formatToPercentage(dataShow);
          } else if (type === "market_price") {
            dataShow = formatNumberWithCommas(Math.floor(dataShow / 1000));
          }

          if (data.prev) {
            const change = Math.floor((data[`${type}`] - data.prev[`${type}`]) / 1000);
            if (change > 0) {
              return (
                <Flex vertical>
                  <span>{dataShow}</span>
                  <span style={{ color: "green", fontSize: "0.6rem" }}>+{change}</span>
                </Flex>
              );
            } else if (change === 0) {
              return (
                <Flex vertical>
                  <span>{dataShow}</span>
                  <span style={{ color: "white", fontSize: "0.6rem" }}>0</span>
                </Flex>
              );
            } else {
              return (
                <Flex vertical>
                  <span>{dataShow}</span>
                  <span style={{ color: "red", fontSize: "0.6rem" }}>{change}</span>
                </Flex>
              );
            }
          } else {
            return (
              <Flex vertical>
                <span>{dataShow}</span>
                <span style={{ color: "green", fontSize: "0.6rem" }}>+{dataShow}</span>
              </Flex>
            );
          }
        },
      });
    }

    return header;
  };

  const monthsHeader = getMonthsHeader();

  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      align: "center",
      ellipsis: true,
      width: "6%",
      fixed: "left",
      render: (_text, _record, index) => index + 1,
    },
    {
      title: "Quỹ",
      dataIndex: "fund_code",
      key: "fund_code",
      align: "center",
      ellipsis: true,
      width: "10%",
      fixed: "left",
      sorter: (a, b) => {
        return a.fund_code ? a.fund_code.localeCompare(b.fund_code) : 1;
      },
      sortOrder: sortedInfo.columnKey === "fund_code" ? sortedInfo.order : null,
    },

    ...monthsHeader,
  ];

  return (
    <>
      <Flex justify="space-between">
        <Space size={12}>
          <InputNumber defaultValue={defaultYear} onChange={(value) => setYear(value)} />
          <Select
            placeholder="Loại thông tin"
            defaultValue={"count_stock"}
            onChange={(value) => setType(value)}
            options={queryTypes}
            style={{ width: 250 }}
          />
          <Select
            allowClear
            showSearch
            placeholder="Quỹ đầu tư"
            onChange={(value) => setFundSelected(value)}
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            options={fundsContext}
            style={{ width: 250 }}
          />
          {loading && <Spin />}
        </Space>

        <h4>Tổng: {data.length}</h4>
      </Flex>
      <Table
        columns={columns}
        dataSource={data}
        onChange={handleChange}
        className="ant-border-space"
        pagination={{
          pageSize: 50,
          position: ["bottomCenter"],
        }}
        scroll={{
          y: 550,
        }}
      />
    </>
  );
};
export default AdvanceFunds;
