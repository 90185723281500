export const trade_centers_data = [
  {
    TradeCenterId: 1,
    TradeCenterName: "HSX - Ho Chi Minh",
  },
  {
    TradeCenterId: 2,
    TradeCenterName: "HNI - Ha Noi",
  },
  {
    TradeCenterId: 9,
    TradeCenterName: "UpCom",
  },
  {
    TradeCenterId: 8,
    TradeCenterName: "OTC",
  },
];

export const category_data = [
  {
    categoryId: 347,
    categoryName: "Công nghệ",
  },
  {
    categoryId: 339,
    categoryName: "Hàng tiêu dùng",
  },
  {
    categoryId: 344,
    categoryName: "Nguyên vật liệu",
  },
  {
    categoryId: 338,
    categoryName: "Nông nghiệp",
  },
  {
    categoryId: 341,
    categoryName: "Tài chính",
  },
  {
    categoryId: 345,
    categoryName: "Bất động sản và Xây dựng",
  },
  {
    categoryId: 348,
    categoryName: "Viễn thông",
  },
  {
    categoryId: 343,
    categoryName: "Công nghiệp",
  },
  {
    categoryId: 346,
    categoryName: "Dịch vụ",
  },
  {
    categoryId: 342,
    categoryName: "Y tế",
  },
  {
    categoryId: 340,
    categoryName: "Năng lượng",
  },
  {
    categoryId: 350,
    categoryName: "Doanh nghiệp Upcom",
  },
];

export const all_stocks_data = [
  {
    Symbol: "24H",
    TradeCenterId: 8,
    CategoryId: 347,
    CompanyName: "CTCP Quảng cáo trực tuyến 24H",
    CategoryName: "Công nghệ",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "A32",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần 32",
    CategoryName: "Hàng tiêu dùng",
    Price: 34.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "AAA",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Nhựa An Phát Xanh",
    CategoryName: "Nguyên vật liệu",
    Price: 8.28,
    TotalCompany: 1659,
  },
  {
    Symbol: "AAH",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Hợp Nhất",
    CategoryName: null,
    Price: 3.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "AAM",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Thủy sản Mekong",
    CategoryName: "Nông nghiệp",
    Price: 6.89,
    TotalCompany: 1659,
  },
  {
    Symbol: "AAS",
    TradeCenterId: 9,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Chứng khoán SmartInvest",
    CategoryName: "Tài chính",
    Price: 6.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "AAT",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Tập đoàn Tiên Sơn Thanh Hóa",
    CategoryName: "Hàng tiêu dùng",
    Price: 3.38,
    TotalCompany: 1659,
  },
  {
    Symbol: "AAV",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần AAV Group",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "ABA",
    TradeCenterId: 8,
    CategoryId: 349,
    CompanyName: null,
    CategoryName: null,
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "ABB",
    TradeCenterId: 9,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại cổ phần An Bình",
    CategoryName: "Tài chính",
    Price: 7.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "ABC",
    TradeCenterId: 9,
    CategoryId: 348,
    CompanyName: "Công ty cổ phần Truyền thông VMG",
    CategoryName: "Viễn thông",
    Price: 11.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "ABF",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Quản lý quỹ đầu tư Chứng khoán An Bình",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "ABI",
    TradeCenterId: 9,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Bảo hiểm Ngân hàng Nông nghiệp",
    CategoryName: "Tài chính",
    Price: 25.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "ABR",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Đầu tư Nhãn hiệu Việt",
    CategoryName: "Hàng tiêu dùng",
    Price: 13.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "ABS",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Dịch vụ Nông nghiệp Bình Thuận",
    CategoryName: "Nông nghiệp",
    Price: 3.83,
    TotalCompany: 1659,
  },
  {
    Symbol: "ABT",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Xuất nhập khẩu Thủy sản Bến Tre",
    CategoryName: "Nông nghiệp",
    Price: 39.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "ABW",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "CTCP Chứng khoán An Bình",
    CategoryName: null,
    Price: 8.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "ACB",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại Cổ phần Á Châu",
    CategoryName: "Tài chính",
    Price: 24.35,
    TotalCompany: 1659,
  },
  {
    Symbol: "ACC",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Xây dựng Bình Dương ACC",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 13.65,
    TotalCompany: 1659,
  },
  {
    Symbol: "ACE",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Bê tông ly tâm An Giang",
    CategoryName: "Công nghiệp",
    Price: 36.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "ACG",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Gỗ An Cường",
    CategoryName: "Hàng tiêu dùng",
    Price: 40.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "ACL",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Xuất nhập khẩu Thủy sản Cửu Long An Giang",
    CategoryName: "Nông nghiệp",
    Price: 11.75,
    TotalCompany: 1659,
  },
  {
    Symbol: "ACM",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Tập đoàn Khoáng sản Á Cường",
    CategoryName: "Nguyên vật liệu",
    Price: 0.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "ACS",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây lắp Thương mại 2",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 9.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "ACV",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Tổng công ty Cảng hàng không Việt Nam - CTCP",
    CategoryName: "Công nghiệp",
    Price: 122,
    TotalCompany: 1659,
  },
  {
    Symbol: "ADC",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Mĩ thuật và Truyền thông",
    CategoryName: "Dịch vụ",
    Price: 20,
    TotalCompany: 1659,
  },
  {
    Symbol: "ADG",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Clever Group",
    CategoryName: "Dịch vụ",
    Price: 11.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "ADP",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Sơn Á Đông",
    CategoryName: "Nguyên vật liệu",
    Price: 28.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "ADS",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Damsan",
    CategoryName: "Hàng tiêu dùng",
    Price: 8.78,
    TotalCompany: 1659,
  },
  {
    Symbol: "AFM",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Quản lý quỹ AIC",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "AFX",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Xuất nhập khẩu Nông sản Thực phẩm An Giang",
    CategoryName: "Nông nghiệp",
    Price: 7.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "AG1",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần 28.1",
    CategoryName: "Hàng tiêu dùng",
    Price: 9.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "AGF",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Xuất nhập khẩu Thủy sản An Giang",
    CategoryName: "Nông nghiệp",
    Price: 2.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "AGG",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Phát triển Bất động sản An Gia",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 14.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "AGM",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Xuất nhập khẩu An Giang",
    CategoryName: "Nông nghiệp",
    Price: 3.22,
    TotalCompany: 1659,
  },
  {
    Symbol: "AGP",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty cổ phần Dược phẩm Agimexpharm",
    CategoryName: "Y tế",
    Price: 38.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "AGR",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán Agribank",
    CategoryName: "Tài chính",
    Price: 16.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "AGX",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Thực phẩm Nông sản Xuất khẩu Sài Gòn",
    CategoryName: "Hàng tiêu dùng",
    Price: 74.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "AIC",
    TradeCenterId: 9,
    CategoryId: 341,
    CompanyName: "Tổng Công ty cổ phần Bảo hiểm Hàng không",
    CategoryName: "Tài chính",
    Price: 10.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "AIG",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Nguyên liệu Á Châu AIG",
    CategoryName: null,
    Price: 56,
    TotalCompany: 1659,
  },
  {
    Symbol: "ALC",
    TradeCenterId: 8,
    CategoryId: 340,
    CompanyName: "CTCP Âu Lạc",
    CategoryName: "Năng lượng",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "ALT",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Văn hóa Tân Bình",
    CategoryName: "Dịch vụ",
    Price: 13.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "ALV",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng ALVICO",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "AMC",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Khoáng sản Á Châu",
    CategoryName: "Nguyên vật liệu",
    Price: 16.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "AME",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Alphanam E&C",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5,
    TotalCompany: 1659,
  },
  {
    Symbol: "AMP",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Armephaco",
    CategoryName: "Y tế",
    Price: 11.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "AMS",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Cơ khí xây dựng AMECC",
    CategoryName: "Công nghiệp",
    Price: 9.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "AMV",
    TradeCenterId: 2,
    CategoryId: 342,
    CompanyName: "CTCP Sản xuất Kinh doanh Dược và Trang thiết bị Y",
    CategoryName: "Y tế",
    Price: 1.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "ANT",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Rau quả Thực phẩm An Giang",
    CategoryName: "Nông nghiệp",
    Price: 19.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "ANV",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Nam Việt",
    CategoryName: "Nông nghiệp",
    Price: 18.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "APC",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Chiếu xạ An Phú",
    CategoryName: "Nông nghiệp",
    Price: 6.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "APF",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Nông sản thực phẩm Quảng Ngãi",
    CategoryName: "Nông nghiệp",
    Price: 51.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "APG",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán APG",
    CategoryName: "Tài chính",
    Price: 9.05,
    TotalCompany: 1659,
  },
  {
    Symbol: "APH",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Tập đoàn An Phát Holdings",
    CategoryName: "Nguyên vật liệu",
    Price: 6.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "API",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Đầu tư Châu Á - Thái Bình Dương",
    CategoryName: "Tài chính",
    Price: 7.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "APL",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "CTCP Cơ khí và Thiết bị áp lực - VVMI",
    CategoryName: "Công nghiệp",
    Price: 16.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "APP",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Phát triển Phụ gia và Sản phẩm Dầu mỏ",
    CategoryName: "Nguyên vật liệu",
    Price: 8.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "APS",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán Châu Á – Thái Bình Dương",
    CategoryName: "Tài chính",
    Price: 6.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "APT",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "CTCP Kinh doanh Thủy Hải Sản Sài Gòn",
    CategoryName: "Nông nghiệp",
    Price: 2.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "ARM",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Xuất nhập khẩu Hàng không",
    CategoryName: "Dịch vụ",
    Price: 29.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "ASC",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán ASC",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "ASG",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Cổ phiếu Công ty Cổ phần Tập đoàn ASG",
    CategoryName: "Công nghiệp",
    Price: 18.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "ASM",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tập đoàn Sao Mai",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 8.62,
    TotalCompany: 1659,
  },
  {
    Symbol: "ASP",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Tập đoàn Dầu khí An Pha",
    CategoryName: "Năng lượng",
    Price: 4.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "AST",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Dịch vụ Hàng không Taseco",
    CategoryName: "Công nghiệp",
    Price: 54.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "ATA",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần NTACO",
    CategoryName: "Nông nghiệp",
    Price: 0.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "ATB",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần An Thịnh",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "ATG",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần An Trường An",
    CategoryName: "Nguyên vật liệu",
    Price: 2.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "ATS",
    TradeCenterId: 2,
    CategoryId: 342,
    CompanyName: "Công ty cổ phần Tập đoàn Đầu tư ATS",
    CategoryName: "Y tế",
    Price: 13.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "AVC",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Thuỷ điện A Vương",
    CategoryName: "Năng lượng",
    Price: 55.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "AVG",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP Phân bón Quốc tế Âu Việt",
    CategoryName: "Dịch vụ",
    Price: 31.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "BAB",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại cổ phần Bắc Á",
    CategoryName: "Tài chính",
    Price: 11.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "BAF",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Nông nghiệp BaF Việt Nam",
    CategoryName: "Nông nghiệp",
    Price: 23.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "BAL",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Bao bì Bia – Rượu – Nước giải khát",
    CategoryName: "Hàng tiêu dùng",
    Price: 8.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "BAX",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Thống Nhất",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 40,
    TotalCompany: 1659,
  },
  {
    Symbol: "BBC",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Bibica",
    CategoryName: "Hàng tiêu dùng",
    Price: 50.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "BBH",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "CTCP Bao bì Hoàng Thạch",
    CategoryName: "Nguyên vật liệu",
    Price: 16.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "BBM",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Bia Hà Nội - Nam Định",
    CategoryName: "Hàng tiêu dùng",
    Price: 7.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "BBS",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần VICEM Bao bì Bút Sơn",
    CategoryName: "Nguyên vật liệu",
    Price: 10.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "BBT",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Bông Bạch Tuyết",
    CategoryName: "Y tế",
    Price: 13,
    TotalCompany: 1659,
  },
  {
    Symbol: "BCA",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần B.C.H",
    CategoryName: "Nguyên vật liệu",
    Price: 10.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "BCB",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần 397",
    CategoryName: "Năng lượng",
    Price: 0.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "BCC",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xi măng Bỉm Sơn",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "BCE",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng và Giao thông Bình Dương",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "BCF",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Thực phẩm Bích Chi",
    CategoryName: "Hàng tiêu dùng",
    Price: 40,
    TotalCompany: 1659,
  },
  {
    Symbol: "BCG",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Bamboo Capital",
    CategoryName: "Tài chính",
    Price: 6.36,
    TotalCompany: 1659,
  },
  {
    Symbol: "BCM",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Tổng Công ty Đầu tư và phát triển Công nghiệp - CTCP",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 66.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "BCP",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Dược Enlie",
    CategoryName: "Nguyên vật liệu",
    Price: 11.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "BCR",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần BCG Land",
    CategoryName: null,
    Price: 5.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "BCV",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP Du lịch và Thương mại Bằng Giang Cao Bằng - Vimico",
    CategoryName: "Dịch vụ",
    Price: 21.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "BDB",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Sách và Thiết bị Bình Định",
    CategoryName: "Dịch vụ",
    Price: 7.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "BDG",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần May mặc Bình Dương",
    CategoryName: "Hàng tiêu dùng",
    Price: 33.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "BDT",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây lắp và Vật liệu Xây dựng Đồng Tháp",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "BDW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Cấp thoát nước Bình Định",
    CategoryName: "Dịch vụ",
    Price: 25.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "BED",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Sách và Thiết bị trường học Đà Nẵng",
    CategoryName: "Dịch vụ",
    Price: 30.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "BEL",
    TradeCenterId: 9,
    CategoryId: 347,
    CompanyName: "CTCP Điện tử Biên Hòa",
    CategoryName: "Công nghệ",
    Price: 33,
    TotalCompany: 1659,
  },
  {
    Symbol: "BFC",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Phân bón Bình Điền",
    CategoryName: "Nông nghiệp",
    Price: 37.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "BFI",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Đầu tư tài chính BIDV",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "BGE",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần BCG Energy",
    CategoryName: null,
    Price: 10.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "BGW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Nước sạch Bắc Giang",
    CategoryName: "Dịch vụ",
    Price: 16,
    TotalCompany: 1659,
  },
  {
    Symbol: "BHA",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Thủy điện Bắc Hà",
    CategoryName: "Năng lượng",
    Price: 22,
    TotalCompany: 1659,
  },
  {
    Symbol: "BHC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Bê tông Biên Hòa",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "BHG",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Chè Biển Hồ",
    CategoryName: "Nông nghiệp",
    Price: 11,
    TotalCompany: 1659,
  },
  {
    Symbol: "BHI",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Tổng Công ty cổ phần Bảo hiểm Sài Gòn - Hà Nội",
    CategoryName: null,
    Price: 13.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "BHK",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Bia Hà Nội - Kim Bài",
    CategoryName: "Hàng tiêu dùng",
    Price: 21.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "BHN",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Tổng CTCP Bia - Rượu - Nước giải khát Hà Nội",
    CategoryName: "Hàng tiêu dùng",
    Price: 38.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "BHP",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Bia Hà Nội - Hải Phòng",
    CategoryName: "Hàng tiêu dùng",
    Price: 6.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "BIC",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Tổng Công ty Cổ phần Bảo hiểm Ngân hàng Đầu tư và phát triển Việt Nam",
    CategoryName: "Tài chính",
    Price: 32.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "BID",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại cổ phần Đầu tư và Phát triển Việt Nam",
    CategoryName: "Tài chính",
    Price: 44.75,
    TotalCompany: 1659,
  },
  {
    Symbol: "BIG",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Big Invest Group",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "BII",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư và Phát triển Công nghiệp Bảo Thư",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "BIO",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty cổ phần Vắc xin và Sinh phẩm Nha Trang",
    CategoryName: "Y tế",
    Price: 14.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "BJC",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công Ty Cổ Phần VRG - Bảo Lộc",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "BKC",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Khoáng sản Bắc Kạn",
    CategoryName: "Nguyên vật liệu",
    Price: 10.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "BKG",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Đầu tư BKG Việt Nam",
    CategoryName: "Dịch vụ",
    Price: 3.25,
    TotalCompany: 1659,
  },
  {
    Symbol: "BLF",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Thủy sản Bạc Liêu",
    CategoryName: "Nông nghiệp",
    Price: 3.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "BLI",
    TradeCenterId: 9,
    CategoryId: 341,
    CompanyName: "Tổng Công ty cổ phần Bảo hiểm Bảo Long ",
    CategoryName: "Tài chính",
    Price: 9.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "BLN",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "CTCP Vận tải và Dịch vụ Liên Ninh",
    CategoryName: "Công nghiệp",
    Price: 7.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "BLT",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Lương thực Bình Định",
    CategoryName: "Nông nghiệp",
    Price: 38.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "BLW",
    TradeCenterId: 8,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Cấp nước Bạc Liêu",
    CategoryName: "Dịch vụ",
    Price: 10,
    TotalCompany: 1659,
  },
  {
    Symbol: "BMC",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Khoáng sản Bình Định",
    CategoryName: "Nguyên vật liệu",
    Price: 20.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "BMD",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Môi trường và Dịch vụ đô thị Bình Thuận",
    CategoryName: "Dịch vụ",
    Price: 9.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "BMF",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Vật liệu Xây dựng và Chất đốt Đồng Nai",
    CategoryName: "Năng lượng",
    Price: 8.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "BMG",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần May Bình Minh",
    CategoryName: "Hàng tiêu dùng",
    Price: 19.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "BMI",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Tổng Công ty Cổ phần Bảo Minh",
    CategoryName: "Tài chính",
    Price: 20.35,
    TotalCompany: 1659,
  },
  {
    Symbol: "BMJ",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Khoáng sản Miền Đông AHP",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "BMN",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần 715",
    CategoryName: "Công nghiệp",
    Price: 8.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "BMP",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Nhựa Bình Minh",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 118,
    TotalCompany: 1659,
  },
  {
    Symbol: "BMS",
    TradeCenterId: 9,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán Bảo Minh",
    CategoryName: "Tài chính",
    Price: 9.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "BMV",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Bột mỳ Vinafood 1",
    CategoryName: "Nông nghiệp",
    Price: 8,
    TotalCompany: 1659,
  },
  {
    Symbol: "BNA",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Tập đoàn Đầu tư Bảo Ngọc",
    CategoryName: "Hàng tiêu dùng",
    Price: 9.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "BNC",
    TradeCenterId: 8,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Dịch vụ Cáp treo Bà Nà",
    CategoryName: "Dịch vụ",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "BNW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Nước sạch Bắc Ninh",
    CategoryName: "Dịch vụ",
    Price: 9.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "BOT",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần BOT Cầu Thái Hà",
    CategoryName: "Công nghiệp",
    Price: 2.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "BPC",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Vicem Bao bì Bỉm Sơn",
    CategoryName: "Nguyên vật liệu",
    Price: 9,
    TotalCompany: 1659,
  },
  {
    Symbol: "BQB",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Bia Hà Nội - Quảng Bình",
    CategoryName: "Hàng tiêu dùng",
    Price: 3.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "BRC",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Cao su Bến Thành",
    CategoryName: "Nguyên vật liệu",
    Price: 13.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "BRR",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Cao su Bà Rịa",
    CategoryName: "Nông nghiệp",
    Price: 18.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "BRS",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Dịch vụ đô thị Bà Rịa",
    CategoryName: "Dịch vụ",
    Price: 19.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "BSA",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Thủy điện Buôn Đôn",
    CategoryName: "Năng lượng",
    Price: 22.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "BSC",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Dịch vụ Bến Thành",
    CategoryName: "Dịch vụ",
    Price: 14.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "BSD",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Bia, Rượu Sài Gòn - Đồng Xuân",
    CategoryName: "Hàng tiêu dùng",
    Price: 14.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "BSG",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Xe khách Sài Gòn",
    CategoryName: "Công nghiệp",
    Price: 11.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "BSH",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Bia Sài Gòn - Hà Nội",
    CategoryName: "Hàng tiêu dùng",
    Price: 22.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "BSI",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Chứng khoán BIDV",
    CategoryName: "Tài chính",
    Price: 44.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "BSL",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Bia Sài Gòn - Sông Lam",
    CategoryName: "Hàng tiêu dùng",
    Price: 9.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "BSP",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Bia Sài Gòn - Phú Thọ",
    CategoryName: "Hàng tiêu dùng",
    Price: 10,
    TotalCompany: 1659,
  },
  {
    Symbol: "BSQ",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Bia Sài Gòn - Quảng Ngãi",
    CategoryName: "Hàng tiêu dùng",
    Price: 20,
    TotalCompany: 1659,
  },
  {
    Symbol: "BSR",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Lọc Hóa dầu Bình Sơn",
    CategoryName: "Năng lượng",
    Price: 19.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "BST",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Sách - Thiết bị Bình Thuận",
    CategoryName: "Dịch vụ",
    Price: 12.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "BT1",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Bảo vệ Thực vật 1 Trung ương",
    CategoryName: "Nông nghiệp",
    Price: 13.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "BTA",
    TradeCenterId: 8,
    CategoryId: 345,
    CompanyName: "CTCP Bất động sản Bình Thiên An",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "BTB",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Bia Hà Nội - Thái Bình",
    CategoryName: "Hàng tiêu dùng",
    Price: 5.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "BTD",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Bê tông Ly tâm Thủ Đức",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 19.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "BTG",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Bao bì Tiền Giang",
    CategoryName: "Nguyên vật liệu",
    Price: 8.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "BTH",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Chế tạo Biến thế và Vật liệu điện Hà Nội",
    CategoryName: "Công nghiệp",
    Price: 35,
    TotalCompany: 1659,
  },
  {
    Symbol: "BTJ",
    TradeCenterId: 8,
    CategoryId: 346,
    CompanyName: "CTCP Vàng bạc đá quý Bến Thành",
    CategoryName: "Dịch vụ",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "BTL",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Đầu tư Địa ốc Bến Thành",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "BTN",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Gạch Tuy Nen Bình Định",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "BTP",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Nhiệt điện Bà Rịa",
    CategoryName: "Năng lượng",
    Price: 12,
    TotalCompany: 1659,
  },
  {
    Symbol: "BTS",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Xi măng Vicem Bút Sơn",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "BTT",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Thương mại - Dịch vụ Bến Thành",
    CategoryName: "Dịch vụ",
    Price: 35,
    TotalCompany: 1659,
  },
  {
    Symbol: "BTU",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Công trình Đô thị Bến Tre",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 13.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "BTV",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Dịch vụ Du lịch Bến Thành",
    CategoryName: "Dịch vụ",
    Price: 11,
    TotalCompany: 1659,
  },
  {
    Symbol: "BTW",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Cấp nước Bến Thành",
    CategoryName: "Dịch vụ",
    Price: 47.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "BVB",
    TradeCenterId: 9,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại cổ phần Bản Việt",
    CategoryName: "Tài chính",
    Price: 11,
    TotalCompany: 1659,
  },
  {
    Symbol: "BVF",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty TNHH Quản lý Quỹ Bảo Việt",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "BVG",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần GROUP Bắc Việt",
    CategoryName: "Công nghiệp",
    Price: 2.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "BVH",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Tập đoàn Bảo Việt",
    CategoryName: "Tài chính",
    Price: 42.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "BVL",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần BV Land",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "BVN",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Bông Việt Nam",
    CategoryName: "Hàng tiêu dùng",
    Price: 12.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "BVS",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán Bảo Việt",
    CategoryName: "Tài chính",
    Price: 38,
    TotalCompany: 1659,
  },
  {
    Symbol: "BWA",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Cấp thoát nước và Xây dựng Bảo Lộc",
    CategoryName: "Dịch vụ",
    Price: 12,
    TotalCompany: 1659,
  },
  {
    Symbol: "BWE",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Nước – Môi trường Bình Dương",
    CategoryName: "Dịch vụ",
    Price: 44.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "BWS",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Cấp nước Bà Rịa - Vũng Tàu",
    CategoryName: "Dịch vụ",
    Price: 32.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "BXH",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần VICEM Bao bì Hải Phòng",
    CategoryName: "Nguyên vật liệu",
    Price: 15,
    TotalCompany: 1659,
  },
  {
    Symbol: "C21",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Thế kỷ 21",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 17.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "C22",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần 22",
    CategoryName: "Nông nghiệp",
    Price: 16.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "C32",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần CIC39",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 17.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "C47",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng 47",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "C4G",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Tập đoàn CIENCO4",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "C69",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng 1369",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "C92",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng và Đầu tư 492",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "CAB",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP Tổng Công ty Truyền hình Cáp Việt Nam",
    CategoryName: "Dịch vụ",
    Price: 11,
    TotalCompany: 1659,
  },
  {
    Symbol: "CAD",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Chế biến và Xuất nhập khẩu Thủy sản CADOVIMEX",
    CategoryName: "Nông nghiệp",
    Price: 0.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "CAG",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Cảng An Giang",
    CategoryName: "Công nghiệp",
    Price: 7.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "CAN",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Đồ hộp Hạ Long",
    CategoryName: "Hàng tiêu dùng",
    Price: 51,
    TotalCompany: 1659,
  },
  {
    Symbol: "CAP",
    TradeCenterId: 2,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Lâm Nông sản Thực phẩm Yên Bái",
    CategoryName: "Nông nghiệp",
    Price: 44.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "CAR",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Tập đoàn Giáo dục Trí Việt",
    CategoryName: "Dịch vụ",
    Price: 19.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "CAT",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Thủy sản Cà Mau",
    CategoryName: "Nông nghiệp",
    Price: 18.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "CBI",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Gang thép Cao Bằng",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 9.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "CBS",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Mía đường Cao Bằng",
    CategoryName: "Nông nghiệp",
    Price: 31,
    TotalCompany: 1659,
  },
  {
    Symbol: "CC1",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Tổng Công ty Xây dựng số 1 - CTCP",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 14,
    TotalCompany: 1659,
  },
  {
    Symbol: "CC4",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Xây dựng số 4",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 12,
    TotalCompany: 1659,
  },
  {
    Symbol: "CCA",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "CTCP Xuất nhập khẩu Thủy sản Cần Thơ",
    CategoryName: "Nông nghiệp",
    Price: 12.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "CCC",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần xây dựng CDC",
    CategoryName: null,
    Price: 18.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "CCI",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư Phát triển Công nghiệp - Thương mại Củ Chi",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 22.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "CCL",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư và Phát triển Đô thị Dầu khí Cửu Long",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "CCM",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Khoáng sản & Xi măng Cần Thơ",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 48.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "CCP",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Cảng Cửa Cấm Hải Phòng",
    CategoryName: "Năng lượng",
    Price: 32.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "CCR",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Cảng Cam Ranh ",
    CategoryName: "Công nghiệp",
    Price: 13.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "CCT",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Cảng Cần Thơ",
    CategoryName: "Công nghiệp",
    Price: 11.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "CCV",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Tư vấn Xây dựng Công nghiệp và Đô thị Việt Nam",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 53.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "CDC",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Chương Dương  ",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 16.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "CDG",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Cầu Đuống",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "CDH",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP Công trình công cộng Dịch vụ Du lịch Hải Phòng",
    CategoryName: "Dịch vụ",
    Price: 9.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "CDN",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Cảng Đà Nẵng",
    CategoryName: "Công nghiệp",
    Price: 31,
    TotalCompany: 1659,
  },
  {
    Symbol: "CDO",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: " Công ty Cổ phần Tư vấn thiết kế và Phát triển đô thị",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "CDP",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty cổ phần Dược phẩm Trung ương Codupha",
    CategoryName: "Y tế",
    Price: 10,
    TotalCompany: 1659,
  },
  {
    Symbol: "CDR",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng Cao su Đồng Nai",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 12.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "CE1",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Xây dựng và Thiết bị Công nghiệp CIE1",
    CategoryName: "Công nghiệp",
    Price: 11.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "CEG",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Tập đoàn Xây dựng và Thiết bị Công nghiệp",
    CategoryName: "Công nghiệp",
    Price: 9.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "CEN",
    TradeCenterId: 9,
    CategoryId: 350,
    CompanyName: "Công ty Cổ phần CENCON Việt Nam",
    CategoryName: "Doanh nghiệp Upcom",
    Price: 2.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "CEO",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tập đoàn C.E.O",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 14.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "CET",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần HTC Holding",
    CategoryName: "Công nghiệp",
    Price: 4.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "CFM",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP Đầu tư CFM",
    CategoryName: "Dịch vụ",
    Price: 9.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "CFV",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Cà phê Thắng Lợi",
    CategoryName: "Nông nghiệp",
    Price: 22,
    TotalCompany: 1659,
  },
  {
    Symbol: "CGV",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Sành sứ Thủy tinh Việt Nam",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "CH5",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng Số 5 Hà Nội",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 15.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "CHC",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Cẩm Hà",
    CategoryName: "Hàng tiêu dùng",
    Price: 7.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "CHP",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Thủy điện miền Trung",
    CategoryName: "Năng lượng",
    Price: 33.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "CHS",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Chiếu sáng Công cộng Thành phố Hồ Chí Minh",
    CategoryName: "Năng lượng",
    Price: 11.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "CI5",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư Xây dựng số 5",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "CIA",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Dịch vụ Sân bay Quốc tế Cam Ranh",
    CategoryName: "Công nghiệp",
    Price: 9.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "CID",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng và Phát triển Cơ sở Hạ tầng",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 29.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "CIG",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần COMA18",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 8.18,
    TotalCompany: 1659,
  },
  {
    Symbol: "CII",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Đầu tư Hạ tầng Kỹ thuật T.P Hồ Chí Minh",
    CategoryName: "Công nghiệp",
    Price: 14.05,
    TotalCompany: 1659,
  },
  {
    Symbol: "CIP",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây lắp và Sản xuất Công nghiệp",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "CJC",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Cơ điện Miền Trung",
    CategoryName: "Công nghiệp",
    Price: 25.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "CK8",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Cơ khí 120",
    CategoryName: "Công nghiệp",
    Price: 3.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "CKA",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Cơ khí An Giang",
    CategoryName: "Công nghiệp",
    Price: 40,
    TotalCompany: 1659,
  },
  {
    Symbol: "CKD",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Cơ khí Đông Anh Licogi",
    CategoryName: "Nguyên vật liệu",
    Price: 22.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "CKG",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "CTCP Tập đoàn Tư vấn Đầu tư & Xây dựng Kiên Giang",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 25,
    TotalCompany: 1659,
  },
  {
    Symbol: "CKV",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần COKYVINA",
    CategoryName: "Dịch vụ",
    Price: 12.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "CLC",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Cát Lợi ",
    CategoryName: "Nguyên vật liệu",
    Price: 48.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "CLG",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Phát triển Nhà đất COTEC",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "CLH",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Xi măng La Hiên VVMI",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 22,
    TotalCompany: 1659,
  },
  {
    Symbol: "CLL",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Cảng Cát Lái",
    CategoryName: "Công nghiệp",
    Price: 37.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "CLM",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "CTCP Xuất nhập khẩu Than - Vinacomin",
    CategoryName: "Năng lượng",
    Price: 74.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "CLW",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Cấp nước Chợ Lớn",
    CategoryName: "Dịch vụ",
    Price: 45.45,
    TotalCompany: 1659,
  },
  {
    Symbol: "CLX",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Xuất nhập khẩu và Đầu tư Chợ Lớn (Cholimex)",
    CategoryName: "Dịch vụ",
    Price: 15.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "CMC",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Đầu tư CMC",
    CategoryName: "Công nghiệp",
    Price: 6.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "CMD",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Vật liệu Xây dựng và Trang trí Nội thất Thành phố Hồ Chí Minh",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 21.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "CMF",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Thực phẩm Cholimex",
    CategoryName: "Hàng tiêu dùng",
    Price: 337.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "CMG",
    TradeCenterId: 1,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Tập đoàn Công nghệ CMC",
    CategoryName: "Công nghệ",
    Price: 56.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "CMI",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần CMISTONE Việt Nam",
    CategoryName: "Nguyên vật liệu",
    Price: 1.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "CMK",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Cơ khí Mạo khê - Vinacomin",
    CategoryName: "Công nghiệp",
    Price: 8.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "CMM",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Camimex",
    CategoryName: "Nông nghiệp",
    Price: 9.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "CMN",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Lương thực Thực phẩm Colusa-Miliket",
    CategoryName: "Nông nghiệp",
    Price: 65,
    TotalCompany: 1659,
  },
  {
    Symbol: "CMP",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "CTCP Cảng Chân Mây",
    CategoryName: "Công nghiệp",
    Price: 8.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "CMS",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Tập đoàn CMH Việt Nam",
    CategoryName: "Dịch vụ",
    Price: 10.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "CMT",
    TradeCenterId: 9,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Công nghệ Mạng và Truyền thông",
    CategoryName: "Công nghệ",
    Price: 14.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "CMV",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Thương nghiệp Cà Mau",
    CategoryName: "Năng lượng",
    Price: 9.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "CMW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Cấp nước Cà Mau",
    CategoryName: "Dịch vụ",
    Price: 13,
    TotalCompany: 1659,
  },
  {
    Symbol: "CMX",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Camimex Group",
    CategoryName: "Nông nghiệp",
    Price: 7.77,
    TotalCompany: 1659,
  },
  {
    Symbol: "CNA",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Tổng Công ty Chè Nghệ An",
    CategoryName: "Nông nghiệp",
    Price: 43.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "CNC",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty cổ phần Công nghệ cao Traphaco",
    CategoryName: "Y tế",
    Price: 31.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "CNG",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần CNG Việt Nam",
    CategoryName: "Năng lượng",
    Price: 30.65,
    TotalCompany: 1659,
  },
  {
    Symbol: "CNN",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Tư vấn công nghệ, thiết bị và kiểm định xây dựng - CONINCO",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 47,
    TotalCompany: 1659,
  },
  {
    Symbol: "CNS",
    TradeCenterId: 8,
    CategoryId: 343,
    CompanyName: "Tổng Công ty Công nghiệp Sài Gòn - TNHH Một thành viên",
    CategoryName: "Công nghiệp",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "CNT",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tập đoàn CNT",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 13.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "COM",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Vật tư - Xăng dầu ",
    CategoryName: "Năng lượng",
    Price: 27.05,
    TotalCompany: 1659,
  },
  {
    Symbol: "CPA",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Cà phê Phước An",
    CategoryName: "Nông nghiệp",
    Price: 7,
    TotalCompany: 1659,
  },
  {
    Symbol: "CPC",
    TradeCenterId: 2,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Thuốc sát trùng Cần Thơ",
    CategoryName: "Nông nghiệp",
    Price: 17.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "CPH",
    TradeCenterId: 9,
    CategoryId: 350,
    CompanyName: "Công ty Cổ phần Mai táng Hải Phòng",
    CategoryName: "Doanh nghiệp Upcom",
    Price: 0.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "CPI",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Đầu tư Cảng Cái Lân",
    CategoryName: "Công nghiệp",
    Price: 3.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "CQN",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Cảng Quảng Ninh",
    CategoryName: "Công nghiệp",
    Price: 33.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "CQT",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Xi măng Quán Triều VVMI",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "CRC",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Create Capital Việt Nam",
    CategoryName: "Công nghiệp",
    Price: 6.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "CRE",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Bất động sản Thế Kỷ",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.92,
    TotalCompany: 1659,
  },
  {
    Symbol: "CRV",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: null,
    CategoryName: null,
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "CSC",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tập đoàn COTANA",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 25.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "CSI",
    TradeCenterId: 9,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Chứng khoán Kiến thiết Việt Nam",
    CategoryName: "Tài chính",
    Price: 29.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "CSM",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Công nghiệp Cao su Miền Nam",
    CategoryName: "Công nghiệp",
    Price: 12.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "CST",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Than Cao Sơn - TKV",
    CategoryName: "Năng lượng",
    Price: 21.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "CSV",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Hóa chất Cơ bản miền Nam",
    CategoryName: "Nguyên vật liệu",
    Price: 37,
    TotalCompany: 1659,
  },
  {
    Symbol: "CT3",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Xây dựng Công trình 3",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "CT6",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Công trình 6",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 9.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "CTB",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Chế tạo Bơm Hải Dương",
    CategoryName: "Công nghiệp",
    Price: 21.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "CTC",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Tập đoàn Hoàng Kim Tây Nguyên",
    CategoryName: "Dịch vụ",
    Price: 1.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "CTD",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng Coteccons",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 64.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "CTF",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần City Auto",
    CategoryName: "Công nghiệp",
    Price: 24.65,
    TotalCompany: 1659,
  },
  {
    Symbol: "CTG",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại Cổ phần Công thương Việt Nam",
    CategoryName: "Tài chính",
    Price: 33.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "CTI",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư Phát triển Cường Thuận IDICO",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 16.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "CTP",
    TradeCenterId: 2,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Hòa Bình Takara",
    CategoryName: "Nông nghiệp",
    Price: 35.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "CTR",
    TradeCenterId: 1,
    CategoryId: 348,
    CompanyName: "Tổng Công ty Cổ phần Công trình Viettel",
    CategoryName: "Viễn thông",
    Price: 124.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "CTS",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Chứng khoán Ngân hàng Công thương",
    CategoryName: "Tài chính",
    Price: 35.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "CTT",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "CTCP Chế tạo máy Vinacomin",
    CategoryName: "Công nghiệp",
    Price: 16.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "CTW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP Cấp thoát nước Cần Thơ",
    CategoryName: "Dịch vụ",
    Price: 30.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "CTX",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Tổng Công ty Cổ phần Đầu tư Xây dựng và Thương mại Việt Nam",
    CategoryName: "Nguyên vật liệu",
    Price: 7.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "CVN",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Vinam",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "CVP",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Cảng Cửa Việt",
    CategoryName: "Công nghiệp",
    Price: 10,
    TotalCompany: 1659,
  },
  {
    Symbol: "CVT",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần CMC",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 24.65,
    TotalCompany: 1659,
  },
  {
    Symbol: "CX8",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư và Xây lắp Constrexim số 8",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "CYC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Gạch men Chang Yih ",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "D11",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Địa ốc 11",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "D17",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Đồng Tân",
    CategoryName: null,
    Price: 42.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "D2D",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Phát triển Đô thị Công nghiệp Số 2",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 30.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "DAC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần 382 Đông Anh",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "DAD",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Đầu tư và Phát triển Giáo dục Đà Nẵng",
    CategoryName: "Dịch vụ",
    Price: 19.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "DAE",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Sách Giáo dục tại Tp. Đà Nẵng",
    CategoryName: "Dịch vụ",
    Price: 15.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "DAF",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Ngân hàng Thương mại cổ phần Đông Á",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "DAH",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Tập đoàn Khách sạn Đông Á",
    CategoryName: "Dịch vụ",
    Price: 3.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "DAN",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty cổ phần Dược Danapha",
    CategoryName: "Y tế",
    Price: 28.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "DAS",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Máy - Thiết bị Dầu khí Đà Nẵng",
    CategoryName: "Hàng tiêu dùng",
    Price: 6.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "DAT",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Đầu tư Du lịch và Phát triển Thủy sản",
    CategoryName: "Nông nghiệp",
    Price: 7.45,
    TotalCompany: 1659,
  },
  {
    Symbol: "DBC",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Tập đoàn Dabaco Việt Nam",
    CategoryName: "Nông nghiệp",
    Price: 26.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "DBD",
    TradeCenterId: 1,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược - Trang thiết bị Y tế Bình Định",
    CategoryName: "Y tế",
    Price: 47.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "DBM",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Dược - Vật tư Y tế Đăk Lăk",
    CategoryName: "Nguyên vật liệu",
    Price: 25.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "DBT",
    TradeCenterId: 1,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược phẩm Bến Tre",
    CategoryName: "Y tế",
    Price: 12,
    TotalCompany: 1659,
  },
  {
    Symbol: "DC1",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư Phát triển Xây dựng số 1",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 11.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "DC2",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư Phát triển - Xây dựng số 2",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "DC4",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng DIC Holdings",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 12.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "DCF",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng và Thiết kế số 1",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 61.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "DCG",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Tổng công ty May Đáp Cầu",
    CategoryName: "Hàng tiêu dùng",
    Price: 12,
    TotalCompany: 1659,
  },
  {
    Symbol: "DCH",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Địa chính Hà Nội",
    CategoryName: "Dịch vụ",
    Price: 9.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "DCL",
    TradeCenterId: 1,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược phẩm Cửu Long",
    CategoryName: "Y tế",
    Price: 26.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "DCM",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Phân bón Dầu khí Cà Mau",
    CategoryName: "Nông nghiệp",
    Price: 36.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "DCR",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Gạch men Cosevco",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "DCS",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Tập đoàn Đại Châu",
    CategoryName: "Hàng tiêu dùng",
    Price: 0.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "DCT",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tấm lợp Vật liệu xây dựng Đồng Nai ",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "DDG",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Đầu tư Công nghiệp Xuất nhập khẩu Đông Dương",
    CategoryName: "Năng lượng",
    Price: 2.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "DDH",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "CTCP Đảm bảo giao thông đường thủy Hải Phòng",
    CategoryName: "Nông nghiệp",
    Price: 18.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "DDM",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Hàng hải Đông Đô",
    CategoryName: "Công nghiệp",
    Price: 1.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "DDN",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược - Thiết bị Y tế Đà Nẵng",
    CategoryName: "Y tế",
    Price: 7.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "DDS",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán AIS",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "DDV",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần DAP - VINACHEM",
    CategoryName: "Nguyên vật liệu",
    Price: 18.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "DFC",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Xích líp Đông Anh",
    CategoryName: "Công nghiệp",
    Price: 19,
    TotalCompany: 1659,
  },
  {
    Symbol: "DFF",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Tập đoàn Đua Fat",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "DGC",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Tập đoàn Hóa chất Đức Giang",
    CategoryName: "Nguyên vật liệu",
    Price: 108.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "DGT",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Công trình Giao thông Đồng Nai",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "DGW",
    TradeCenterId: 1,
    CategoryId: 347,
    CompanyName: "Công ty cổ phần Thế giới số",
    CategoryName: "Công nghệ",
    Price: 40.65,
    TotalCompany: 1659,
  },
  {
    Symbol: "DHA",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Hóa An",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 40.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "DHB",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Phân đạm và Hóa chất Hà Bắc",
    CategoryName: "Nông nghiệp",
    Price: 8,
    TotalCompany: 1659,
  },
  {
    Symbol: "DHC",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Đông Hải Bến Tre",
    CategoryName: "Nguyên vật liệu",
    Price: 34.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "DHD",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty cổ phần Dược Vật tư Y tế Hải Dương",
    CategoryName: "Y tế",
    Price: 26.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "DHG",
    TradeCenterId: 1,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược Hậu Giang",
    CategoryName: "Y tế",
    Price: 100.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "DHM",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Thương mại và khai thác khoáng sản",
    CategoryName: "Dịch vụ",
    Price: 8.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "DHN",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược phẩm Hà Nội",
    CategoryName: "Y tế",
    Price: 25,
    TotalCompany: 1659,
  },
  {
    Symbol: "DHP",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Điện Cơ Hải Phòng",
    CategoryName: "Năng lượng",
    Price: 11.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "DHT",
    TradeCenterId: 2,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược phẩm Hà Tây",
    CategoryName: "Y tế",
    Price: 73.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "DIC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Thương mại DIC",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1,
    TotalCompany: 1659,
  },
  {
    Symbol: "DID",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần DIC - Đồng Tiến",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "DIG",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Tổng Công ty Cổ phần Đầu tư Phát triển Xây dựng",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 19.85,
    TotalCompany: 1659,
  },
  {
    Symbol: "DIH",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư Phát triển Xây dựng - Hội An",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 15.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "DJL",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty TNHH Đầu tư Bất động sản Doji Land",
    CategoryName: null,
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "DKC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Chợ Lạng Sơn",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "DL1",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "CTCP Tập đoàn Alpha Seven",
    CategoryName: "Tài chính",
    Price: 5.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "DLD",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Du lịch Đắk Lắk ",
    CategoryName: "Dịch vụ",
    Price: 5.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "DLG",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tập đoàn Đức Long Gia Lai",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.86,
    TotalCompany: 1659,
  },
  {
    Symbol: "DLR",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Địa ốc Đà Lạt",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 19.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "DLT",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "CTCP Du lịch và Thương mại – Vinacomin",
    CategoryName: "Công nghiệp",
    Price: 6.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "DM7",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Dệt may 7",
    CategoryName: "Hàng tiêu dùng",
    Price: 20,
    TotalCompany: 1659,
  },
  {
    Symbol: "DMC",
    TradeCenterId: 1,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Xuất nhập khẩu Y tế Domesco",
    CategoryName: "Y tế",
    Price: 65.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "DMN",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Domenal",
    CategoryName: "Nông nghiệp",
    Price: 5.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "DMS",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Hóa phẩm Dầu khí DMC - miền Nam",
    CategoryName: null,
    Price: 7.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "DNA",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Điện nước An Giang",
    CategoryName: "Năng lượng",
    Price: 19.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "DNC",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Điện nước Lắp máy Hải Phòng",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 77.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "DND",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Đầu tư Xây dựng và Vật liệu Đồng Nai",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 16.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "DNE",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Môi trường Đô thị Đà Nẵng",
    CategoryName: "Dịch vụ",
    Price: 9.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "DNH",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Thủy điện Đa Nhim - Hàm Thuận - Đa Mi",
    CategoryName: "Năng lượng",
    Price: 50,
    TotalCompany: 1659,
  },
  {
    Symbol: "DNL",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Logistic Cảng Đà Nẵng",
    CategoryName: "Công nghiệp",
    Price: 35,
    TotalCompany: 1659,
  },
  {
    Symbol: "DNM",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Tổng Công ty cổ phần Y tế Danameco",
    CategoryName: "Y tế",
    Price: 7.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "DNN",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Cấp nước Đà Nẵng",
    CategoryName: "Dịch vụ",
    Price: 0.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "DNP",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần DNP Holding",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 21,
    TotalCompany: 1659,
  },
  {
    Symbol: "DNT",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Du lịch Đồng Nai",
    CategoryName: "Dịch vụ",
    Price: 30.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "DNV",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "CTCP Thương mại Dịch vụ VDA Đà Nẵng",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "DNW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Cấp nước Đồng Nai",
    CategoryName: "Dịch vụ",
    Price: 32.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "DOC",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Vật tư nông nghiệp Đồng Nai",
    CategoryName: "Nông nghiệp",
    Price: 10,
    TotalCompany: 1659,
  },
  {
    Symbol: "DOP",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "CTCP Vận tải Xăng dầu Đồng Tháp ",
    CategoryName: "Công nghiệp",
    Price: 14,
    TotalCompany: 1659,
  },
  {
    Symbol: "DP1",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần dược phẩm Trung ương CPC1",
    CategoryName: "Y tế",
    Price: 37.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "DP2",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược phẩm Trung ương 2",
    CategoryName: "Y tế",
    Price: 2.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "DP3",
    TradeCenterId: 2,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược phẩm Trung ương 3",
    CategoryName: "Y tế",
    Price: 58,
    TotalCompany: 1659,
  },
  {
    Symbol: "DPC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Nhựa Đà Nẵng",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10,
    TotalCompany: 1659,
  },
  {
    Symbol: "DPG",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tập đoàn Đạt Phương",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 51.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "DPH",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược phẩm Hải Phòng",
    CategoryName: "Y tế",
    Price: 51,
    TotalCompany: 1659,
  },
  {
    Symbol: "DPM",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Tổng Công ty Phân bón và Hóa chất Dầu khí-CTCP",
    CategoryName: "Nông nghiệp",
    Price: 33.65,
    TotalCompany: 1659,
  },
  {
    Symbol: "DPP",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược Đồng Nai",
    CategoryName: "Y tế",
    Price: 19,
    TotalCompany: 1659,
  },
  {
    Symbol: "DPR",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Cao su Đồng Phú",
    CategoryName: "Nông nghiệp",
    Price: 37.25,
    TotalCompany: 1659,
  },
  {
    Symbol: "DPS",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư Phát triển Sóc Sơn ",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "DQC",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Bóng đèn Điện Quang ",
    CategoryName: "Hàng tiêu dùng",
    Price: 12.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "DRC",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Cao su Đà Nẵng",
    CategoryName: "Công nghiệp",
    Price: 26.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "DRG",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Cao su Đắk Lắk",
    CategoryName: "Nông nghiệp",
    Price: 8.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "DRH",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần DRH Holdings",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "DRI",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Đầu tư Cao su Đắk Lắk",
    CategoryName: "Nông nghiệp",
    Price: 12,
    TotalCompany: 1659,
  },
  {
    Symbol: "DRL",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Thủy điện – Điện lực 3",
    CategoryName: "Năng lượng",
    Price: 60,
    TotalCompany: 1659,
  },
  {
    Symbol: "DS3",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần DS3",
    CategoryName: "Dịch vụ",
    Price: 5,
    TotalCompany: 1659,
  },
  {
    Symbol: "DSC",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán DSC",
    CategoryName: "Tài chính",
    Price: 18.25,
    TotalCompany: 1659,
  },
  {
    Symbol: "DSD",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần DHC Suối Đôi",
    CategoryName: "Dịch vụ",
    Price: 20,
    TotalCompany: 1659,
  },
  {
    Symbol: "DSE",
    TradeCenterId: 1,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Chứng khoán DNSE",
    CategoryName: null,
    Price: 22.35,
    TotalCompany: 1659,
  },
  {
    Symbol: "DSG",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Kính Viglacera Đáp Cầu",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "DSN",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Công viên nước Đầm Sen",
    CategoryName: "Dịch vụ",
    Price: 54.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "DSP",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Dịch vụ Du lịch Phú Thọ",
    CategoryName: "Dịch vụ",
    Price: 15.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "DST",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Đầu tư Sao Thăng Long",
    CategoryName: "Dịch vụ",
    Price: 3,
    TotalCompany: 1659,
  },
  {
    Symbol: "DSV",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Đường sắt Vĩnh Phú",
    CategoryName: "Công nghiệp",
    Price: 14.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "DTA",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đệ Tam",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "DTB",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Công trình Đô thị Bảo Lộc",
    CategoryName: "Dịch vụ",
    Price: 12.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "DTC",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Viglacera Đông Triều",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "DTD",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư phát triển Thành Đạt",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 25.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "DTE",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "CTCP Đầu tư Năng lượng Đại Trường Thành Holdings",
    CategoryName: "Năng lượng",
    Price: 5.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "DTG",
    TradeCenterId: 2,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược phẩm Tipharco",
    CategoryName: "Y tế",
    Price: 23.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "DTH",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược - Vật tư Y tế Thanh Hóa",
    CategoryName: "Y tế",
    Price: 13.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "DTI",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư Đức Trung",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "DTK",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Tổng công ty Điện lực TKV - CTCP",
    CategoryName: "Năng lượng",
    Price: 12.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "DTL",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Đại Thiên Lộc",
    CategoryName: "Nguyên vật liệu",
    Price: 10.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "DTP",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty cổ phần Dược phẩm CPC1 Hà Nội",
    CategoryName: "Y tế",
    Price: 142,
    TotalCompany: 1659,
  },
  {
    Symbol: "DTS",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "CTCP Dịch Vụ Du Lịch Đà Lạt ",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "DTT",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Kỹ nghệ Đô Thành",
    CategoryName: "Nguyên vật liệu",
    Price: 22.45,
    TotalCompany: 1659,
  },
  {
    Symbol: "DUS",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP Dịch vụ Đô thị Đà Lạt",
    CategoryName: "Dịch vụ",
    Price: 8.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "DVC",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Thương mại Dịch vụ Tổng hợp Cảng Hải Phòng",
    CategoryName: "Năng lượng",
    Price: 11,
    TotalCompany: 1659,
  },
  {
    Symbol: "DVG",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Đại Việt Group DVG",
    CategoryName: "Nguyên vật liệu",
    Price: 1.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "DVM",
    TradeCenterId: 2,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược liệu Việt Nam",
    CategoryName: "Y tế",
    Price: 7.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "DVN",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Tổng Công ty Dược Việt Nam - CTCP",
    CategoryName: "Y tế",
    Price: 22.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "DVP",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Đầu tư và Phát triển Cảng Đình Vũ",
    CategoryName: "Công nghiệp",
    Price: 77.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "DVS",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Thép Đình Vũ",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "DVW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Dịch vụ và Xây dựng Cấp nước Đồng Nai",
    CategoryName: "Dịch vụ",
    Price: 20.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "DWC",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP Cấp nước Đắk Lắk",
    CategoryName: "Dịch vụ",
    Price: 10.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "DWS",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Cấp nước và Môi trường đô thị Đồng Tháp",
    CategoryName: "Dịch vụ",
    Price: 12.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "DXG",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tập đoàn Đất Xanh",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 16.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "DXL",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Du lịch và Xuất nhập khẩu Lạng Sơn",
    CategoryName: "Dịch vụ",
    Price: 12.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "DXP",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Cảng Đoạn Xá",
    CategoryName: "Công nghiệp",
    Price: 12.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "DXS",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Dịch vụ Bất động sản Đất Xanh",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "DXV",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần VICEM Vật liệu Xây dựng Đà Nẵng",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "DZM",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Cơ điện Dzĩ An",
    CategoryName: "Công nghiệp",
    Price: 2.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "E12",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Xây dựng điện Vneco12",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "E29",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Đầu tư xây dựng và kỹ thuật 29",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "EBS",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Sách Giáo dục tại Tp.Hà Nội",
    CategoryName: "Dịch vụ",
    Price: 10.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "ECC",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán EUROCAPITAL",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "ECI",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Bản đồ và Tranh ảnh Giáo dục",
    CategoryName: "Dịch vụ",
    Price: 24.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "ECO",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Nhựa sinh thái Việt Nam",
    CategoryName: null,
    Price: 14.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "EFI",
    TradeCenterId: 9,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Đầu tư Tài chính Giáo dục",
    CategoryName: "Tài chính",
    Price: 1.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "EIB",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại Cổ phần Xuất nhập khẩu Việt Nam",
    CategoryName: "Tài chính",
    Price: 17.85,
    TotalCompany: 1659,
  },
  {
    Symbol: "EIC",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần EVN Quốc tế",
    CategoryName: "Năng lượng",
    Price: 22,
    TotalCompany: 1659,
  },
  {
    Symbol: "EID",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Đầu tư và Phát triển giáo dục Hà Nội",
    CategoryName: "Dịch vụ",
    Price: 27.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "EIN",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư - Thương mại - Dịch vụ Điện lực",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "ELC",
    TradeCenterId: 1,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần công nghệ - viễn thông ELCOM",
    CategoryName: "Công nghệ",
    Price: 25.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "EME",
    TradeCenterId: 9,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Điện cơ",
    CategoryName: "Công nghệ",
    Price: 25.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "EMG",
    TradeCenterId: 9,
    CategoryId: 350,
    CompanyName: "Công ty Cổ phần Thiết bị Phụ tùng Cơ điện",
    CategoryName: "Doanh nghiệp Upcom",
    Price: 23.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "EMS",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Tổng Công ty Chuyển phát nhanh Bưu Điện - CTCP",
    CategoryName: "Dịch vụ",
    Price: 27.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "EPC",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty TNHH Một thành viên Cà phê Ea Pốk",
    CategoryName: "Nông nghiệp",
    Price: 11.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "EPH",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần dịch vụ xuất bản giáo dục Hà Nội",
    CategoryName: "Dịch vụ",
    Price: 16.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "EPS",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty Cổ Phần Chứng khoán Gia Quyền",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "EVE",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Everpia",
    CategoryName: "Hàng tiêu dùng",
    Price: 10.35,
    TotalCompany: 1659,
  },
  {
    Symbol: "EVF",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty Tài chính cổ phần Điện lực",
    CategoryName: "Tài chính",
    Price: 10.55,
    TotalCompany: 1659,
  },
  {
    Symbol: "EVG",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tập đoàn EVERLAND",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5.93,
    TotalCompany: 1659,
  },
  {
    Symbol: "EVN",
    TradeCenterId: 8,
    CategoryId: 340,
    CompanyName: "Tập đoàn Điện lực Việt Nam - Công ty TNHH MTV",
    CategoryName: "Năng lượng",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "EVS",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Chứng khoán Everest",
    CategoryName: "Tài chính",
    Price: 5.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "EWH",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "CTCP EuroWindow Holding",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "FBC",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Cơ khí Phổ Yên",
    CategoryName: "Công nghiệp",
    Price: 3.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "FCC",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "CTCP Liên hợp Thực phẩm",
    CategoryName: "Hàng tiêu dùng",
    Price: 10,
    TotalCompany: 1659,
  },
  {
    Symbol: "FCM",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Khoáng sản FECON",
    CategoryName: "Nguyên vật liệu",
    Price: 3.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "FCN",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần FECON",
    CategoryName: "Công nghiệp",
    Price: 12.45,
    TotalCompany: 1659,
  },
  {
    Symbol: "FCS",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Lương thực Thành phố Hồ Chí Minh",
    CategoryName: "Nông nghiệp",
    Price: 8.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "FDC",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Ngoại thương và Phát triển Đầu tư Thành phố Hồ Chí Minh",
    CategoryName: "Dịch vụ",
    Price: 16,
    TotalCompany: 1659,
  },
  {
    Symbol: "FGL",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Cà phê Gia Lai",
    CategoryName: "Nông nghiệp",
    Price: 10.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "FHH",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "CTCP Đầu tư Kinh doanh Phát triển Bất Động Sản FLCHOMES",
    CategoryName: null,
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "FHN",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công Ty Cổ phần Xuất Nhập Khẩu Lương Thực - Thực Phẩm Hà Nội",
    CategoryName: "Nông nghiệp",
    Price: 14.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "FHS",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Phát hành sách T.P Hồ Chí Minh",
    CategoryName: "Dịch vụ",
    Price: 31.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "FIC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Tổng Công ty Vật liệu Xây dựng số 1 - CTCP",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 14,
    TotalCompany: 1659,
  },
  {
    Symbol: "FID",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Đầu tư và Phát triển Doanh nghiệp Việt Nam",
    CategoryName: "Dịch vụ",
    Price: 1.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "FIR",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Địa ốc First Real",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.48,
    TotalCompany: 1659,
  },
  {
    Symbol: "FIS",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Hệ thống Thông tin FPT",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "FIT",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Tập đoàn F.I.T",
    CategoryName: "Tài chính",
    Price: 4.16,
    TotalCompany: 1659,
  },
  {
    Symbol: "FLC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Tập đoàn FLC",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "FMC",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Thực phẩm Sao Ta ",
    CategoryName: "Nông nghiệp",
    Price: 45.65,
    TotalCompany: 1659,
  },
  {
    Symbol: "FNS",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Chứng khoán FUNAN",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "FOC",
    TradeCenterId: 9,
    CategoryId: 347,
    CompanyName: "Công ty cổ phần Dịch vụ Trực tuyến FPT",
    CategoryName: "Công nghệ",
    Price: 81.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "FOX",
    TradeCenterId: 9,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Viễn thông FPT",
    CategoryName: "Công nghệ",
    Price: 92.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "FPT",
    TradeCenterId: 1,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần FPT",
    CategoryName: "Công nghệ",
    Price: 130.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "FRC",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Lâm đặc sản Xuất khẩu Quảng Nam",
    CategoryName: "Nguyên vật liệu",
    Price: 28.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "FRM",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Lâm nghiệp Sài Gòn",
    CategoryName: "Nguyên vật liệu",
    Price: 4.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "FRT",
    TradeCenterId: 1,
    CategoryId: 347,
    CompanyName: "Công ty cổ phần Bán lẻ Kỹ thuật số FPT",
    CategoryName: "Công nghệ",
    Price: 164,
    TotalCompany: 1659,
  },
  {
    Symbol: "FSO",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Cơ khí đóng tàu thủy sản Việt Nam",
    CategoryName: "Công nghiệp",
    Price: 5.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "FT1",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Phụ tùng máy số 1",
    CategoryName: "Công nghiệp",
    Price: 47.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "FTG",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Thương mại FPT",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "FTI",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Công nghiệp - Thương mại Hữu Nghị",
    CategoryName: "Công nghiệp",
    Price: 3.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "FTM",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Đầu tư và Phát triển Đức Quân",
    CategoryName: "Hàng tiêu dùng",
    Price: 0.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "FTS",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán FPT",
    CategoryName: "Tài chính",
    Price: 41.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "G20",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Đầu tư Dệt may G.Home",
    CategoryName: "Hàng tiêu dùng",
    Price: 0.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "G36",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Tổng Công ty 36 - CTCP",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "GAS",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Tổng Công ty Khí Việt Nam-CTCP",
    CategoryName: "Năng lượng",
    Price: 69,
    TotalCompany: 1659,
  },
  {
    Symbol: "GCB",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ Phần Petec Bình Định",
    CategoryName: "Năng lượng",
    Price: 18,
    TotalCompany: 1659,
  },
  {
    Symbol: "GCC",
    TradeCenterId: 8,
    CategoryId: 339,
    CompanyName: "CTCP Văn hóa Tổng hợp Bến Thành",
    CategoryName: "Hàng tiêu dùng",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "GCF",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Thực phẩm G.C",
    CategoryName: "Hàng tiêu dùng",
    Price: 23,
    TotalCompany: 1659,
  },
  {
    Symbol: "GDA",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Tôn Đông Á",
    CategoryName: "Nguyên vật liệu",
    Price: 26.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "GDC",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "CTCP Xuất Nhập khẩu Gia Định",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "GDT",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Chế biến Gỗ Đức Thành",
    CategoryName: "Hàng tiêu dùng",
    Price: 26.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "GDW",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Cấp nước Gia Định",
    CategoryName: "Dịch vụ",
    Price: 28.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "GEE",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Điện lực GELEX",
    CategoryName: "Công nghiệp",
    Price: 33,
    TotalCompany: 1659,
  },
  {
    Symbol: "GEG",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Điện Gia Lai",
    CategoryName: "Năng lượng",
    Price: 10.85,
    TotalCompany: 1659,
  },
  {
    Symbol: "GER",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Thể thao Ngôi sao Geru",
    CategoryName: "Nguyên vật liệu",
    Price: 12.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "GEX",
    TradeCenterId: 1,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Tập đoàn GELEX",
    CategoryName: "Công nghệ",
    Price: 18,
    TotalCompany: 1659,
  },
  {
    Symbol: "GGG",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Ô tô Giải Phóng ",
    CategoryName: "Công nghiệp",
    Price: 3.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "GH3",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Công trình Giao thông Hà Nội",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "GHC",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: " Công ty Cổ phần Thủy điện Gia Lai",
    CategoryName: "Năng lượng",
    Price: 28.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "GIC",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Đầu tư dịch vụ và Phát triển Xanh",
    CategoryName: "Công nghiệp",
    Price: 14.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "GIL",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Sản xuất Kinh doanh Xuất nhập khẩu Bình Thạnh",
    CategoryName: "Hàng tiêu dùng",
    Price: 20.25,
    TotalCompany: 1659,
  },
  {
    Symbol: "GKM",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần GKM Holdings",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "GLC",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Vàng Lào Cai",
    CategoryName: "Nguyên vật liệu",
    Price: 10,
    TotalCompany: 1659,
  },
  {
    Symbol: "GLS",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán Sen Vàng",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "GLT",
    TradeCenterId: 2,
    CategoryId: 348,
    CompanyName: "Công ty cổ phần Kỹ thuật điện Toàn Cầu",
    CategoryName: "Viễn thông",
    Price: 26.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "GLW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Cấp thoát nước Gia Lai",
    CategoryName: "Dịch vụ",
    Price: 9.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "GMA",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần G-AutoMobile",
    CategoryName: "Công nghiệp",
    Price: 53.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "GMC",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Garmex Sài Gòn",
    CategoryName: "Hàng tiêu dùng",
    Price: 8,
    TotalCompany: 1659,
  },
  {
    Symbol: "GMD",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Gemadept",
    CategoryName: "Công nghiệp",
    Price: 63.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "GMH",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Minh Hưng Quảng Trị",
    CategoryName: "Nguyên vật liệu",
    Price: 8.15,
    TotalCompany: 1659,
  },
  {
    Symbol: "GMS",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "CTCP Thiết kế và gia công phần mền GMS888",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "GMX",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Gạch Ngói Gốm Xây dựng Mỹ Xuân",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 18.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "GND",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Gạch ngói Đồng Nai",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 27.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "GPC",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "CTCP Tập đoàn Green+",
    CategoryName: "Hàng tiêu dùng",
    Price: 2.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "GSC",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Thủy điện Geruco Sông Côn",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "GSM",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Thủy điện Hương Sơn",
    CategoryName: "Năng lượng",
    Price: 22.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "GSP",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Vận tải Sản phẩm khí quốc tế",
    CategoryName: "Năng lượng",
    Price: 12.85,
    TotalCompany: 1659,
  },
  {
    Symbol: "GTA",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Chế biến Gỗ Thuận An",
    CategoryName: "Hàng tiêu dùng",
    Price: 10.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "GTD",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Giầy Thượng Đình",
    CategoryName: "Hàng tiêu dùng",
    Price: 8.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "GTH",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng Giao thông Thừa Thiên Huế",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "GTS",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Công trình Giao thông Sài Gòn",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "GVR",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Tập đoàn Công nghiệp Cao su Việt Nam",
    CategoryName: "Nông nghiệp",
    Price: 31.15,
    TotalCompany: 1659,
  },
  {
    Symbol: "GVT",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Giấy Việt Trì",
    CategoryName: "Nguyên vật liệu",
    Price: 78,
    TotalCompany: 1659,
  },
  {
    Symbol: "H11",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng HUD101",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "HAC",
    TradeCenterId: 9,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán Hải Phòng",
    CategoryName: "Tài chính",
    Price: 11.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "HAD",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Bia Hà Nội - Hải Dương",
    CategoryName: "Hàng tiêu dùng",
    Price: 16.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "HAF",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Thực phẩm Hà Nội",
    CategoryName: "Hàng tiêu dùng",
    Price: 13.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "HAG",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Hoàng Anh Gia Lai",
    CategoryName: "Tài chính",
    Price: 11.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "HAH",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Vận tải và Xếp dỡ Hải An",
    CategoryName: "Công nghiệp",
    Price: 47.75,
    TotalCompany: 1659,
  },
  {
    Symbol: "HAM",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Vật tư Hậu Giang",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 29,
    TotalCompany: 1659,
  },
  {
    Symbol: "HAN",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Tổng công ty Xây dựng Hà Nội - CTCP",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "HAP",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Tập đoàn Hapaco",
    CategoryName: "Nguyên vật liệu",
    Price: 4.18,
    TotalCompany: 1659,
  },
  {
    Symbol: "HAR",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư Thương mại Bất động sản An Dương Thảo Điền",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.31,
    TotalCompany: 1659,
  },
  {
    Symbol: "HAS",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần HACISCO",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 8.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "HAT",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Thương mại Bia Hà Nội",
    CategoryName: "Hàng tiêu dùng",
    Price: 42.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "HAV",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần rượu Hapro",
    CategoryName: "Hàng tiêu dùng",
    Price: 3.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "HAX",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Dịch vụ Ô tô Hàng Xanh",
    CategoryName: "Dịch vụ",
    Price: 15.75,
    TotalCompany: 1659,
  },
  {
    Symbol: "HBC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Tập đoàn Xây dựng Hoà Bình",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "HBD",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Bao bì PP Bình Dương",
    CategoryName: "Nguyên vật liệu",
    Price: 17.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "HBH",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Habeco - Hải Phòng",
    CategoryName: "Hàng tiêu dùng",
    Price: 5.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "HBS",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán Hòa Bình",
    CategoryName: "Tài chính",
    Price: 6.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "HC1",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Xây dựng số 1 Hà Nội",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 11,
    TotalCompany: 1659,
  },
  {
    Symbol: "HC3",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng Số 3 Hải Phòng",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 26.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "HCB",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Dệt may 29/3",
    CategoryName: "Hàng tiêu dùng",
    Price: 28,
    TotalCompany: 1659,
  },
  {
    Symbol: "HCC",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Bê tông Hoà Cầm - Intimex",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 12,
    TotalCompany: 1659,
  },
  {
    Symbol: "HCD",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Đầu tư Sản xuất và Thương mại HCD",
    CategoryName: "Nguyên vật liệu",
    Price: 8.69,
    TotalCompany: 1659,
  },
  {
    Symbol: "HCI",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư - Xây dựng Hà Nội",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 11,
    TotalCompany: 1659,
  },
  {
    Symbol: "HCM",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán Thành phố Hồ Chí Minh",
    CategoryName: "Tài chính",
    Price: 27.25,
    TotalCompany: 1659,
  },
  {
    Symbol: "HCT",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Thương mại Dịch vụ Vận tải Xi măng Hải Phòng",
    CategoryName: "Công nghiệp",
    Price: 9.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "HD2",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư Phát triển nhà HUD2",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 17.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "HD6",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư và Phát triển nhà số 6 Hà Nội",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 13.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "HD8",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Đầu tư Phát triển Nhà và Đô thị HUD8",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 8.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "HDA",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Hãng sơn Đông Á",
    CategoryName: "Nguyên vật liệu",
    Price: 3.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "HDB",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Ngân hàng TMCP Phát triển T.P Hồ Chí Minh",
    CategoryName: "Tài chính",
    Price: 24.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "HDC",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Phát triển nhà Bà Rịa-Vũng Tàu",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 24.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "HDG",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tập đoàn Hà Đô",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 27.35,
    TotalCompany: 1659,
  },
  {
    Symbol: "HDM",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Dệt May Huế",
    CategoryName: "Hàng tiêu dùng",
    Price: 33.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "HDP",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược Hà Tĩnh",
    CategoryName: "Y tế",
    Price: 17.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "HDS",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Giống cây trồng Hải Dương",
    CategoryName: null,
    Price: 8.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "HDW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Kinh doanh nước sạch Hải Dương",
    CategoryName: "Dịch vụ",
    Price: 14,
    TotalCompany: 1659,
  },
  {
    Symbol: "HEC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tư vấn Xây dựng Thủy lợi II",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 57.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "HEJ",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Tổng Công ty Tư vấn Xây dựng Thủy lợi Việt Nam-CTCP",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 12.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "HEM",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Chế tạo Điện Cơ Hà Nội",
    CategoryName: "Công nghiệp",
    Price: 16.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "HEP",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Môi trường và Công trình Đô thị Huế",
    CategoryName: "Dịch vụ",
    Price: 16.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "HES",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Dịch vụ Giải trí Hà Nội",
    CategoryName: "Dịch vụ",
    Price: 47.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "HEV",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Sách Đại học - Dạy nghề",
    CategoryName: "Dịch vụ",
    Price: 21.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "HFB",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Công trình Cầu phà Thành phố Hồ Chí Minh",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10,
    TotalCompany: 1659,
  },
  {
    Symbol: "HFC",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Xăng dầu HFC",
    CategoryName: "Năng lượng",
    Price: 6.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "HFX",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Sản xuất - Xuất nhập khẩu Thanh Hà",
    CategoryName: "Nông nghiệp",
    Price: 7.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "HGM",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Cơ khí và Khoáng sản Hà Giang",
    CategoryName: "Nguyên vật liệu",
    Price: 150,
    TotalCompany: 1659,
  },
  {
    Symbol: "HGT",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Du lịch Hương Giang",
    CategoryName: "Dịch vụ",
    Price: 10.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "HHC",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Bánh kẹo Hải Hà",
    CategoryName: "Hàng tiêu dùng",
    Price: 111,
    TotalCompany: 1659,
  },
  {
    Symbol: "HHG",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Hoàng Hà",
    CategoryName: "Công nghiệp",
    Price: 1.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "HHN",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ Phần Vận tải và Dịch vụ Hàng hóa Hà Nội",
    CategoryName: "Công nghiệp",
    Price: 0.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "HHP",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Giấy Hoàng Hà Hải Phòng",
    CategoryName: "Nguyên vật liệu",
    Price: 8.86,
    TotalCompany: 1659,
  },
  {
    Symbol: "HHR",
    TradeCenterId: 9,
    CategoryId: 350,
    CompanyName: "Công ty Cổ phần Đường sắt Hà Hải",
    CategoryName: "Doanh nghiệp Upcom",
    Price: 0.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "HHS",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Đầu tư Dịch vụ Hoàng Huy",
    CategoryName: "Công nghiệp",
    Price: 7.37,
    TotalCompany: 1659,
  },
  {
    Symbol: "HHV",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Đầu tư hạ tầng giao thông Đèo Cả",
    CategoryName: "Công nghiệp",
    Price: 10.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "HID",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Halcom Việt Nam",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.69,
    TotalCompany: 1659,
  },
  {
    Symbol: "HIG",
    TradeCenterId: 9,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Tập đoàn HIPT",
    CategoryName: "Công nghệ",
    Price: 10.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "HII",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần An Tiến Industries",
    CategoryName: "Nguyên vật liệu",
    Price: 4.46,
    TotalCompany: 1659,
  },
  {
    Symbol: "HIO",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Helio Energy",
    CategoryName: null,
    Price: 10.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "HIS",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Đầu tư phát triển thương mại tổng hợp Sơn Hà",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "HJC",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Hòa Việt",
    CategoryName: "Hàng tiêu dùng",
    Price: 5.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "HJS",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Thủy điện Nậm Mu",
    CategoryName: "Năng lượng",
    Price: 32,
    TotalCompany: 1659,
  },
  {
    Symbol: "HKB",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Nông nghiệp và Thực phẩm Hà Nội - Kinh Bắc  ",
    CategoryName: "Nông nghiệp",
    Price: 0.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "HKT",
    TradeCenterId: 2,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Đầu tư QP Xanh",
    CategoryName: "Nông nghiệp",
    Price: 8.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "HLB",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Bia và Nước giải khát Hạ Long",
    CategoryName: "Hàng tiêu dùng",
    Price: 305,
    TotalCompany: 1659,
  },
  {
    Symbol: "HLC",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Than Hà Lầm - Vinacomin",
    CategoryName: "Năng lượng",
    Price: 12.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "HLD",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Phát triển Bất động sản HUDLAND",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 26.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "HLO",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Công nghệ Ha Lô",
    CategoryName: null,
    Price: 20,
    TotalCompany: 1659,
  },
  {
    Symbol: "HLR",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Đường sắt Hà Lạng",
    CategoryName: "Công nghiệp",
    Price: 12.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "HLS",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Sứ kỹ thuật Hoàng Liên Sơn",
    CategoryName: "Công nghiệp",
    Price: 17.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "HLT",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "CTCP Dệt may Hoàng Thị Loan",
    CategoryName: "Hàng tiêu dùng",
    Price: 12.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "HLY",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Gốm Xây dựng Hưng Yên",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 16.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "HMC",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Kim khí Thành phố Hồ Chí Minh - Vnsteel",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.75,
    TotalCompany: 1659,
  },
  {
    Symbol: "HMG",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "CTCP Kim khí Hà Nội - VNSTEEL",
    CategoryName: "Nguyên vật liệu",
    Price: 11.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "HMH",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Hải Minh",
    CategoryName: "Công nghiệp",
    Price: 14.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "HMR",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đá Hoàng Mai",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "HMS",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng bảo tàng Hồ Chí Minh",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 34.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "HNA",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Thủy điện Hủa Na",
    CategoryName: "Năng lượng",
    Price: 26.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "HNB",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Bến xe Hà Nội",
    CategoryName: "Công nghiệp",
    Price: 12.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "HND",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "CTCP Nhiệt điện Hải Phòng",
    CategoryName: "Năng lượng",
    Price: 13.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "HNF",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Thực phẩm Hữu Nghị",
    CategoryName: "Hàng tiêu dùng",
    Price: 28.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "HNG",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Nông nghiệp Quốc tế Hoàng Anh Gia Lai",
    CategoryName: "Nông nghiệp",
    Price: 4.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "HNI",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần May Hữu Nghị",
    CategoryName: "Hàng tiêu dùng",
    Price: 23.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "HNM",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Sữa Hà Nội",
    CategoryName: "Hàng tiêu dùng",
    Price: 8.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "HNP",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Hanel Xốp nhựa",
    CategoryName: "Nguyên vật liệu",
    Price: 18.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "HNR",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Rượu và Nước giải khát Hà Nội",
    CategoryName: "Hàng tiêu dùng",
    Price: 12,
    TotalCompany: 1659,
  },
  {
    Symbol: "HOM",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Xi măng VICEM Hoàng Mai",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "HOT",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Du lịch - Dịch vụ Hội An",
    CategoryName: "Dịch vụ",
    Price: 12.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "HPB",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Bao bì PP",
    CategoryName: "Nguyên vật liệu",
    Price: 18.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "HPD",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Công ty Cổ phần Thủy điện Đăk Đoa",
    CategoryName: "Năng lượng",
    Price: 22.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "HPG",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Tập đoàn Hòa Phát",
    CategoryName: "Nguyên vật liệu",
    Price: 25.65,
    TotalCompany: 1659,
  },
  {
    Symbol: "HPH",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Hóa chất Hưng Phát Hà Bắc",
    CategoryName: "Nguyên vật liệu",
    Price: 15,
    TotalCompany: 1659,
  },
  {
    Symbol: "HPI",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Khu công nghiệp Hiệp Phước",
    CategoryName: "Dịch vụ",
    Price: 13,
    TotalCompany: 1659,
  },
  {
    Symbol: "HPM",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "CTCP Xây dựng Thương mại và Khoáng sản Hoàng Phúc",
    CategoryName: "Năng lượng",
    Price: 8.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "HPP",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Sơn Hải Phòng",
    CategoryName: "Nguyên vật liệu",
    Price: 80.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "HPT",
    TradeCenterId: 9,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Dịch vụ Công nghệ Tin học HPT",
    CategoryName: "Công nghệ",
    Price: 19.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "HPW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Cấp nước Hải Phòng",
    CategoryName: "Dịch vụ",
    Price: 16.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "HPX",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư Hải Phát",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.66,
    TotalCompany: 1659,
  },
  {
    Symbol: "HQC",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Tư vấn-Thương mại-Dịch vụ Địa ốc Hoàng Quân",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.16,
    TotalCompany: 1659,
  },
  {
    Symbol: "HRB",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Harec Đầu tư và Thương mại",
    CategoryName: "Dịch vụ",
    Price: 61.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "HRC",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Cao su Hòa Bình",
    CategoryName: "Nông nghiệp",
    Price: 41.05,
    TotalCompany: 1659,
  },
  {
    Symbol: "HRS",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "CTCP Chứng khoán Việt Nam Gateway",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "HRT",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Vận tải Đường sắt Hà Nội",
    CategoryName: "Công nghiệp",
    Price: 13.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "HSA",
    TradeCenterId: 9,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần HESTIA",
    CategoryName: "Tài chính",
    Price: 33,
    TotalCompany: 1659,
  },
  {
    Symbol: "HSG",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Tập đoàn Hoa Sen",
    CategoryName: "Nguyên vật liệu",
    Price: 18.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "HSI",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Vật tư tổng hợp và Phân bón Hóa sinh",
    CategoryName: "Nông nghiệp",
    Price: 1.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "HSL",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Đầu tư Phát triển Thực phẩm Hồng Hà",
    CategoryName: "Nông nghiệp",
    Price: 4.04,
    TotalCompany: 1659,
  },
  {
    Symbol: "HSM",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Tổng Công ty cổ phần Dệt may Hà Nội",
    CategoryName: "Hàng tiêu dùng",
    Price: 5.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "HSP",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "CTCP Sơn Tổng hợp Hà Nội",
    CategoryName: "Nguyên vật liệu",
    Price: 10.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "HSV",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Tập đoàn HSV Việt Nam",
    CategoryName: "Nguyên vật liệu",
    Price: 3.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "HT1",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần xi măng VICEM Hà Tiên",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 11.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "HTC",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Thương mại Hóc Môn",
    CategoryName: "Dịch vụ",
    Price: 25.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "HTE",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư Kinh doanh Điện lực Thành phố Hồ Chí Minh",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "HTG",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Tổng Công ty cổ phần Dệt may Hòa Thọ",
    CategoryName: "Hàng tiêu dùng",
    Price: 46.25,
    TotalCompany: 1659,
  },
  {
    Symbol: "HTI",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Đầu tư phát triển hạ tầng IDICO",
    CategoryName: "Công nghiệp",
    Price: 15.95,
    TotalCompany: 1659,
  },
  {
    Symbol: "HTL",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Kỹ thuật và Ô tô Trường Long",
    CategoryName: "Dịch vụ",
    Price: 30.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "HTM",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Tổng Công ty Thương mại Hà Nội - CTCP",
    CategoryName: "Dịch vụ",
    Price: 9.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "HTN",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Hưng Thịnh Incons",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "HTP",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần In sách giáo khoa Hòa Phát",
    CategoryName: "Dịch vụ",
    Price: 3,
    TotalCompany: 1659,
  },
  {
    Symbol: "HTR",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Đường sắt Hà Thái",
    CategoryName: "Công nghiệp",
    Price: 11.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "HTT",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Thương mại Hà Tây",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "HTV",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Logistics Vicem",
    CategoryName: "Công nghiệp",
    Price: 9.21,
    TotalCompany: 1659,
  },
  {
    Symbol: "HTW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Cấp nước Hà Tĩnh",
    CategoryName: "Dịch vụ",
    Price: 10,
    TotalCompany: 1659,
  },
  {
    Symbol: "HU1",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Xây dựng HUD1",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.01,
    TotalCompany: 1659,
  },
  {
    Symbol: "HU3",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư và Xây dựng HUD3",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "HU4",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Xây dựng HUD4",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "HU6",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư Phát triển nhà và Đô thị HUD 6",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "HUB",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Xây lắp Thừa Thiên Huế",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 18.35,
    TotalCompany: 1659,
  },
  {
    Symbol: "HUG",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Tổng Công ty may Hưng Yên - Công ty Cổ phần",
    CategoryName: "Hàng tiêu dùng",
    Price: 32.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "HUT",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Tasco",
    CategoryName: "Công nghiệp",
    Price: 15.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "HVA",
    TradeCenterId: 9,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Đầu tư HVA",
    CategoryName: "Tài chính",
    Price: 6.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "HVH",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Công nghệ HVC",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 8.16,
    TotalCompany: 1659,
  },
  {
    Symbol: "HVN",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Tổng Công ty Hàng không Việt Nam - CTCP",
    CategoryName: "Công nghiệp",
    Price: 25.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "HVS",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "CTCP Chứng khoán HVS Việt Nam",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "HVT",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Hóa chất Việt Trì",
    CategoryName: "Nguyên vật liệu",
    Price: 94.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "HVX",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xi măng Vicem Hải Vân",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.48,
    TotalCompany: 1659,
  },
  {
    Symbol: "HWS",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Cấp nước Thừa Thiên Huế",
    CategoryName: "Dịch vụ",
    Price: 16.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "IBD",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP In Tổng hợp Bình Dương",
    CategoryName: "Dịch vụ",
    Price: 11.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "ICA",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Công nghệ Sinh học - Dược phẩm ICA ",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "ICC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Xây dựng Công nghiệp",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 26,
    TotalCompany: 1659,
  },
  {
    Symbol: "ICF",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Đầu tư Thương mại Thủy Sản",
    CategoryName: "Nông nghiệp",
    Price: 3.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "ICG",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng Sông Hồng",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "ICI",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Xây dựng Công nghiệp",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "ICN",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư Xây dựng Dầu khí IDICO",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 50.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "ICT",
    TradeCenterId: 1,
    CategoryId: 348,
    CompanyName: "Công ty cổ phần Viễn thông - Tin học Bưu điện",
    CategoryName: "Viễn thông",
    Price: 12.55,
    TotalCompany: 1659,
  },
  {
    Symbol: "IDC",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Tổng công ty IDICO - Công ty Cổ phần",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 54.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "IDI",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Đầu tư và Phát triển Đa Quốc Gia I.D.I",
    CategoryName: "Nông nghiệp",
    Price: 8.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "IDJ",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư IDJ Việt Nam",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "IDP",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Sữa Quốc tế",
    CategoryName: "Hàng tiêu dùng",
    Price: 254,
    TotalCompany: 1659,
  },
  {
    Symbol: "IDV",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Phát triển Hạ tầng Vĩnh Phúc",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 37.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "IFS",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Thực phẩm Quốc tế",
    CategoryName: "Hàng tiêu dùng",
    Price: 26.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "IHK",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần In Hàng không",
    CategoryName: "Hàng tiêu dùng",
    Price: 13.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "IJC",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Phát triển Hạ tầng Kỹ thuật",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 13.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "ILA",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần ILA",
    CategoryName: "Nông nghiệp",
    Price: 4,
    TotalCompany: 1659,
  },
  {
    Symbol: "ILB",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần ICD Tân Cảng – Long Bình",
    CategoryName: "Công nghiệp",
    Price: 32.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "ILC",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Hợp tác Lao động với Nước ngoài",
    CategoryName: "Dịch vụ",
    Price: 5.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "ILS",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Đầu tư Thương mại và Dịch vụ Quốc tế",
    CategoryName: "Công nghiệp",
    Price: 13.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "IME",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Cơ khí và Xây lắp Công nghiệp",
    CategoryName: "Công nghiệp",
    Price: 141,
    TotalCompany: 1659,
  },
  {
    Symbol: "IMP",
    TradeCenterId: 1,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược phẩm IMEXPHARM",
    CategoryName: "Y tế",
    Price: 41.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "IN4",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần In số 4",
    CategoryName: "Dịch vụ",
    Price: 57.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "INC",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tư vấn Đầu tư IDICO",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 33.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "ING",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Đầu tư và Phát triển Xây dựng",
    CategoryName: null,
    Price: 8.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "INN",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Bao bì và In Nông nghiệp",
    CategoryName: "Nguyên vật liệu",
    Price: 53.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "IPA",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Tập đoàn Đầu tư I.P.A",
    CategoryName: "Tài chính",
    Price: 12.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "IRC",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Cao su Công nghiệp",
    CategoryName: "Nguyên vật liệu",
    Price: 8.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "IRS",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán Quốc tế Hoàng Gia",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "ISC",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán Công Nghiệp Việt Nam",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "ISG",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Vận tải biển và Hợp tác Quốc tế",
    CategoryName: "Công nghiệp",
    Price: 7,
    TotalCompany: 1659,
  },
  {
    Symbol: "ISH",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Thủy điện Srok Phu Miêng IDICO",
    CategoryName: "Năng lượng",
    Price: 25.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "IST",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần ICD Tân Cảng Sóng Thần",
    CategoryName: "Công nghiệp",
    Price: 36.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "ITA",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Công nghiệp Tân Tạo",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.35,
    TotalCompany: 1659,
  },
  {
    Symbol: "ITC",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư - Kinh doanh nhà",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.45,
    TotalCompany: 1659,
  },
  {
    Symbol: "ITD",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Công nghệ Tiên Phong",
    CategoryName: "Công nghiệp",
    Price: 12.85,
    TotalCompany: 1659,
  },
  {
    Symbol: "ITQ",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Tập đoàn Thiên Quang",
    CategoryName: "Công nghiệp",
    Price: 2.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "ITS",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Đầu tư, Thương mại và Dịch vụ - Vinacomin",
    CategoryName: "Nguyên vật liệu",
    Price: 3.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "IVS",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Chứng khoán Guotai Junan (Việt Nam)",
    CategoryName: "Tài chính",
    Price: 9.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "JOS",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Chế biến Thủy sản Xuất khẩu Minh Hải",
    CategoryName: "Nông nghiệp",
    Price: 0.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "JPA",
    TradeCenterId: 8,
    CategoryId: 343,
    CompanyName: "Công ty CP Hàng không Jetstar Pacific Airlines",
    CategoryName: "Công nghiệp",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "JSI",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Chứng khoán Nhật Bản",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "JVC",
    TradeCenterId: 1,
    CategoryId: 342,
    CompanyName: "Công ty cổ phần Thiết bị Y tế Việt Nhật",
    CategoryName: "Y tế",
    Price: 3.05,
    TotalCompany: 1659,
  },
  {
    Symbol: "KAL",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Nhôm Khánh Hoà",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "KBC",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Tổng Công ty Phát triển Đô thị Kinh Bắc-CTCP",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 27.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "KCB",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "CTCP Khoáng sản và luyện kim Cao Bằng",
    CategoryName: "Nguyên vật liệu",
    Price: 8.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "KCE",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Bê tông Ly tâm Điện lực Khánh Hòa",
    CategoryName: "Nguyên vật liệu",
    Price: 10.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "KDC",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Tập đoàn Kido",
    CategoryName: "Hàng tiêu dùng",
    Price: 48.95,
    TotalCompany: 1659,
  },
  {
    Symbol: "KDH",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Kinh doanh nhà Khang Điền",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 32.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "KDM",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Tập đoàn GCL",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 16.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "KGM",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Xuất nhập khẩu Kiên Giang",
    CategoryName: "Nông nghiệp",
    Price: 6.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "KHD",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Khai thác, Chế biến khoáng sản Hải Dương",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 9.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "KHG",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tập đoàn Khải Hoàn Land",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5.16,
    TotalCompany: 1659,
  },
  {
    Symbol: "KHP",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Điện lực Khánh Hòa",
    CategoryName: "Năng lượng",
    Price: 11.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "KHS",
    TradeCenterId: 2,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Kiên Hùng",
    CategoryName: "Nông nghiệp",
    Price: 11.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "KHV",
    TradeCenterId: 8,
    CategoryId: 338,
    CompanyName: "Công ty TNHH Kim Hà Việt",
    CategoryName: "Nông nghiệp",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "KHW",
    TradeCenterId: 9,
    CategoryId: 350,
    CompanyName: "Công ty Cổ phần Cấp nước Khánh Hòa",
    CategoryName: "Doanh nghiệp Upcom",
    Price: 19.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "KIP",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần K.I.P Việt Nam",
    CategoryName: "Công nghiệp",
    Price: 11.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "KIS",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán KIS Việt Nam",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "KKC",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tập đoàn Thành Thái",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "KLB",
    TradeCenterId: 9,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại cổ phần Kiên Long",
    CategoryName: "Tài chính",
    Price: 12,
    TotalCompany: 1659,
  },
  {
    Symbol: "KMR",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Mirae",
    CategoryName: "Hàng tiêu dùng",
    Price: 3.24,
    TotalCompany: 1659,
  },
  {
    Symbol: "KMT",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Kim khí miền Trung",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 9.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "KOS",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Kosy",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 38.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "KPF",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư tài sản Koji",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.73,
    TotalCompany: 1659,
  },
  {
    Symbol: "KSB",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Khoáng sản và Xây dựng Bình Dương",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 17.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "KSD",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Đầu tư DNA",
    CategoryName: "Hàng tiêu dùng",
    Price: 4.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "KSF",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tập đoàn Sunshine",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 40.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "KSH",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Damac GLS",
    CategoryName: "Nguyên vật liệu",
    Price: 0.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "KSQ",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần CNC Capital Việt Nam",
    CategoryName: "Nguyên vật liệu",
    Price: 2.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "KST",
    TradeCenterId: 2,
    CategoryId: 347,
    CompanyName: "Công ty cổ phần KASATI",
    CategoryName: "Công nghệ",
    Price: 10.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "KSV",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Tổng Công ty Khoáng sản TKV - CTCP",
    CategoryName: "Nguyên vật liệu",
    Price: 51.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "KTC",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Thương mại Kiên Giang",
    CategoryName: "Năng lượng",
    Price: 9.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "KTL",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "CTCP Kim khí Thăng Long",
    CategoryName: "Công nghiệp",
    Price: 17.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "KTS",
    TradeCenterId: 2,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Đường Kon Tum",
    CategoryName: "Nông nghiệp",
    Price: 41.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "KTT",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Tập đoàn đầu tư KTT",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "KTW",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Cấp nước Kon Tum",
    CategoryName: null,
    Price: 9.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "KVC",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "CTCP Sản xuất Xuất nhập khẩu Inox Kim Vĩ",
    CategoryName: "Công nghiệp",
    Price: 1.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "KVS",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán KENANGA Việt Nam",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "L10",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Lilama 10 ",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 20.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "L12",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Licogi 12",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "L14",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần LICOGI 14",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 25.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "L18",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Xây dựng số 18",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 38.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "L35",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Cơ khí Lắp máy Lilama",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "L40",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Xây dựng 40",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 39.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "L43",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Lilama 45.3",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "L44",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Lilama 45.4",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "L45",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Lilama 45.1",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "L61",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Lilama 69-1",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "L62",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Lilama 69-2",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "L63",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "CTCP Lilama 69-3",
    CategoryName: "",
    Price: 6.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "LAF",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Chế biến Hàng xuất khẩu Long An",
    CategoryName: "Nông nghiệp",
    Price: 18.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "LAI",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Đầu tư xây dựng Long An IDICO",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 31.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "LAS",
    TradeCenterId: 2,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Supe Phốt phát và Hóa chất Lâm Thao",
    CategoryName: "Nông nghiệp",
    Price: 21.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "LAW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Cấp thoát nước Long An",
    CategoryName: "Dịch vụ",
    Price: 28,
    TotalCompany: 1659,
  },
  {
    Symbol: "LBC",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Thương mại - Đầu tư Long Biên",
    CategoryName: "Hàng tiêu dùng",
    Price: 14.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "LBE",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Thương mại và Dịch vụ LVA",
    CategoryName: "Dịch vụ",
    Price: 22.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "LBM",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Khoáng sản và Vật liệu xây dựng Lâm Đồng",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 27.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "LCC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xi măng Hồng Phong",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "LCD",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Lắp máy - Thí nghiệm Cơ điện",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 21.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "LCG",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần LIZEN",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 9.97,
    TotalCompany: 1659,
  },
  {
    Symbol: "LCM",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Khai thác và Chế biến Khoáng sản Lào Cai",
    CategoryName: "Nguyên vật liệu",
    Price: 1,
    TotalCompany: 1659,
  },
  {
    Symbol: "LCS",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Licogi 166",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "LDG",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư LDG",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.86,
    TotalCompany: 1659,
  },
  {
    Symbol: "LDP",
    TradeCenterId: 2,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược Lâm Đồng - Ladophar",
    CategoryName: "Y tế",
    Price: 13.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "LDW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Cấp thoát nước Lâm Đồng",
    CategoryName: "Dịch vụ",
    Price: 15.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "LEC",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Bất động sản Điện lực Miền Trung",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5.77,
    TotalCompany: 1659,
  },
  {
    Symbol: "LG9",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Cơ giới và Xây lắp số 9",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 8.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "LGC",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Đầu tư Cầu đường CII",
    CategoryName: "Công nghiệp",
    Price: 62,
    TotalCompany: 1659,
  },
  {
    Symbol: "LGL",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư và Phát triển Đô thị Long Giang",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.46,
    TotalCompany: 1659,
  },
  {
    Symbol: "LGM",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Giày da và May mặc Xuất khẩu",
    CategoryName: "Hàng tiêu dùng",
    Price: 11.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "LHC",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Xây dựng Thủy lợi Lâm Đồng",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 72.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "LHG",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Long Hậu",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 36,
    TotalCompany: 1659,
  },
  {
    Symbol: "LIC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Tổng Công ty Licogi - Công ty Cổ phần",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 34.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "LIG",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Licogi 13",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "LIX",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Bột giặt Lix",
    CategoryName: "Hàng tiêu dùng",
    Price: 32.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "LKW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP Cấp nước Long Khánh",
    CategoryName: "Dịch vụ",
    Price: 30.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "LLM",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Tổng Công ty Lắp máy Việt Nam - CTCP",
    CategoryName: "Công nghiệp",
    Price: 14.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "LM3",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Lilama 3",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "LM7",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Lilama 7",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "LM8",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Lilama 18",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 13.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "LMC",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Khoáng sản Latca",
    CategoryName: "Nguyên vật liệu",
    Price: 20.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "LMH",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Quốc tế Holding",
    CategoryName: "Nguyên vật liệu",
    Price: 1,
    TotalCompany: 1659,
  },
  {
    Symbol: "LMI",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư Xây dựng Lắp máy IDICO",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "LNC",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Lệ Ninh",
    CategoryName: "Nông nghiệp",
    Price: 10,
    TotalCompany: 1659,
  },
  {
    Symbol: "LNS",
    TradeCenterId: 8,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Mía đường La Ngà",
    CategoryName: "Nông nghiệp",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "LO5",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Lilama 5",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1,
    TotalCompany: 1659,
  },
  {
    Symbol: "LPB",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại cổ phần Lộc Phát Việt Nam",
    CategoryName: "Tài chính",
    Price: 31.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "LPT",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Thương mại và Sản xuất Lập Phương Thành",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "LQN",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Licogi Quảng Ngãi",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "LSG",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Bất động sản Sài Gòn VINA",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "LSS",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Mía đường Lam Sơn",
    CategoryName: "Nông nghiệp",
    Price: 11.55,
    TotalCompany: 1659,
  },
  {
    Symbol: "LTC",
    TradeCenterId: 9,
    CategoryId: 348,
    CompanyName: "Công ty cổ phần Điện nhẹ Viễn thông",
    CategoryName: "Viễn thông",
    Price: 1.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "LTG",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Tập đoàn Lộc Trời",
    CategoryName: "Nông nghiệp",
    Price: 7.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "LUT",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư Xây dựng Lương Tài",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "LVS",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Chứng khoán Liên Việt",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "M10",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Tổng Công ty May 10 - Công ty cổ phần",
    CategoryName: "Hàng tiêu dùng",
    Price: 22.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "MA1",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Thiết bị",
    CategoryName: "Nguyên vật liệu",
    Price: 55.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "MAC",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Cung ứng và Dịch vụ Kỹ thuật Hàng Hải",
    CategoryName: "Công nghiệp",
    Price: 22.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "MAS",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Dịch vụ Hàng không Sân bay Đà Nẵng",
    CategoryName: "Công nghiệp",
    Price: 35,
    TotalCompany: 1659,
  },
  {
    Symbol: "MBB",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại cổ phần Quân đội",
    CategoryName: "Tài chính",
    Price: 23.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "MBG",
    TradeCenterId: 2,
    CategoryId: 347,
    CompanyName: "Công ty cổ phần Tập đoàn MBG",
    CategoryName: "Công nghệ",
    Price: 3.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "MBN",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Môi trường và Công trình Đô thị Bắc Ninh",
    CategoryName: "Dịch vụ",
    Price: 7.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "MBS",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Chứng khoán MB",
    CategoryName: "Tài chính",
    Price: 27.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "MCC",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Gạch ngói cao cấp",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 12,
    TotalCompany: 1659,
  },
  {
    Symbol: "MCD",
    TradeCenterId: 8,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Môi trường và Công trình đô thị Đông Hà",
    CategoryName: "Dịch vụ",
    Price: 15.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "MCF",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "CTCP Xây lắp Cơ khí và Lương thực Thực phẩm ",
    CategoryName: "Công nghiệp",
    Price: 8.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "MCG",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Năng Lượng và Bất động sản MCG",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "MCH",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Hàng tiêu dùng Masan",
    CategoryName: "Hàng tiêu dùng",
    Price: 220,
    TotalCompany: 1659,
  },
  {
    Symbol: "MCM",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Giống bò sữa Mộc Châu",
    CategoryName: "Hàng tiêu dùng",
    Price: 34.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "MCO",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Xây dựng BDC Việt Nam",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 9.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "MCP",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần In và Bao bì Mỹ Châu",
    CategoryName: "Nguyên vật liệu",
    Price: 30.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "MDA",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Môi trường Đô thị Đông Anh",
    CategoryName: "Dịch vụ",
    Price: 9,
    TotalCompany: 1659,
  },
  {
    Symbol: "MDC",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Than Mông Dương - Vinacomin",
    CategoryName: "Năng lượng",
    Price: 9.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "MDF",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Gỗ MDF VRG Quảng Trị",
    CategoryName: "Hàng tiêu dùng",
    Price: 6.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "MDG",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần miền Đông",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 13.55,
    TotalCompany: 1659,
  },
  {
    Symbol: "MEC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Cơ khí - lắp máy Sông Đà",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "MED",
    TradeCenterId: 2,
    CategoryId: 342,
    CompanyName: "Công ty cổ phần Dược Trung ương Mediplantex",
    CategoryName: "Y tế",
    Price: 26.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "MEF",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty cổ phần MEINFA",
    CategoryName: "Y tế",
    Price: 5.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "MEL",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Thép Mê Lin",
    CategoryName: "Nguyên vật liệu",
    Price: 6.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "MES",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Cơ điện Công trình",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10,
    TotalCompany: 1659,
  },
  {
    Symbol: "MFS",
    TradeCenterId: 9,
    CategoryId: 348,
    CompanyName: "Công ty Cổ phần Dịch vụ Kỹ thuật Mobifone",
    CategoryName: "Viễn thông",
    Price: 38.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "MGC",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Địa chất mỏ - TKV",
    CategoryName: "Năng lượng",
    Price: 5.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "MGG",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Tổng Công ty Đức Giang - Công ty Cổ phần",
    CategoryName: "Hàng tiêu dùng",
    Price: 28,
    TotalCompany: 1659,
  },
  {
    Symbol: "MGR",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Tập đoàn Mgroup",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "MH3",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Khu công nghiệp Cao Su Bình Long",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 32.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "MHB",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Ngân hàng TMCP Phát triển nhà đồng bằng sông Cửu Long",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "MHC",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần MHC",
    CategoryName: "Công nghiệp",
    Price: 6.24,
    TotalCompany: 1659,
  },
  {
    Symbol: "MIC",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Kỹ nghệ Khoáng sản Quảng Nam",
    CategoryName: "Nguyên vật liệu",
    Price: 14.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "MIE",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Tổng Công ty Máy và Thiết bị Công nghiệp - CTCP",
    CategoryName: "Công nghiệp",
    Price: 6.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "MIG",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Tổng Công ty cổ phần Bảo hiểm Quân đội",
    CategoryName: "Tài chính",
    Price: 16.65,
    TotalCompany: 1659,
  },
  {
    Symbol: "MKP",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Hóa - Dược phẩm Mekophar",
    CategoryName: "Y tế",
    Price: 28.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "MKV",
    TradeCenterId: 2,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Dược Thú y Cai Lậy",
    CategoryName: "Nông nghiệp",
    Price: 9.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "MLC",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Môi trường Đô thị Tỉnh Lào Cai",
    CategoryName: "Dịch vụ",
    Price: 16.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "MLG",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Tập đoàn Mai Linh",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "MLS",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Chăn nuôi - Mitraco",
    CategoryName: "Nông nghiệp",
    Price: 13.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "MML",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Masan MEATLife",
    CategoryName: "Hàng tiêu dùng",
    Price: 24.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "MNB",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Tổng Công ty May Nhà Bè - Công ty Cổ phần",
    CategoryName: "Hàng tiêu dùng",
    Price: 30.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "MND",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Môi trường Nam Định",
    CategoryName: "Dịch vụ",
    Price: 11,
    TotalCompany: 1659,
  },
  {
    Symbol: "MPC",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Tập đoàn Thủy sản Minh Phú",
    CategoryName: "Nông nghiệp",
    Price: 15.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "MPT",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Tập đoàn Trường Tiền",
    CategoryName: "Hàng tiêu dùng",
    Price: 0.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "MPY",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Môi trường Đô thị Phú Yên",
    CategoryName: "Dịch vụ",
    Price: 6.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "MQB",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Môi trường và phát triển Đô thị Quảng Bình",
    CategoryName: "Dịch vụ",
    Price: 8.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "MQN",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Môi trường đô thị Quảng Ngãi",
    CategoryName: "Dịch vụ",
    Price: 19.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "MRF",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty cổ phần Merufa",
    CategoryName: "Y tế",
    Price: 19.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "MSB",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại cổ phần Hàng hải Việt Nam",
    CategoryName: "Tài chính",
    Price: 11.35,
    TotalCompany: 1659,
  },
  {
    Symbol: "MSG",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty Chứng khoán JB Việt Nam",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "MSH",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần May Sông Hồng",
    CategoryName: "Hàng tiêu dùng",
    Price: 52,
    TotalCompany: 1659,
  },
  {
    Symbol: "MSI",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán KB Việt Nam",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "MSM",
    TradeCenterId: 8,
    CategoryId: 346,
    CompanyName: "Công ty TNHH Truyền thông Megastar",
    CategoryName: "Dịch vụ",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "MSN",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Tập đoàn MaSan",
    CategoryName: "Tài chính",
    Price: 70.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "MSR",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Masan High-Tech Materials",
    CategoryName: "Nguyên vật liệu",
    Price: 11.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "MST",
    TradeCenterId: 2,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Đầu tư MST",
    CategoryName: "",
    Price: 5.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "MTA",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Tổng Công ty Khoáng sản và Thương mại Hà Tĩnh - CTCP",
    CategoryName: "Nguyên vật liệu",
    Price: 4.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "MTB",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP Môi trường và Công trình Đô thị tỉnh Thái Bình",
    CategoryName: "Dịch vụ",
    Price: 9.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "MTC",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP Dịch vụ Du lịch Mỹ Trà",
    CategoryName: "Dịch vụ",
    Price: 1.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "MTG",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần MT Gas",
    CategoryName: "Năng lượng",
    Price: 8,
    TotalCompany: 1659,
  },
  {
    Symbol: "MTH",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Môi trường Đô thị Hà Đông",
    CategoryName: "Dịch vụ",
    Price: 17.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "MTL",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP Dịch vụ Môi trường Đô thị Từ Liêm",
    CategoryName: "Dịch vụ",
    Price: 5.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "MTP",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược Medipharco",
    CategoryName: "Y tế",
    Price: 12,
    TotalCompany: 1659,
  },
  {
    Symbol: "MTS",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Vật tư - TKV",
    CategoryName: "Nguyên vật liệu",
    Price: 9.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "MTV",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Dịch vụ Môi trường và Công trình Đô thị Vũng Tàu",
    CategoryName: "Dịch vụ",
    Price: 16,
    TotalCompany: 1659,
  },
  {
    Symbol: "MTX",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Công trình Đô thị Gò Công",
    CategoryName: null,
    Price: 14,
    TotalCompany: 1659,
  },
  {
    Symbol: "MVB",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Tổng công ty Công nghiệp mỏ Việt Bắc TKV - CTCP",
    CategoryName: "Nguyên vật liệu",
    Price: 19.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "MVC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Vật liệu và Xây dựng Bình Dương",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 8.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "MVN",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Tổng Công ty Hàng hải Việt Nam - CTCP",
    CategoryName: "Công nghiệp",
    Price: 46.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "MWG",
    TradeCenterId: 1,
    CategoryId: 347,
    CompanyName: "Công ty cổ phần Đầu tư Thế giới Di động",
    CategoryName: "Công nghệ",
    Price: 58,
    TotalCompany: 1659,
  },
  {
    Symbol: "MZG",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần MiZa",
    CategoryName: null,
    Price: 10.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "NAB",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại cổ phần Nam Á",
    CategoryName: "Tài chính",
    Price: 15.35,
    TotalCompany: 1659,
  },
  {
    Symbol: "NAC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tư vấn Xây dựng Tổng hợp",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "NAF",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Nafoods Group ",
    CategoryName: "Nông nghiệp",
    Price: 19.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "NAG",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Tập đoàn Nagakawa",
    CategoryName: "Hàng tiêu dùng",
    Price: 10.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "NAP",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Cảng Nghệ Tĩnh",
    CategoryName: "Công nghiệp",
    Price: 9.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "NAS",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Dịch vụ Hàng không Sân bay Nội Bài",
    CategoryName: "Công nghiệp",
    Price: 26,
    TotalCompany: 1659,
  },
  {
    Symbol: "NAU",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Môi trường và Công trình đô thị Nghệ An",
    CategoryName: "Dịch vụ",
    Price: 9,
    TotalCompany: 1659,
  },
  {
    Symbol: "NAV",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Nam Việt",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 18.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "NAW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Cấp nước Nghệ An",
    CategoryName: "Dịch vụ",
    Price: 7.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "NBB",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư Năm Bảy Bảy",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 24.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "NBC",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Than Núi Béo – Vinacomin",
    CategoryName: "Năng lượng",
    Price: 9,
    TotalCompany: 1659,
  },
  {
    Symbol: "NBE",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Sách và Thiết bị Giáo dục Miền Bắc",
    CategoryName: "Dịch vụ",
    Price: 12,
    TotalCompany: 1659,
  },
  {
    Symbol: "NBP",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Nhiệt điện Ninh Bình",
    CategoryName: "Năng lượng",
    Price: 12.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "NBT",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP Cấp thoát nước Bến Tre",
    CategoryName: "Dịch vụ",
    Price: 17.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "NBW",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Cấp nước Nhà Bè",
    CategoryName: "Dịch vụ",
    Price: 27,
    TotalCompany: 1659,
  },
  {
    Symbol: "NCG",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Tập đoàn Nova Consumer",
    CategoryName: "",
    Price: 8.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "NCS",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Suất ăn Hàng không Nội Bài",
    CategoryName: "Công nghiệp",
    Price: 25.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "NCT",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Dịch vụ Hàng hóa Nội Bài",
    CategoryName: "Công nghiệp",
    Price: 112,
    TotalCompany: 1659,
  },
  {
    Symbol: "ND2",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư Phát triển điện Miền Bắc 2",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 36.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "NDC",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Nam Dược",
    CategoryName: "Y tế",
    Price: 130,
    TotalCompany: 1659,
  },
  {
    Symbol: "NDN",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư Phát triển Nhà Đà Nẵng  ",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 8.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "NDP",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Dược phẩm 2/9 ",
    CategoryName: "Nguyên vật liệu",
    Price: 25.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "NDT",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Tổng Công ty cổ phần Dệt may Nam Định",
    CategoryName: "Hàng tiêu dùng",
    Price: 5.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "NDW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP Cấp nước Nam Định",
    CategoryName: "Dịch vụ",
    Price: 6.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "NDX",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây lắp Phát triển Nhà Đà Nẵng",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "NED",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Đầu tư và Phát triển Điện Tây Bắc",
    CategoryName: "Năng lượng",
    Price: 7.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "NEM",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Thiết bị điện miền Bắc",
    CategoryName: null,
    Price: 21.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "NET",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Bột giặt Net",
    CategoryName: "Hàng tiêu dùng",
    Price: 77,
    TotalCompany: 1659,
  },
  {
    Symbol: "NFC",
    TradeCenterId: 2,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Phân lân Ninh Bình",
    CategoryName: "Nông nghiệp",
    Price: 16.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "NGC",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Chế biến Thủy sản Xuất khẩu Ngô Quyền",
    CategoryName: "Nông nghiệp",
    Price: 1.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "NHA",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Tổng Công ty Đầu tư Phát triển Nhà và Đô thị Nam Hà Nội",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 25.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "NHC",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Gạch Ngói Nhị Hiệp",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 29.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "NHH",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Nhựa Hà Nội",
    CategoryName: "Nguyên vật liệu",
    Price: 13.35,
    TotalCompany: 1659,
  },
  {
    Symbol: "NHP",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Sản xuất Xuất nhập khẩu NHP",
    CategoryName: "Nguyên vật liệu",
    Price: 0.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "NHT",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Sản xuất và Thương mại Nam Hoa",
    CategoryName: "Hàng tiêu dùng",
    Price: 11,
    TotalCompany: 1659,
  },
  {
    Symbol: "NHV",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Đầu tư NHV",
    CategoryName: "Nguyên vật liệu",
    Price: 0.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "NJC",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần May Nam Định",
    CategoryName: "Hàng tiêu dùng",
    Price: 15.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "NKG",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Thép Nam Kim",
    CategoryName: "Nguyên vật liệu",
    Price: 19.25,
    TotalCompany: 1659,
  },
  {
    Symbol: "NLG",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư Nam Long",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 37.15,
    TotalCompany: 1659,
  },
  {
    Symbol: "NLS",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Cấp thoát Nước Lạng Sơn",
    CategoryName: "Dịch vụ",
    Price: 4.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "NNC",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đá Núi Nhỏ",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 20.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "NNG",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Công nghiệp - Dịch vụ - Thương Mại Ngọc Nghĩa",
    CategoryName: "Nguyên vật liệu",
    Price: 14.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "NNT",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Cấp nước Ninh Thuận",
    CategoryName: "Dịch vụ",
    Price: 52.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "NO1",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Tập đoàn 911",
    CategoryName: "Công nghiệp",
    Price: 12.15,
    TotalCompany: 1659,
  },
  {
    Symbol: "NOS",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Vận tải biển và Thương mại Phương Đông",
    CategoryName: "Công nghiệp",
    Price: 0.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "NPM",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty TNHH Khai thác Chế biến Khoáng sản Núi Pháo",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "NQB",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Cấp nước Quảng Bình",
    CategoryName: "Dịch vụ",
    Price: 8,
    TotalCompany: 1659,
  },
  {
    Symbol: "NQN",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Nước sạch Quảng Ninh",
    CategoryName: "Dịch vụ",
    Price: 13.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "NQT",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Nước sạch Quảng Trị",
    CategoryName: "Dịch vụ",
    Price: 8.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "NRC",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tập đoàn Danh Khôi",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "NS2",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Nước sạch số 2 Hà Nội",
    CategoryName: "Dịch vụ",
    Price: 17.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "NSC",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Tập đoàn Giống cây trồng Việt Nam",
    CategoryName: "Nông nghiệp",
    Price: 79,
    TotalCompany: 1659,
  },
  {
    Symbol: "NSG",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "CTCP Nhựa Sài Gòn",
    CategoryName: "Nguyên vật liệu",
    Price: 11,
    TotalCompany: 1659,
  },
  {
    Symbol: "NSH",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Tập đoàn Nhôm Sông Hồng Shalumi",
    CategoryName: "Nguyên vật liệu",
    Price: 4.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "NSI",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán Quốc gia",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "NSL",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Cấp nước Sơn La",
    CategoryName: "Dịch vụ",
    Price: 19.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "NSS",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Nông Súc Sản Đồng Nai",
    CategoryName: "Nông nghiệp",
    Price: 3.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "NST",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Ngân Sơn",
    CategoryName: "Hàng tiêu dùng",
    Price: 10.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "NT2",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Điện lực Dầu khí Nhơn Trạch 2",
    CategoryName: "Năng lượng",
    Price: 19,
    TotalCompany: 1659,
  },
  {
    Symbol: "NTC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Khu Công nghiệp Nam Tân Uyên",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 197.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "NTF",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty cổ phần Dược - Vật tư Y tế Nghệ An",
    CategoryName: "Y tế",
    Price: 14.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "NTH",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần thủy điện Nước Trong",
    CategoryName: "Năng lượng",
    Price: 58,
    TotalCompany: 1659,
  },
  {
    Symbol: "NTL",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Phát triển Đô thị Từ Liêm",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 18.65,
    TotalCompany: 1659,
  },
  {
    Symbol: "NTP",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Nhựa Thiếu niên Tiền Phong",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 58.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "NTT",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Dệt - May Nha Trang",
    CategoryName: "Hàng tiêu dùng",
    Price: 7.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "NTW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Cấp nước Nhơn Trạch",
    CategoryName: "Dịch vụ",
    Price: 19.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "NUE",
    TradeCenterId: 9,
    CategoryId: 350,
    CompanyName: "Công ty Cổ phần Môi trường Đô thị Nha Trang",
    CategoryName: "Doanh nghiệp Upcom",
    Price: 9.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "NVB",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại cổ phần Quốc Dân",
    CategoryName: "Tài chính",
    Price: 8.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "NVL",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Tập đoàn Đầu tư Địa ốc No Va",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "NVP",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Nước sạch Vĩnh Phúc",
    CategoryName: "Dịch vụ",
    Price: 6.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "NVS",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Chứng khoán NAVIBANK",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "NVT",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Bất động sản Du lịch Ninh Vân Bay",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7.72,
    TotalCompany: 1659,
  },
  {
    Symbol: "NWT",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Vận tải Newway",
    CategoryName: "Dịch vụ",
    Price: 6.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "NXT",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Sản xuất và Cung ứng vật liệu xây dựng Kon Tum",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "OCB",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại cổ phần Phương Đông",
    CategoryName: "Tài chính",
    Price: 10.45,
    TotalCompany: 1659,
  },
  {
    Symbol: "OCH",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần One Capital Hospitality",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "OCS",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán Đại Dương",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "ODE",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Tập đoàn Truyền thông và Giải trí ODE",
    CategoryName: "Dịch vụ",
    Price: 43.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "OGC",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tập đoàn Đại Dương",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.88,
    TotalCompany: 1659,
  },
  {
    Symbol: "OIL",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Tổng Công ty Dầu Việt Nam - CTCP",
    CategoryName: "Năng lượng",
    Price: 11.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "OMC",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Quản lý quỹ đầu tư Chứng khoán Phương Đông",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "ONE",
    TradeCenterId: 2,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Công nghệ ONE",
    CategoryName: "Công nghệ",
    Price: 4.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "ONW",
    TradeCenterId: 9,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Dịch vụ Một Thế giới",
    CategoryName: "Công nghệ",
    Price: 3.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "OPC",
    TradeCenterId: 1,
    CategoryId: 342,
    CompanyName: "Công ty cổ phần Dược phẩm OPC",
    CategoryName: "Y tế",
    Price: 23.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "ORS",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán Tiên Phong",
    CategoryName: "Tài chính",
    Price: 13.55,
    TotalCompany: 1659,
  },
  {
    Symbol: "OSC",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán ĐẠI TÂY DƯƠNG",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "PAC",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Pin Ắc quy Miền Nam ",
    CategoryName: "Công nghiệp",
    Price: 36.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "PAI",
    TradeCenterId: 9,
    CategoryId: 347,
    CompanyName: "CTCP Công nghệ thông tin, viễn thông và tự động hóa Dầu khí",
    CategoryName: "Công nghệ",
    Price: 12.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "PAN",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Tập đoàn PAN",
    CategoryName: "Nông nghiệp",
    Price: 22.55,
    TotalCompany: 1659,
  },
  {
    Symbol: "PAP",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Dầu khí Đầu tư Khai thác Cảng Phước An",
    CategoryName: "Công nghiệp",
    Price: 22.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "PAS",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Quốc tế Phương Anh",
    CategoryName: "Nguyên vật liệu",
    Price: 3.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "PAT",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Phốt pho Apatit Việt Nam",
    CategoryName: "Nguyên vật liệu",
    Price: 87,
    TotalCompany: 1659,
  },
  {
    Symbol: "PBC",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "CTCP Dược phẩm Trung ương I - Pharbaco",
    CategoryName: "Y tế",
    Price: 6.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PBP",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Bao bì Dầu khí Việt Nam",
    CategoryName: "Nguyên vật liệu",
    Price: 12.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "PBT",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Nhà và Thương mại Dầu khí",
    CategoryName: "Nguyên vật liệu",
    Price: 8.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PC1",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tập đoàn PC1",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 22.15,
    TotalCompany: 1659,
  },
  {
    Symbol: "PCC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tập đoàn Xây lắp 1 - Petrolimex",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 31,
    TotalCompany: 1659,
  },
  {
    Symbol: "PCE",
    TradeCenterId: 2,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Phân bón và Hóa chất Dầu khí Miền Trung",
    CategoryName: "Nông nghiệp",
    Price: 17.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "PCF",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Cà Phê Petec",
    CategoryName: "Nông nghiệp",
    Price: 4.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "PCG",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Đầu tư và Phát triển Gas Đô Thị",
    CategoryName: "Năng lượng",
    Price: 6.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "PCH",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Nhựa Picomat",
    CategoryName: "Công nghiệp",
    Price: 12.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "PCM",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Vật liệu Xây dựng Bưu điện",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 14.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "PCN",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Hóa phẩm dầu khí DMC - miền Bắc",
    CategoryName: "Nguyên vật liệu",
    Price: 13.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PCT",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Vận tải biển Global Pacific",
    CategoryName: "Công nghiệp",
    Price: 11.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PDB",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tập đoàn đầu tư DIN Capital",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10,
    TotalCompany: 1659,
  },
  {
    Symbol: "PDC",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Du lịch Dầu khí Phương Đông",
    CategoryName: "Dịch vụ",
    Price: 5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PDN",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Cảng Đồng Nai",
    CategoryName: "Công nghiệp",
    Price: 110.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PDR",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Phát triển Bất động sản Phát Đạt",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 20.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "PDV",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Vận tải và Tiếp Vận Phương Đông Việt",
    CategoryName: "Năng lượng",
    Price: 16.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "PEC",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Cơ khí Điện lực",
    CategoryName: "Nguyên vật liệu",
    Price: 10,
    TotalCompany: 1659,
  },
  {
    Symbol: "PEG",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Tổng Công ty Thương mại Kỹ thuật và Đầu tư - CTCP",
    CategoryName: "Năng lượng",
    Price: 5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PEN",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Xây lắp III Petrolimex",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "PEQ",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Thiết bị Xăng dầu Petrolimex",
    CategoryName: "Năng lượng",
    Price: 39.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "PET",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Tổng Công ty Cổ phần Dịch vụ Tổng hợp Dầu khí",
    CategoryName: "Dịch vụ",
    Price: 25.25,
    TotalCompany: 1659,
  },
  {
    Symbol: "PFI",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Đầu tư Tài chính Công đoàn Dầu khí",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "PFL",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Dầu khí Đông Đô",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2,
    TotalCompany: 1659,
  },
  {
    Symbol: "PGB",
    TradeCenterId: 9,
    CategoryId: 341,
    CompanyName: "Ngân hàng TMCP Thịnh vượng và Phát triển",
    CategoryName: "Tài chính",
    Price: 16.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "PGC",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Tổng Công ty Gas Petrolimex-CTCP",
    CategoryName: "Năng lượng",
    Price: 15,
    TotalCompany: 1659,
  },
  {
    Symbol: "PGD",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Phân phối Khí thấp áp Dầu khí Việt Nam",
    CategoryName: "Năng lượng",
    Price: 31.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "PGI",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Tổng Công ty cổ phần Bảo hiểm Petrolimex",
    CategoryName: "Tài chính",
    Price: 23,
    TotalCompany: 1659,
  },
  {
    Symbol: "PGN",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Phụ gia Nhựa",
    CategoryName: "Nguyên vật liệu",
    Price: 10.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "PGS",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Kinh doanh Khí Miền Nam",
    CategoryName: "Năng lượng",
    Price: 32.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PGT",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần PGT Holdings",
    CategoryName: "Công nghiệp",
    Price: 7,
    TotalCompany: 1659,
  },
  {
    Symbol: "PGV",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Tổng Công ty Phát điện 3 - Công ty cổ phần",
    CategoryName: "Năng lượng",
    Price: 19.35,
    TotalCompany: 1659,
  },
  {
    Symbol: "PHC",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Xây dựng Phục Hưng Holdings",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5.54,
    TotalCompany: 1659,
  },
  {
    Symbol: "PHH",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Hồng Hà Việt Nam",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "PHN",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Pin Hà Nội",
    CategoryName: "Công nghiệp",
    Price: 85,
    TotalCompany: 1659,
  },
  {
    Symbol: "PHP",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Cảng Hải Phòng",
    CategoryName: "Công nghiệp",
    Price: 36.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "PHR",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Cao su Phước Hòa",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 55.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "PHS",
    TradeCenterId: 9,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán Phú Hưng",
    CategoryName: "Tài chính",
    Price: 11.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "PIA",
    TradeCenterId: 2,
    CategoryId: 347,
    CompanyName: "Công ty cổ phần Tin học Viễn thông Petrolimex",
    CategoryName: "Công nghệ",
    Price: 27.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "PIC",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Đầu tư Điện lực 3",
    CategoryName: "Năng lượng",
    Price: 19.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "PIS",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Tổng Công ty Pisico Bình Định - CTCP",
    CategoryName: "Hàng tiêu dùng",
    Price: 9.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "PIT",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Xuất nhập khẩu Petrolimex",
    CategoryName: "Dịch vụ",
    Price: 5.35,
    TotalCompany: 1659,
  },
  {
    Symbol: "PIV",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần PIV",
    CategoryName: "Dịch vụ",
    Price: 3,
    TotalCompany: 1659,
  },
  {
    Symbol: "PJC",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Thương mại và Vận tải Petrolimex Hà Nội",
    CategoryName: "Năng lượng",
    Price: 28.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "PJS",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Cấp nước Phú Hòa Tân",
    CategoryName: "Dịch vụ",
    Price: 21.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "PJT",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Vận tải Xăng dầu Đường thủy Petrolimex",
    CategoryName: "Công nghiệp",
    Price: 9.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "PLA",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư Dịch vụ Hạ tầng Xăng Dầu",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "PLC",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Tổng Công ty Hóa dầu Petrolimex-CTCP",
    CategoryName: "Nguyên vật liệu",
    Price: 21.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "PLE",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Tư vấn Xây dựng Petrolimex",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 13.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "PLO",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Kho Vận Petec ",
    CategoryName: "Công nghiệp",
    Price: 3.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PLP",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Sản xuất và Công nghệ Nhựa Pha Lê",
    CategoryName: "Nguyên vật liệu",
    Price: 4.38,
    TotalCompany: 1659,
  },
  {
    Symbol: "PLX",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Tập đoàn Xăng dầu Việt Nam",
    CategoryName: "Năng lượng",
    Price: 38.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "PMB",
    TradeCenterId: 2,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Phân bón và Hóa chất Dầu khí Miền Bắc",
    CategoryName: "Nông nghiệp",
    Price: 9.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "PMC",
    TradeCenterId: 2,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược phẩm Dược liệu Pharmedic",
    CategoryName: "Y tế",
    Price: 107,
    TotalCompany: 1659,
  },
  {
    Symbol: "PMG",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Đầu tư và Sản xuất Petro Miền Trung",
    CategoryName: "Năng lượng",
    Price: 8.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "PMJ",
    TradeCenterId: 9,
    CategoryId: 347,
    CompanyName: "Công ty cổ phần Vật tư Bưu điện",
    CategoryName: "Công nghệ",
    Price: 18,
    TotalCompany: 1659,
  },
  {
    Symbol: "PMP",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Bao bì Đạm Phú Mỹ",
    CategoryName: "Nguyên vật liệu",
    Price: 13.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "PMS",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Cơ khí Xăng dầu",
    CategoryName: "Công nghiệp",
    Price: 33.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PMT",
    TradeCenterId: 9,
    CategoryId: 347,
    CompanyName: "Công ty cổ phần Viễn thông TELVINA Việt Nam",
    CategoryName: "Công nghệ",
    Price: 7.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "PMW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Cấp nước Phú Mỹ",
    CategoryName: "Dịch vụ",
    Price: 37.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PNC",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Văn hóa Phương Nam ",
    CategoryName: "Dịch vụ",
    Price: 16.95,
    TotalCompany: 1659,
  },
  {
    Symbol: "PND",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Xăng dầu Dầu khí Nam Định",
    CategoryName: "Năng lượng",
    Price: 6.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "PNG",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Thương mại Phú Nhuận",
    CategoryName: "Hàng tiêu dùng",
    Price: 17.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "PNJ",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Vàng bạc Đá quý Phú Nhuận",
    CategoryName: "Dịch vụ",
    Price: 91.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "PNP",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Tân Cảng - Phú Hữu",
    CategoryName: "Công nghiệp",
    Price: 22.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "PNT",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Kỹ thuật Xây dựng Phú Nhuận",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "POB",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Xăng dầu Dầu khí Thái Bình",
    CategoryName: "Năng lượng",
    Price: 50.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "POM",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Thép Pomina",
    CategoryName: "Nguyên vật liệu",
    Price: 2.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "POS",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Dịch vụ Lắp đặt, Vận hành và Bảo dưỡng Công trình Dầu khí biển PTSC",
    CategoryName: "Năng lượng",
    Price: 20,
    TotalCompany: 1659,
  },
  {
    Symbol: "POT",
    TradeCenterId: 2,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Thiết bị Bưu điện",
    CategoryName: "Công nghệ",
    Price: 16.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "POV",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Xăng dầu Dầu khí Vũng Áng",
    CategoryName: "Năng lượng",
    Price: 8.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "POW",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Tổng Công ty Điện lực Dầu khí Việt Nam - CTCP",
    CategoryName: "Năng lượng",
    Price: 11.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "PPC",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Nhiệt điện Phả Lại",
    CategoryName: "Năng lượng",
    Price: 11.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "PPE",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Tư vấn Đầu tư PP Enterprise",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 14.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PPH",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Tổng Công ty Cổ phần Phong Phú",
    CategoryName: "Hàng tiêu dùng",
    Price: 29.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "PPI",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Phát triển Dự án Hạ tầng Thái Bình Dương",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "PPP",
    TradeCenterId: 2,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược phẩm Phong Phú",
    CategoryName: "Y tế",
    Price: 18,
    TotalCompany: 1659,
  },
  {
    Symbol: "PPS",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Dịch vụ Kỹ thuật Điện lực Dầu khí Việt Nam",
    CategoryName: "Công nghiệp",
    Price: 11.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "PPT",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Petro Times",
    CategoryName: "Năng lượng",
    Price: 12.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "PPY",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Xăng dầu Dầu khí Phú Yên",
    CategoryName: "Năng lượng",
    Price: 8.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PQG",
    TradeCenterId: 8,
    CategoryId: 346,
    CompanyName: "CTCP Đầu tư vàng Phú Quý",
    CategoryName: "Dịch vụ",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "PQN",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Dịch vụ Dầu khí Quảng Ngãi PTSC",
    CategoryName: "Năng lượng",
    Price: 4,
    TotalCompany: 1659,
  },
  {
    Symbol: "PRC",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Logistics Portserco",
    CategoryName: "Công nghiệp",
    Price: 20,
    TotalCompany: 1659,
  },
  {
    Symbol: "PRE",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Tổng Công ty Cổ phần Tái bảo hiểm Hà Nội (Hanoi Re)",
    CategoryName: "Tài chính",
    Price: 19,
    TotalCompany: 1659,
  },
  {
    Symbol: "PRO",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Procimex Việt Nam",
    CategoryName: "Nông nghiệp",
    Price: 4.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "PRT",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Tổng công ty Sản xuất - Xuất nhập khẩu Bình Dương - CTCP",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "PSB",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Đầu tư Dầu khí Sao Mai – Bến Đình",
    CategoryName: "Nguyên vật liệu",
    Price: 5.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PSC",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Vận tải và Dịch vụ Petrolimex Sài Gòn",
    CategoryName: "Năng lượng",
    Price: 10.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "PSD",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Dịch vụ Phân phối Tổng hợp Dầu khí",
    CategoryName: "Dịch vụ",
    Price: 12.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "PSE",
    TradeCenterId: 2,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Phân bón và Hóa chất Dầu khí Đông Nam Bộ",
    CategoryName: "Nông nghiệp",
    Price: 9.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PSG",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Xây lắp Dầu khí Sài Gòn  ",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PSH",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Thương mại Đầu tư Dầu khí Nam Sông Hậu",
    CategoryName: "Năng lượng",
    Price: 3.66,
    TotalCompany: 1659,
  },
  {
    Symbol: "PSI",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Chứng khoán Dầu khí",
    CategoryName: "Tài chính",
    Price: 6.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "PSL",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Chăn nuôi Phú Sơn",
    CategoryName: "Nông nghiệp",
    Price: 11.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "PSN",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Dịch vụ Kỹ thuật PTSC Thanh Hóa",
    CategoryName: "Năng lượng",
    Price: 11,
    TotalCompany: 1659,
  },
  {
    Symbol: "PSP",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Cảng dịch vụ Dầu khí Đình Vũ",
    CategoryName: "Công nghiệp",
    Price: 12,
    TotalCompany: 1659,
  },
  {
    Symbol: "PSW",
    TradeCenterId: 2,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Phân bón và Hóa chất Dầu khí Tây Nam Bộ",
    CategoryName: "Nông nghiệp",
    Price: 8.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "PTB",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Phú Tài",
    CategoryName: "Dịch vụ",
    Price: 63.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PTC",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Đầu tư ICapital",
    CategoryName: "Công nghiệp",
    Price: 4.74,
    TotalCompany: 1659,
  },
  {
    Symbol: "PTD",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "CTCP Thiết kế - Xây dựng - Thương mại Phúc Thịnh",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 9.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "PTE",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xi măng Phú Thọ",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "PTG",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần May Xuất khẩu Phan Thiết",
    CategoryName: "Hàng tiêu dùng",
    Price: 0.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "PTH",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Vận tải và Dịch vụ Petrolimex Hà Tây",
    CategoryName: "Năng lượng",
    Price: 16,
    TotalCompany: 1659,
  },
  {
    Symbol: "PTI",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Tổng Công ty Cổ phần Bảo hiểm Bưu điện ",
    CategoryName: "Tài chính",
    Price: 30.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "PTL",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Victory Group",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.54,
    TotalCompany: 1659,
  },
  {
    Symbol: "PTN",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Phát triển Nhà Khánh Hòa",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "PTO",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Dịch vụ - Xây dựng Công trình Bưu điện",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 17.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "PTP",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Dịch vụ Viễn thông và In Bưu điện",
    CategoryName: "Hàng tiêu dùng",
    Price: 11,
    TotalCompany: 1659,
  },
  {
    Symbol: "PTS",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Vận tải và Dịch vụ Petrolimex Hải Phòng",
    CategoryName: "Năng lượng",
    Price: 8,
    TotalCompany: 1659,
  },
  {
    Symbol: "PTT",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Vận tải Dầu khí Đông Dương",
    CategoryName: "Năng lượng",
    Price: 10.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "PTV",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Thương mại Dầu khí",
    CategoryName: "Năng lượng",
    Price: 4.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "PV2",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư PV2",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "PVA",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tổng Công ty Xây lắp Dầu khí Nghệ An",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PVB",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Bọc ống Dầu khí Việt Nam",
    CategoryName: "Công nghiệp",
    Price: 26.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "PVC",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Tổng Công ty Hóa chất và Dịch vụ Dầu khí - CTCP",
    CategoryName: "Nguyên vật liệu",
    Price: 10.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "PVD",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Tổng Công ty Cổ phần Khoan và Dịch vụ Khoan Dầu khí",
    CategoryName: "Năng lượng",
    Price: 23.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "PVE",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Tổng Công ty Tư vấn Thiết kế Dầu khí-CTCP",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "PVG",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Kinh doanh LPG Việt Nam",
    CategoryName: "Năng lượng",
    Price: 6.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "PVH",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Xây lắp dầu khí Thanh Hóa",
    CategoryName: "Nguyên vật liệu",
    Price: 0.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "PVI",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần PVI",
    CategoryName: "Dịch vụ",
    Price: 47.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "PVL",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư Nhà đất Việt",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "PVM",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Máy - Thiết bị Dầu khí",
    CategoryName: "Năng lượng",
    Price: 15.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "PVN",
    TradeCenterId: 8,
    CategoryId: 340,
    CompanyName: "Tập đoàn Dầu khí Việt Nam",
    CategoryName: "Năng lượng",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "PVO",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "CTCP Dầu nhờn PV Oil",
    CategoryName: "Nguyên vật liệu",
    Price: 6.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "PVP",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Vận tải dầu khí Thái Bình Dương",
    CategoryName: "Năng lượng",
    Price: 16.05,
    TotalCompany: 1659,
  },
  {
    Symbol: "PVR",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần đầu tư PVR Hà Nội",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1,
    TotalCompany: 1659,
  },
  {
    Symbol: "PVS",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Tổng Công ty Cổ phần Dịch vụ Kỹ thuật Dầu khí Việt Nam",
    CategoryName: "Năng lượng",
    Price: 33.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "PVT",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Tổng công ty Cổ phần Vận tải Dầu khí",
    CategoryName: "Năng lượng",
    Price: 27.65,
    TotalCompany: 1659,
  },
  {
    Symbol: "PVV",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Vinaconex 39",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PVX",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Tổng Công ty cổ phần Xây lắp Dầu khí Việt Nam",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "PVY",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Chế tạo Giàn khoan Dầu khí",
    CategoryName: "Công nghiệp",
    Price: 2.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PWA",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Bất động sản Dầu khí",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "PWS",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Cấp thoát nước Phú Yên",
    CategoryName: "Dịch vụ",
    Price: 12,
    TotalCompany: 1659,
  },
  {
    Symbol: "PX1",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Xi măng Sông Lam 2",
    CategoryName: "Nguyên vật liệu",
    Price: 10,
    TotalCompany: 1659,
  },
  {
    Symbol: "PXA",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư & Thương mại Dầu khí Nghệ A",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "PXC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Phát triển đô thị Dầu khí",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PXI",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng công nghiệp và dân dụng Dầu khí",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "PXL",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Tổng Công ty cổ phần Đầu tư và Phát triển KCN Dầu khí-Long Sơn",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 13.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "PXM",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây lắp Dầu khí Miền Trung",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PXS",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Kết cấu Kim loại và Lắp máy Dầu khí",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "PXT",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây lắp Đường ống Bể chứa Dầu khí",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "QBS",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Xuất nhập khẩu Quảng Bình",
    CategoryName: "Nông nghiệp",
    Price: 0.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "QCC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Đầu tư Xây dựng và Phát triển Hạ tầng Viễn thông",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 8.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "QCG",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Quốc Cường Gia Lai",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 12.65,
    TotalCompany: 1659,
  },
  {
    Symbol: "QHD",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Que hàn điện Việt Đức",
    CategoryName: "Nguyên vật liệu",
    Price: 34.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "QHW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP Nước khoáng Quảng Ninh",
    CategoryName: "Dịch vụ",
    Price: 35,
    TotalCompany: 1659,
  },
  {
    Symbol: "QNC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xi măng và Xây dựng Quảng Ninh",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "QNP",
    TradeCenterId: 1,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Cảng Quy Nhơn",
    CategoryName: "",
    Price: 35,
    TotalCompany: 1659,
  },
  {
    Symbol: "QNS",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Đường Quảng Ngãi",
    CategoryName: "Nông nghiệp",
    Price: 49.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "QNT",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Tư vấn và Đầu tư Phát triển Quảng Nam",
    CategoryName: "",
    Price: 7,
    TotalCompany: 1659,
  },
  {
    Symbol: "QNU",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Môi trường Đô thị Quảng Nam",
    CategoryName: "Dịch vụ",
    Price: 9.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "QNW",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "CTCP Cấp thoát nước và Xây dựng Quảng Ngãi",
    CategoryName: "Công nghiệp",
    Price: 17.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "QPH",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Thủy điện Quế Phong",
    CategoryName: "Năng lượng",
    Price: 27.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "QSP",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Tân Cảng Quy Nhơn",
    CategoryName: "Công nghiệp",
    Price: 25,
    TotalCompany: 1659,
  },
  {
    Symbol: "QST",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Sách và Thiết bị trường học Quảng Ninh",
    CategoryName: "Dịch vụ",
    Price: 20.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "QTC",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Công trình Giao thông Vận tải Quảng Nam",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 15.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "QTP",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Nhiệt điện Quảng Ninh",
    CategoryName: "Năng lượng",
    Price: 13.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "RAL",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Bóng đèn Phích nước Rạng Đông",
    CategoryName: "Hàng tiêu dùng",
    Price: 124.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "RAT",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "CTCP Vận tải và Thương mại Đường sắt",
    CategoryName: "Công nghiệp",
    Price: 14.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "RBC",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "CTCP Công nghiệp và Xuất nhập khẩu Cao Su",
    CategoryName: "Nông nghiệp",
    Price: 6.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "RCC",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Tổng công ty Công trình đường sắt",
    CategoryName: "Công nghiệp",
    Price: 16,
    TotalCompany: 1659,
  },
  {
    Symbol: "RCD",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Xây dựng - Địa ốc Cao su",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "RCL",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Địa ốc Chợ Lớn",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 11.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "RDP",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Rạng Đông Holding",
    CategoryName: "Nguyên vật liệu",
    Price: 1.75,
    TotalCompany: 1659,
  },
  {
    Symbol: "REE",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Cơ điện lạnh",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 63.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "RGC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư PV - Inconess",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "RIC",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Quốc tế Hoàng Gia",
    CategoryName: "Dịch vụ",
    Price: 3.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "RTB",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Cao su Tân Biên",
    CategoryName: "Nông nghiệp",
    Price: 27.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "RTD",
    TradeCenterId: 8,
    CategoryId: 338,
    CompanyName: "CTCP Phát triển công nghệ nông thôn",
    CategoryName: "Nông nghiệp",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "S12",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Sông Đà 12",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "S27",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Sông Đà 27",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "S4A",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Thủy điện Sê San 4A",
    CategoryName: "Năng lượng",
    Price: 40,
    TotalCompany: 1659,
  },
  {
    Symbol: "S55",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Sông Đà 505",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 64.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "S72",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Sông Đà 7.02",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "S74",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Sông Đà 7.04",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 15,
    TotalCompany: 1659,
  },
  {
    Symbol: "S99",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần SCI",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "SAB",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Tổng CTCP Bia - Rượu - Nước giải khát Sài Gòn",
    CategoryName: "Hàng tiêu dùng",
    Price: 55.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "SAC",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Xếp dỡ và Dịch vụ Cảng Sài Gòn",
    CategoryName: "Công nghiệp",
    Price: 12.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "SAF",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Lương thực Thực phẩm Safoco",
    CategoryName: "Hàng tiêu dùng",
    Price: 58.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "SAL",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Trục vớt cứu hộ Việt Nam",
    CategoryName: "Công nghiệp",
    Price: 1.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "SAM",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần SAM Holdings",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.31,
    TotalCompany: 1659,
  },
  {
    Symbol: "SAP",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần In Sách giáo khoa tại Tp.Hồ Chí Minh",
    CategoryName: "Dịch vụ",
    Price: 32,
    TotalCompany: 1659,
  },
  {
    Symbol: "SAS",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "CTCP Dịch vụ Hàng không Sân bay Tân Sơn Nhất",
    CategoryName: "Công nghiệp",
    Price: 34.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "SAV",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Hợp tác kinh tế và Xuất nhập khẩu SAVIMEX",
    CategoryName: "Hàng tiêu dùng",
    Price: 20.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "SB1",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Bia Sài Gòn - Nghệ Tĩnh",
    CategoryName: "Hàng tiêu dùng",
    Price: 6,
    TotalCompany: 1659,
  },
  {
    Symbol: "SBA",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Sông Ba",
    CategoryName: "Năng lượng",
    Price: 30.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "SBB",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Tập đoàn Bia Sài Gòn Bình Tây",
    CategoryName: null,
    Price: 18.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "SBD",
    TradeCenterId: 9,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Công nghệ Sao Bắc Đẩu",
    CategoryName: "Công nghệ",
    Price: 7.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "SBF",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Quản lý quỹ Sabeco",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "SBG",
    TradeCenterId: 1,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Tập đoàn Cơ khí Công nghệ cao SIBA",
    CategoryName: null,
    Price: 10.25,
    TotalCompany: 1659,
  },
  {
    Symbol: "SBH",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Thủy điện Sông Ba Hạ",
    CategoryName: "Năng lượng",
    Price: 49.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "SBL",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Bia Sài Gòn - Bạc Liêu",
    CategoryName: "Hàng tiêu dùng",
    Price: 8,
    TotalCompany: 1659,
  },
  {
    Symbol: "SBM",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Đầu tư Phát triển Bắc Minh",
    CategoryName: "Năng lượng",
    Price: 31,
    TotalCompany: 1659,
  },
  {
    Symbol: "SBR",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Cao su Sông Bé",
    CategoryName: "Nông nghiệp",
    Price: 9.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "SBS",
    TradeCenterId: 9,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán SBS",
    CategoryName: "Tài chính",
    Price: 4.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "SBT",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Thành Thành Công - Biên Hòa",
    CategoryName: "Nông nghiệp",
    Price: 11.85,
    TotalCompany: 1659,
  },
  {
    Symbol: "SBV",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "CTCP Siam Brothers Việt Nam",
    CategoryName: "Nguyên vật liệu",
    Price: 9.61,
    TotalCompany: 1659,
  },
  {
    Symbol: "SC5",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng số 5 ",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 21,
    TotalCompany: 1659,
  },
  {
    Symbol: "SCB",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại Cổ phần Sài Gòn",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "SCC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư Thương mại Hưng Long tỉnh Hòa Bình",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "SCD",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Nước giải khát Chương Dương",
    CategoryName: "Hàng tiêu dùng",
    Price: 13.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "SCG",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Tập đoàn Xây dựng SCG",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 65.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "SCI",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần SCI E&C",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "SCJ",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xi măng Sài Sơn",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "SCL",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Sông Đà Cao Cường",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 26.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "SCO",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Công nghiệp Thủy sản",
    CategoryName: "Nông nghiệp",
    Price: 5.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "SCR",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Địa ốc Sài Gòn Thương Tín",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5.16,
    TotalCompany: 1659,
  },
  {
    Symbol: "SCS",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Dịch vụ Hàng hóa Sài Gòn",
    CategoryName: "Dịch vụ",
    Price: 78.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "SCY",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Đóng tàu Sông Cấm",
    CategoryName: "Công nghiệp",
    Price: 11.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "SD2",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Sông Đà 2",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "SD3",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Sông Đà 3",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "SD4",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Sông Đà 4",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3,
    TotalCompany: 1659,
  },
  {
    Symbol: "SD5",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Sông Đà 5",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "SD6",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Sông Đà 6",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3,
    TotalCompany: 1659,
  },
  {
    Symbol: "SD7",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Sông Đà 7",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "SD9",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Sông Đà 9",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 11.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "SDA",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Simco Sông Đà",
    CategoryName: "Dịch vụ",
    Price: 5,
    TotalCompany: 1659,
  },
  {
    Symbol: "SDC",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tư vấn Sông Đà",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "SDD",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Xây lắp Sông Đà",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "SDG",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Sadico Cần Thơ",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 14.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "SDJ",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Sông Đà 25",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "SDK",
    TradeCenterId: 9,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Cơ khí Luyện kim",
    CategoryName: "Công nghệ",
    Price: 18.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "SDN",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Sơn Đồng Nai",
    CategoryName: "Nguyên vật liệu",
    Price: 33.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "SDP",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần SDP",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1,
    TotalCompany: 1659,
  },
  {
    Symbol: "SDT",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Sông Đà 10",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "SDU",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư Xây dựng và Phát triển Đô thị Sông Đà",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 17.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "SDV",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Dịch vụ Sonadezi",
    CategoryName: "Dịch vụ",
    Price: 30.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "SDY",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xi măng Sông Đà Yaly",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "SEA",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Tổng công ty Thủy sản Việt Nam – CTCP",
    CategoryName: "Nông nghiệp",
    Price: 36.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "SEB",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Đầu tư và Phát triển Điện miền Trung",
    CategoryName: "Năng lượng",
    Price: 47,
    TotalCompany: 1659,
  },
  {
    Symbol: "SED",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Đầu tư và Phát triển giáo dục Phương Nam",
    CategoryName: "Dịch vụ",
    Price: 21.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "SEP",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Tổng Công ty Thương mại Quảng Trị",
    CategoryName: "Dịch vụ",
    Price: 17.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "SEV",
    TradeCenterId: 8,
    CategoryId: 347,
    CompanyName: "Công ty TNHH Samsung Electronics Việt Nam",
    CategoryName: "Công nghệ",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "SFC",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Nhiên liệu Sài Gòn",
    CategoryName: "Năng lượng",
    Price: 23.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "SFG",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Phân bón Miền Nam",
    CategoryName: "Nông nghiệp",
    Price: 10.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "SFI",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Đại lý Vận tải SAFI",
    CategoryName: "Công nghiệp",
    Price: 31,
    TotalCompany: 1659,
  },
  {
    Symbol: "SFN",
    TradeCenterId: 2,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Dệt lưới Sài Gòn",
    CategoryName: "Nông nghiệp",
    Price: 18.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "SGB",
    TradeCenterId: 9,
    CategoryId: 341,
    CompanyName: "Ngân hàng TMCP Sài Gòn Công thương",
    CategoryName: "Tài chính",
    Price: 12.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "SGC",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Xuất nhập khẩu Sa Giang ",
    CategoryName: "Hàng tiêu dùng",
    Price: 111.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "SGD",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Sách Giáo dục tại T.P Hồ Chí Minh",
    CategoryName: "Dịch vụ",
    Price: 12,
    TotalCompany: 1659,
  },
  {
    Symbol: "SGH",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Khách sạn Sài Gòn",
    CategoryName: "Dịch vụ",
    Price: 31.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "SGI",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Đầu tư phát triển Sài Gòn 3 Group",
    CategoryName: "Hàng tiêu dùng",
    Price: 14.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "SGN",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Phục vụ Mặt đất Sài Gòn ",
    CategoryName: "Công nghiệp",
    Price: 77.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "SGP",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Cảng Sài Gòn",
    CategoryName: "Công nghiệp",
    Price: 24.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "SGR",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Tổng Công ty cổ phần Địa ốc Sài Gòn",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 39.85,
    TotalCompany: 1659,
  },
  {
    Symbol: "SGS",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Vận tải biển Sài Gòn",
    CategoryName: "Công nghiệp",
    Price: 18.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "SGT",
    TradeCenterId: 1,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Công nghệ Viễn thông Sài Gòn",
    CategoryName: "Công nghệ",
    Price: 14.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "SHA",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Sơn Hà Sài Gòn",
    CategoryName: "Nguyên vật liệu",
    Price: 4.26,
    TotalCompany: 1659,
  },
  {
    Symbol: "SHB",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại cổ phần Sài Gòn - Hà Nội",
    CategoryName: "Tài chính",
    Price: 10.15,
    TotalCompany: 1659,
  },
  {
    Symbol: "SHC",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Hàng Hải Sài Gòn",
    CategoryName: "Công nghiệp",
    Price: 12.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "SHE",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Phát triển năng lượng Sơn Hà",
    CategoryName: "Năng lượng",
    Price: 9,
    TotalCompany: 1659,
  },
  {
    Symbol: "SHF",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Quản lý quỹ Đầu tư Sài Gòn - Hà Nội",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "SHG",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Tổng Công ty Cổ phần Sông Hồng",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2,
    TotalCompany: 1659,
  },
  {
    Symbol: "SHI",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Quốc tế Sơn Hà",
    CategoryName: "Hàng tiêu dùng",
    Price: 14.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "SHN",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Đầu tư Tổng hợp Hà Nội",
    CategoryName: "Tài chính",
    Price: 6.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "SHP",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Thủy điện Miền Nam",
    CategoryName: "Năng lượng",
    Price: 34.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "SHS",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng Khoán Sài Gòn Hà Nội",
    CategoryName: "Tài chính",
    Price: 13.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "SHX",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Sài Gòn Hỏa xa",
    CategoryName: "Hàng tiêu dùng",
    Price: 1.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "SIC",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần ANI",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 22,
    TotalCompany: 1659,
  },
  {
    Symbol: "SID",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Đầu tư Phát triển Sài Gòn Co.op",
    CategoryName: "Dịch vụ",
    Price: 12,
    TotalCompany: 1659,
  },
  {
    Symbol: "SIG",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Đầu tư và Thương mại Sông Đà",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "SII",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Hạ tầng nước Sài Gòn",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 16.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "SIP",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư Sài Gòn VRG",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 78.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "SIV",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần SIVICO",
    CategoryName: "Nguyên vật liệu",
    Price: 37.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "SJ1",
    TradeCenterId: 2,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Nông nghiệp Hùng Hậu",
    CategoryName: "Nông nghiệp",
    Price: 11,
    TotalCompany: 1659,
  },
  {
    Symbol: "SJC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Sông Đà 1.01",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "SJD",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Thủy điện Cần Đơn",
    CategoryName: "Năng lượng",
    Price: 14.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "SJE",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Sông Đà 11",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 20.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "SJF",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "CTCP Đầu tư Sao Thái Dương",
    CategoryName: "Nông nghiệp",
    Price: 1.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "SJG",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Tổng Công ty Sông Đà - Công ty cổ phần",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 17.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "SJM",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Sông Đà 19",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "SJS",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần SJ Group",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 61,
    TotalCompany: 1659,
  },
  {
    Symbol: "SKG",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Tàu cao tốc Superdong – Kiên Giang",
    CategoryName: "Công nghiệp",
    Price: 10.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "SKH",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Nước giải khát Sanest Khánh Hòa",
    CategoryName: "Hàng tiêu dùng",
    Price: 25.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "SKN",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "CTCP Nước giải khát Sanna Khánh Hòa",
    CategoryName: "Hàng tiêu dùng",
    Price: 7.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "SKV",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Nước giải khát Yến sào Khánh Hòa",
    CategoryName: "Hàng tiêu dùng",
    Price: 31.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "SLD",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Địa ốc Sacom",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "SLS",
    TradeCenterId: 2,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Mía đường Sơn La",
    CategoryName: "Nông nghiệp",
    Price: 175.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "SMA",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Thiết bị Phụ tùng Sài Gòn",
    CategoryName: "Dịch vụ",
    Price: 10.75,
    TotalCompany: 1659,
  },
  {
    Symbol: "SMB",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Bia Sài Gòn - Miền Trung",
    CategoryName: "Hàng tiêu dùng",
    Price: 38.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "SMC",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Ðầu tư Thương mại SMC",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.99,
    TotalCompany: 1659,
  },
  {
    Symbol: "SMN",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Sách và Thiết bị Giáo dục Miền Nam",
    CategoryName: "Dịch vụ",
    Price: 13,
    TotalCompany: 1659,
  },
  {
    Symbol: "SMT",
    TradeCenterId: 2,
    CategoryId: 347,
    CompanyName: "Công ty cổ phần SAMETEL",
    CategoryName: "Công nghệ",
    Price: 5.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "SNC",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Xuất nhập khẩu Thuỷ sản Năm Căn",
    CategoryName: "Hàng tiêu dùng",
    Price: 22,
    TotalCompany: 1659,
  },
  {
    Symbol: "SNP",
    TradeCenterId: 8,
    CategoryId: 343,
    CompanyName: "Công ty TNHH MTV - Tổng Công ty Tân Cảng Sài Gòn",
    CategoryName: "Công nghiệp",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "SNZ",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Tổng Công ty Cổ phần phát triển khu Công nghiệp",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 33.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "SP2",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Thủy điện Sử Pán 2",
    CategoryName: "Năng lượng",
    Price: 18.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "SPB",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Sợi Phú Bài",
    CategoryName: "Hàng tiêu dùng",
    Price: 17.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "SPC",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Bảo vệ Thực vật Sài Gòn",
    CategoryName: "Nguyên vật liệu",
    Price: 8.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "SPD",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Xuất nhập khẩu Thủy sản Miền Trung",
    CategoryName: "Hàng tiêu dùng",
    Price: 8,
    TotalCompany: 1659,
  },
  {
    Symbol: "SPH",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Xuất nhập khẩu Thủy sản Hà Nội",
    CategoryName: "Nông nghiệp",
    Price: 8,
    TotalCompany: 1659,
  },
  {
    Symbol: "SPI",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Spiral Galaxy",
    CategoryName: "Công nghiệp",
    Price: 2.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "SPM",
    TradeCenterId: 1,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần S.P.M",
    CategoryName: "Y tế",
    Price: 10.65,
    TotalCompany: 1659,
  },
  {
    Symbol: "SPT",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Dịch vụ Bưu chính Viễn thông Sài Gòn",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "SPV",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Thủy Đặc Sản",
    CategoryName: "Nông nghiệp",
    Price: 15.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "SQC",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Khoáng sản Sài Gòn - Quy Nhơn",
    CategoryName: "Nguyên vật liệu",
    Price: 11.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "SRA",
    TradeCenterId: 2,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Sara Việt Nam",
    CategoryName: "Công nghệ",
    Price: 2.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "SRB",
    TradeCenterId: 9,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Tập đoàn Sara",
    CategoryName: "Công nghệ",
    Price: 1.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "SRC",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Cao Su Sao Vàng",
    CategoryName: "Công nghiệp",
    Price: 26.35,
    TotalCompany: 1659,
  },
  {
    Symbol: "SRF",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần SEAREFICO",
    CategoryName: "Công nghiệp",
    Price: 8.39,
    TotalCompany: 1659,
  },
  {
    Symbol: "SRT",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Vận tải Đường sắt Sài Gòn",
    CategoryName: "Công nghiệp",
    Price: 10.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "SSB",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại cổ phần Đông Nam Á",
    CategoryName: "Tài chính",
    Price: 16.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "SSC",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Giống cây trồng Miền Nam ",
    CategoryName: "Nông nghiệp",
    Price: 37,
    TotalCompany: 1659,
  },
  {
    Symbol: "SSF",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Giầy Sài Gòn",
    CategoryName: "Hàng tiêu dùng",
    Price: 20.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "SSG",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Vận tải biển Hải Âu",
    CategoryName: "Công nghiệp",
    Price: 10.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "SSH",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Phát triển Sunshine Homes",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 67.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "SSI",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Chứng khoán SSI",
    CategoryName: "Tài chính",
    Price: 24.35,
    TotalCompany: 1659,
  },
  {
    Symbol: "SSM",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Chế tạo Kết cấu Thép VNECO.SSM",
    CategoryName: "Công nghiệp",
    Price: 5.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "SSN",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Xuất nhập khẩu Thủy sản Sài Gòn",
    CategoryName: "Hàng tiêu dùng",
    Price: 1.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "ST8",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Tập đoàn ST8",
    CategoryName: "Dịch vụ",
    Price: 7.09,
    TotalCompany: 1659,
  },
  {
    Symbol: "STB",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại Cổ phần Sài Gòn Thương Tín",
    CategoryName: "Tài chính",
    Price: 32.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "STC",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Sách và Thiết bị trường học Tp. Hồ Chí Minh",
    CategoryName: "Dịch vụ",
    Price: 16.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "STG",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Kho vận Miền Nam",
    CategoryName: "Công nghiệp",
    Price: 40,
    TotalCompany: 1659,
  },
  {
    Symbol: "STH",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP Phát hành sách Thái Nguyên",
    CategoryName: "Dịch vụ",
    Price: 6.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "STJ",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Vận tải Sonadezi",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "STK",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Sợi Thế Kỷ",
    CategoryName: "Hàng tiêu dùng",
    Price: 24.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "STP",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Công nghiệp Thương mại Sông Đà",
    CategoryName: "Nguyên vật liệu",
    Price: 9.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "STS",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Dịch vụ Vận tải Sài Gòn",
    CategoryName: "Công nghiệp",
    Price: 37,
    TotalCompany: 1659,
  },
  {
    Symbol: "STT",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Vận chuyển Sài Gòn Tourist",
    CategoryName: "Công nghiệp",
    Price: 1.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "STW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Cấp nước Sóc Trăng",
    CategoryName: "Dịch vụ",
    Price: 33.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "SVC",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Dịch vụ tổng hợp Sài Gòn",
    CategoryName: "Dịch vụ",
    Price: 24.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "SVD",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Đầu tư và Thương mại Vũ Đăng",
    CategoryName: "Hàng tiêu dùng",
    Price: 3.12,
    TotalCompany: 1659,
  },
  {
    Symbol: "SVG",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "CTCP Hơi Kỹ nghệ Que hàn",
    CategoryName: "Công nghiệp",
    Price: 4.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "SVH",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Thủy điện Sông Vàng",
    CategoryName: "Năng lượng",
    Price: 6,
    TotalCompany: 1659,
  },
  {
    Symbol: "SVI",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Bao bì Biên Hòa",
    CategoryName: "Nguyên vật liệu",
    Price: 58.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "SVJ",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "CTCP Santomas Việt Nam",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "SVN",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Tập đoàn Vexilla Việt Nam",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3,
    TotalCompany: 1659,
  },
  {
    Symbol: "SVT",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Công nghệ Sài Gòn Viễn Đông",
    CategoryName: "Dịch vụ",
    Price: 11.25,
    TotalCompany: 1659,
  },
  {
    Symbol: "SWC",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Tổng Công ty Cổ phần Đường Sông Miền Nam",
    CategoryName: "Công nghiệp",
    Price: 28.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "SZB",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Sonadezi Long Bình",
    CategoryName: "Công nghiệp",
    Price: 41,
    TotalCompany: 1659,
  },
  {
    Symbol: "SZC",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Sonadezi Châu Đức",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 41.25,
    TotalCompany: 1659,
  },
  {
    Symbol: "SZE",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Môi trường Sonadezi",
    CategoryName: "Dịch vụ",
    Price: 12,
    TotalCompany: 1659,
  },
  {
    Symbol: "SZG",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Sonadezi Giang Điền",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 34,
    TotalCompany: 1659,
  },
  {
    Symbol: "SZL",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Sonadezi Long Thành",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 41.95,
    TotalCompany: 1659,
  },
  {
    Symbol: "TA3",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Xây lắp Thành An 386",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "TA6",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Xây lắp Thành An 665",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 12.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "TA9",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây lắp Thành An 96",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 11.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "TAB",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Freco Việt Nam",
    CategoryName: null,
    Price: 17.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "TAL",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Đầu tư Bất động sản Taseco",
    CategoryName: null,
    Price: 17.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "TAN",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Cà phê Thuận An",
    CategoryName: "Nông nghiệp",
    Price: 39.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "TAR",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Nông nghiệp Công nghệ cao Trung An",
    CategoryName: "Nông nghiệp",
    Price: 4.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "TAW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Cấp nước Trung An",
    CategoryName: "Dịch vụ",
    Price: 14.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "TB8",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "CTCP Sản xuất và Kinh doanh Vật tư Thiết bị - VVMI",
    CategoryName: "Nguyên vật liệu",
    Price: 9.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "TBC",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Thủy điện Thác Bà",
    CategoryName: "Năng lượng",
    Price: 40,
    TotalCompany: 1659,
  },
  {
    Symbol: "TBD",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Tổng Công ty Thiết bị Điện Đông Anh - Công ty Cổ phần",
    CategoryName: "Năng lượng",
    Price: 79.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "TBH",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Tổng Bách Hóa",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 13.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "TBR",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Địa ốc Tân Bình",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7,
    TotalCompany: 1659,
  },
  {
    Symbol: "TBW",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Nước sạch Thái Binh",
    CategoryName: null,
    Price: 13.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "TBX",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xi măng Thái Bình",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 22.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "TC6",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Than Cọc Sáu - Vinacomin",
    CategoryName: "Năng lượng",
    Price: 10.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "TCB",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Ngân hàng TMCP Kỹ Thương Việt Nam (Techcombank)",
    CategoryName: "Tài chính",
    Price: 22.35,
    TotalCompany: 1659,
  },
  {
    Symbol: "TCD",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tập đoàn Xây dựng Tracodi",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "TCH",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư Dịch vụ Tài chính Hoàng Huy",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 15.05,
    TotalCompany: 1659,
  },
  {
    Symbol: "TCI",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Chứng khoán Thành Công",
    CategoryName: "Tài chính",
    Price: 7.82,
    TotalCompany: 1659,
  },
  {
    Symbol: "TCJ",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Tô Châu",
    CategoryName: "Nông nghiệp",
    Price: 4,
    TotalCompany: 1659,
  },
  {
    Symbol: "TCK",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Tổng công ty cơ khí xây dựng - CTCP",
    CategoryName: "Công nghiệp",
    Price: 3.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "TCL",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Đại lý Giao nhận Vận tải Xếp dỡ Tân Cảng",
    CategoryName: "Công nghiệp",
    Price: 34.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "TCM",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Dệt may - Đầu tư - Thương mại Thành Công",
    CategoryName: "Hàng tiêu dùng",
    Price: 46.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "TCO",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần TCO Holdings",
    CategoryName: "Công nghiệp",
    Price: 19.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "TCR",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Công nghiệp Gốm sứ Taicera",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.48,
    TotalCompany: 1659,
  },
  {
    Symbol: "TCT",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Cáp treo Núi Bà Tây Ninh",
    CategoryName: "Dịch vụ",
    Price: 16.95,
    TotalCompany: 1659,
  },
  {
    Symbol: "TCW",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Kho vận Tân Cảng",
    CategoryName: "Công nghiệp",
    Price: 30,
    TotalCompany: 1659,
  },
  {
    Symbol: "TDB",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Thủy điện Định Bình",
    CategoryName: "Năng lượng",
    Price: 40.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "TDC",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Kinh doanh và Phát triển Bình Dương",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.15,
    TotalCompany: 1659,
  },
  {
    Symbol: "TDF",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Trung Đô",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 11.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "TDG",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Đầu tư TDG Global",
    CategoryName: "Năng lượng",
    Price: 3.47,
    TotalCompany: 1659,
  },
  {
    Symbol: "TDH",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Phát triển Nhà Thủ Đức",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.36,
    TotalCompany: 1659,
  },
  {
    Symbol: "TDI",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Tập đoàn TDI ",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "TDL",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "CTCP Da Tây Đô ",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "TDM",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Nước Thủ Dầu Một",
    CategoryName: "Dịch vụ",
    Price: 50.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "TDN",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Than Đèo Nai - Vinacomin",
    CategoryName: "Năng lượng",
    Price: 10.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "TDP",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Thuận Đức",
    CategoryName: "Nguyên vật liệu",
    Price: 33.35,
    TotalCompany: 1659,
  },
  {
    Symbol: "TDS",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Thép Thủ Đức - Vnsteel",
    CategoryName: "Nguyên vật liệu",
    Price: 10.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "TDT",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Đầu tư và Phát triển TDT",
    CategoryName: "Hàng tiêu dùng",
    Price: 6.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "TDW",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Cấp nước Thủ Đức",
    CategoryName: "Dịch vụ",
    Price: 50,
    TotalCompany: 1659,
  },
  {
    Symbol: "TED",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Tổng công ty Tư vấn thiết kế Giao thông vận tải - CTCP",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 50,
    TotalCompany: 1659,
  },
  {
    Symbol: "TEG",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Năng lượng và Bất động sản Trường Thành",
    CategoryName: "Năng lượng",
    Price: 7.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "TEL",
    TradeCenterId: 9,
    CategoryId: 348,
    CompanyName: "Công ty Cổ phần Phát triển Công trình Viễn thông",
    CategoryName: "Viễn thông",
    Price: 13.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "TET",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Vải sợi May mặc Miền Bắc",
    CategoryName: "Nguyên vật liệu",
    Price: 31.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "TFC",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Trang",
    CategoryName: "Hàng tiêu dùng",
    Price: 40.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "TGP",
    TradeCenterId: 9,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Trường Phú",
    CategoryName: "Công nghệ",
    Price: 4.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "TH1",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Xuất nhập khẩu Tổng hợp I Việt Nam",
    CategoryName: "Dịch vụ",
    Price: 3.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "THA",
    TradeCenterId: 8,
    CategoryId: 343,
    CompanyName: "CÔNG TY CỔ PHẦN TẬP ĐOÀN TRƯỜNG HẢI",
    CategoryName: "Công nghiệp",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "THB",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Bia Hà Nội - Thanh Hóa",
    CategoryName: "Hàng tiêu dùng",
    Price: 11.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "THD",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Thaiholdings",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 35.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "THG",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Xây dựng Tiền Giang",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 42.95,
    TotalCompany: 1659,
  },
  {
    Symbol: "THM",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Tứ Hải Hà Nam",
    CategoryName: null,
    Price: 7.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "THN",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Cấp nước Thanh Hóa",
    CategoryName: "Dịch vụ",
    Price: 5.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "THP",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "CTCP Thủy sản và Thương mại Thuận Phước",
    CategoryName: "Nông nghiệp",
    Price: 8.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "THS",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Thanh Hoa - Sông Đà",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 8.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "THT",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Than Hà Tu - Vinacomin",
    CategoryName: "Năng lượng",
    Price: 12.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "THU",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Môi trường và Công trình Đô thị Thanh Hóa",
    CategoryName: "Dịch vụ",
    Price: 10.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "THW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Cấp nước Tân Hòa",
    CategoryName: "Dịch vụ",
    Price: 15,
    TotalCompany: 1659,
  },
  {
    Symbol: "TID",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Tổng công ty Tín Nghĩa",
    CategoryName: "Nông nghiệp",
    Price: 23.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "TIE",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần TIE",
    CategoryName: "Dịch vụ",
    Price: 4.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "TIG",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Tập đoàn Đầu tư Thăng Long",
    CategoryName: "Tài chính",
    Price: 12.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "TIP",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Phát triển Khu Công nghiệp Tín Nghĩa",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 21.75,
    TotalCompany: 1659,
  },
  {
    Symbol: "TIS",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Gang thép Thái Nguyên",
    CategoryName: "Nguyên vật liệu",
    Price: 6.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "TIX",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "CTCP Sản xuất Kinh doanh Xuất nhập khẩu Dịch vụ và Đầu tư Tân Bình",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 36.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "TJC",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Dịch vụ Vận tải và Thương mại",
    CategoryName: "Công nghiệp",
    Price: 19.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "TKA",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Bao bì Tân Khánh An",
    CategoryName: "Nguyên vật liệu",
    Price: 3.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "TKC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công  ty Cổ phần Xây dựng và Kinh doanh Địa ốc Tân Kỷ",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "TKG",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "CTCP Sản xuất và Thương mại Tùng Khánh",
    CategoryName: "Hàng tiêu dùng",
    Price: 2.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "TKR",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Cao su Tân Biên - Kampong Thom",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "TKU",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Công nghiệp Tung Kuang",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 15,
    TotalCompany: 1659,
  },
  {
    Symbol: "TL4",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Tổng công ty Xây dựng Thủy lợi 4 - CTCP",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "TLD",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư Xây dựng và Phát triển Đô thị Thăng Long",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5.87,
    TotalCompany: 1659,
  },
  {
    Symbol: "TLG",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Tập đoàn Thiên Long",
    CategoryName: "Hàng tiêu dùng",
    Price: 60,
    TotalCompany: 1659,
  },
  {
    Symbol: "TLH",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Tập đoàn Thép Tiến Lên",
    CategoryName: "Nguyên vật liệu",
    Price: 4.54,
    TotalCompany: 1659,
  },
  {
    Symbol: "TLI",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần May Quốc tế Thắng Lợi",
    CategoryName: "Hàng tiêu dùng",
    Price: 4.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "TLP",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Tổng Công ty Thương mại Xuất nhập khẩu Thanh Lễ - CTCP",
    CategoryName: "Năng lượng",
    Price: 6.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "TLT",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Viglacera Thăng Long",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 14,
    TotalCompany: 1659,
  },
  {
    Symbol: "TMB",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Kinh doanh than Miền Bắc - Vinacomin",
    CategoryName: "Năng lượng",
    Price: 65,
    TotalCompany: 1659,
  },
  {
    Symbol: "TMC",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Thương mại - Xuất nhập khẩu Thủ Đức",
    CategoryName: "Dịch vụ",
    Price: 8,
    TotalCompany: 1659,
  },
  {
    Symbol: "TMG",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Kim loại màu Thái Nguyên - Vimico",
    CategoryName: "Nguyên vật liệu",
    Price: 42.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "TMP",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Thủy điện Thác Mơ",
    CategoryName: "Năng lượng",
    Price: 67,
    TotalCompany: 1659,
  },
  {
    Symbol: "TMS",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Transimex",
    CategoryName: "Công nghiệp",
    Price: 41.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "TMT",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Ô tô TMT",
    CategoryName: "Công nghiệp",
    Price: 7,
    TotalCompany: 1659,
  },
  {
    Symbol: "TMW",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Tổng hợp Gỗ Tân Mai",
    CategoryName: "Hàng tiêu dùng",
    Price: 20,
    TotalCompany: 1659,
  },
  {
    Symbol: "TMX",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần VICEM Thương mại Xi măng",
    CategoryName: "Dịch vụ",
    Price: 9.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "TN1",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Rox Key Holdings",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "TNA",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Thương mại Xuất nhập khẩu Thiên Nam",
    CategoryName: "Dịch vụ",
    Price: 3.77,
    TotalCompany: 1659,
  },
  {
    Symbol: "TNB",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Thép Nhà Bè - VNSTEEL",
    CategoryName: "Nguyên vật liệu",
    Price: 10.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "TNC",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Cao su Thống Nhất",
    CategoryName: "Nông nghiệp",
    Price: 34.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "TNG",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Đầu tư và Thương mại TNG",
    CategoryName: "Hàng tiêu dùng",
    Price: 24.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "TNH",
    TradeCenterId: 1,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Bệnh viện Quốc tế Thái Nguyên",
    CategoryName: "Y tế",
    Price: 20.25,
    TotalCompany: 1659,
  },
  {
    Symbol: "TNI",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Tập đoàn Thành Nam",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 2.23,
    TotalCompany: 1659,
  },
  {
    Symbol: "TNP",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Cảng Thị Nại",
    CategoryName: "Công nghiệp",
    Price: 25.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "TNS",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Thép Tấm lá Thống Nhất",
    CategoryName: "Công nghiệp",
    Price: 4.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "TNT",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Tập đoàn TNT",
    CategoryName: "Nguyên vật liệu",
    Price: 4.35,
    TotalCompany: 1659,
  },
  {
    Symbol: "TNW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Nước sạch Thái Nguyên",
    CategoryName: "Dịch vụ",
    Price: 10.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "TOP",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Phân phối Top One",
    CategoryName: "Dịch vụ",
    Price: 1.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "TOS",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Dịch vụ biển Tân Cảng",
    CategoryName: "Công nghiệp",
    Price: 70.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "TOT",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Transimex Logistics",
    CategoryName: "Công nghiệp",
    Price: 17.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "TOW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Cấp nước Trà Nóc - Ô Môn",
    CategoryName: "Dịch vụ",
    Price: 21.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "TPB",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại cổ phần Tiên Phong",
    CategoryName: "Tài chính",
    Price: 15.95,
    TotalCompany: 1659,
  },
  {
    Symbol: "TPC",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Nhựa Tân Đại Hưng",
    CategoryName: "Nguyên vật liệu",
    Price: 6.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "TPH",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần In Sách giáo khoa tại T.P Hà Nội",
    CategoryName: "Dịch vụ",
    Price: 15.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "TPP",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Tân Phú Việt Nam",
    CategoryName: "Nguyên vật liệu",
    Price: 10.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "TPS",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Bến bãi Vận tải Sài Gòn",
    CategoryName: "Công nghiệp",
    Price: 31.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "TQN",
    TradeCenterId: 9,
    CategoryId: 350,
    CompanyName: "Công ty Cổ phần Thông Quảng Ninh",
    CategoryName: "Doanh nghiệp Upcom",
    Price: 27.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "TQW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP Cấp thoát nước Tuyên Quang",
    CategoryName: "Dịch vụ",
    Price: 8.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "TR1",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "CTCP Vận tải 1 Traco",
    CategoryName: "Công nghiệp",
    Price: 17.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "TRA",
    TradeCenterId: 1,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần TRAPHACO",
    CategoryName: "Y tế",
    Price: 76.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "TRC",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Cao su Tây Ninh",
    CategoryName: "Nông nghiệp",
    Price: 45,
    TotalCompany: 1659,
  },
  {
    Symbol: "TRS",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Vận tải và Dịch vụ Hàng hải",
    CategoryName: "Công nghiệp",
    Price: 23.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "TRT",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần RedstarCera",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 14,
    TotalCompany: 1659,
  },
  {
    Symbol: "TS3",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Trường Sơn 532",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5,
    TotalCompany: 1659,
  },
  {
    Symbol: "TSA",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Đầu tư và Xây lắp Trường Sơn",
    CategoryName: null,
    Price: 12.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "TSB",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Ắc quy Tia sáng ",
    CategoryName: "Công nghiệp",
    Price: 44.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "TSC",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Vật tư kỹ thuật Nông nghiệp Cần Thơ",
    CategoryName: "Nông nghiệp",
    Price: 2.47,
    TotalCompany: 1659,
  },
  {
    Symbol: "TSD",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Du lịch Trường Sơn COECCO",
    CategoryName: "Dịch vụ",
    Price: 4.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "TSG",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Thông tin Tín hiệu Đường sắt Sài Gòn",
    CategoryName: "Công nghiệp",
    Price: 18,
    TotalCompany: 1659,
  },
  {
    Symbol: "TSH",
    TradeCenterId: 8,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Tiên Sơn Thanh Hóa",
    CategoryName: "Hàng tiêu dùng",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "TSJ",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Du lịch Dịch vụ Hà Nội",
    CategoryName: "Dịch vụ",
    Price: 27.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "TSS",
    TradeCenterId: 8,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán TRƯỜNG SƠN",
    CategoryName: "Tài chính",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "TST",
    TradeCenterId: 9,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Dịch vụ Kỹ thuật Viễn thông",
    CategoryName: "Công nghệ",
    Price: 3.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "TT6",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Tập đoàn Tiến Thịnh",
    CategoryName: null,
    Price: 8.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "TTA",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Đầu tư Xây dựng và Phát triển Trường Thành",
    CategoryName: "Năng lượng",
    Price: 10.15,
    TotalCompany: 1659,
  },
  {
    Symbol: "TTC",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Gạch men Thanh Thanh ",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "TTD",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Bệnh viện Tim Tâm Đức",
    CategoryName: "Y tế",
    Price: 62.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "TTE",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Đầu tư Năng lượng Trường Thịnh",
    CategoryName: "Năng lượng",
    Price: 32.95,
    TotalCompany: 1659,
  },
  {
    Symbol: "TTF",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Tập đoàn Kỹ nghệ Gỗ Trường Thành",
    CategoryName: "Hàng tiêu dùng",
    Price: 3.09,
    TotalCompany: 1659,
  },
  {
    Symbol: "TTG",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần May Thanh Trì",
    CategoryName: "Hàng tiêu dùng",
    Price: 24.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "TTH",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Thương mại và Dịch vụ Tiến Thành",
    CategoryName: "Hàng tiêu dùng",
    Price: 2.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "TTL",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Tổng Công ty Thăng Long - CTCP",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7,
    TotalCompany: 1659,
  },
  {
    Symbol: "TTN",
    TradeCenterId: 9,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Công nghệ và Truyền thông Việt Nam",
    CategoryName: "Công nghệ",
    Price: 17.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "TTP",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Bao bì Nhựa Tân Tiến",
    CategoryName: "Nguyên vật liệu",
    Price: 69,
    TotalCompany: 1659,
  },
  {
    Symbol: "TTS",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Cán thép Thái Trung",
    CategoryName: "Công nghiệp",
    Price: 4.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "TTT",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Du lịch – Thương mại Tây Ninh",
    CategoryName: "Dịch vụ",
    Price: 30.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "TUG",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Lai dắt và Vận tải Cảng Hải Phòng",
    CategoryName: "Công nghiệp",
    Price: 18.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "TV1",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Tư vấn Xây dựng Điện 1",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 21.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "TV2",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tư vấn Xây dựng Điện 2",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 30.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "TV3",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tư vấn Xây dựng Điện 3",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 11.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "TV4",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tư vấn Xây dựng Điện 4",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 14.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "TV6",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Thương mại Đầu tư Xây lắp điện Thịnh Vượng",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "TVA",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Sứ Viglacera Thanh Trì",
    CategoryName: "Hàng tiêu dùng",
    Price: 11.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "TVB",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Chứng khoán Trí Việt",
    CategoryName: "Tài chính",
    Price: 8.65,
    TotalCompany: 1659,
  },
  {
    Symbol: "TVC",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Tập đoàn Quản lý Tài sản Trí Việt",
    CategoryName: "Tài chính",
    Price: 10.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "TVD",
    TradeCenterId: 2,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Than Vàng Danh - Vinacomin",
    CategoryName: "Năng lượng",
    Price: 10.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "TVG",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Tư vấn Đầu tư và Xây dựng Giao thông Vận tải",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 9.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "TVH",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Tư vấn và Xây dựng công trình Hàng Hải",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 16.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "TVM",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP Tư vấn Đầu tư Mỏ và Công nghiệp - Vinacomin",
    CategoryName: "Dịch vụ",
    Price: 11,
    TotalCompany: 1659,
  },
  {
    Symbol: "TVN",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Tổng Công ty Thép Việt Nam - CTCP",
    CategoryName: "Nguyên vật liệu",
    Price: 7.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "TVP",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược phẩm TV.Pharm",
    CategoryName: "Y tế",
    Price: 16,
    TotalCompany: 1659,
  },
  {
    Symbol: "TVS",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán Thiên Việt",
    CategoryName: "Tài chính",
    Price: 20.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "TVT",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Tổng Công ty Việt Thắng - CTCP",
    CategoryName: "Hàng tiêu dùng",
    Price: 15.75,
    TotalCompany: 1659,
  },
  {
    Symbol: "TW3",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "CTCP Dược Trung ương 3",
    CategoryName: "Y tế",
    Price: 14,
    TotalCompany: 1659,
  },
  {
    Symbol: "TXM",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần VICEM Thạch cao Xi măng",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "TYA",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Dây và Cáp điện Taya Việt Nam ",
    CategoryName: "Công nghiệp",
    Price: 13.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "UCT",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Đô thị Cần Thơ",
    CategoryName: "Dịch vụ",
    Price: 7.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "UDC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Xây dựng và Phát triển Đô thị tỉnh Bà Rịa-Vũng Tàu",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "UDJ",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Phát triển Đô thị",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 8.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "UDL",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP Đô thị và Môi trường Đắk Lắk",
    CategoryName: "Dịch vụ",
    Price: 12,
    TotalCompany: 1659,
  },
  {
    Symbol: "UEM",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "CTCP Cơ điện Uông Bí- Vinacomin",
    CategoryName: "Công nghiệp",
    Price: 9.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "UIC",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư Phát triển Nhà và Đô thị Idico",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 40.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "UMC",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Công trình đô thị Nam Định",
    CategoryName: "Dịch vụ",
    Price: 9.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "UNI",
    TradeCenterId: 2,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Đầu tư và phát triển Sao Mai Việt",
    CategoryName: "Công nghệ",
    Price: 6.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "UPC",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Phát triển Công viên cây xanh và Đô thị Vũng Tàu ",
    CategoryName: "Dịch vụ",
    Price: 34.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "UPH",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "CTCP Dược phẩm TW25",
    CategoryName: "Y tế",
    Price: 10.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "USC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Khảo sát và Xây dựng - USCO",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 9.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "USD",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Công trình Đô thị Sóc Trăng",
    CategoryName: "Dịch vụ",
    Price: 22.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "UXC",
    TradeCenterId: 8,
    CategoryId: 338,
    CompanyName: "CTCP Chế biến Thủy sản Út Xi",
    CategoryName: "Nông nghiệp",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "V11",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng số 11",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "V12",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng số 12",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 12.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "V15",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng Số 15",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "V21",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần VINACONEX 21",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "VAB",
    TradeCenterId: 9,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại cổ phần Việt Á",
    CategoryName: "Tài chính",
    Price: 8.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "VAF",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Phân lân nung chảy Văn Điển",
    CategoryName: "Nông nghiệp",
    Price: 15,
    TotalCompany: 1659,
  },
  {
    Symbol: "VAV",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần VIWACO",
    CategoryName: "Dịch vụ",
    Price: 46,
    TotalCompany: 1659,
  },
  {
    Symbol: "VBB",
    TradeCenterId: 9,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại cổ phần Việt Nam Thương Tín",
    CategoryName: "Tài chính",
    Price: 10.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "VBC",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Nhựa Bao bì Vinh",
    CategoryName: "Nguyên vật liệu",
    Price: 20,
    TotalCompany: 1659,
  },
  {
    Symbol: "VBG",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Địa chất Việt Bắc - TKV",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "VBH",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Điện tử Bình Hòa",
    CategoryName: "Hàng tiêu dùng",
    Price: 10.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "VBL",
    TradeCenterId: 8,
    CategoryId: 339,
    CompanyName: "Công ty TNHH Nhà máy Bia Việt Nam",
    CategoryName: "Hàng tiêu dùng",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "VC1",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng số 1",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 8.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "VC2",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Xây dựng Vina2",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 8.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "VC3",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tập đoàn Nam Mê Kông",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 27.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "VC5",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng số 5",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "VC6",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng và Đầu tư Visicons",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 20.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "VC7",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Tập đoàn BGI",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 8.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "VC9",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng số 9 - VC9",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "VCA",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Thép VICASA - VNSTEEL",
    CategoryName: "Nguyên vật liệu",
    Price: 8.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "VCB",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại cổ phần Ngoại thương Việt Nam",
    CategoryName: "Tài chính",
    Price: 90.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "VCC",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Vinaconex 25",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 9.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "VCE",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "CTCP Xây lắp Môi trường - TKV",
    CategoryName: "Năng lượng",
    Price: 8,
    TotalCompany: 1659,
  },
  {
    Symbol: "VCF",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần VinaCafé Biên Hòa",
    CategoryName: "Hàng tiêu dùng",
    Price: 212.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "VCG",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Tổng Công ty Cổ phần Xuất nhập khẩu và Xây dựng Việt Nam",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 17.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "VCI",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán VIETCAP",
    CategoryName: "Tài chính",
    Price: 32.95,
    TotalCompany: 1659,
  },
  {
    Symbol: "VCM",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần BV Life",
    CategoryName: "Dịch vụ",
    Price: 11.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "VCP",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Xây dựng và Năng lượng VCP",
    CategoryName: "Năng lượng",
    Price: 24.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "VCR",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Phát triển Du lịch Vinaconex",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 23.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "VCS",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần VICOSTONE",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 67.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "VCT",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Tư vấn Xây dựng Vinaconex",
    CategoryName: "Dịch vụ",
    Price: 8.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "VCW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Đầu tư Nước sạch Sông Đà",
    CategoryName: "Dịch vụ",
    Price: 29,
    TotalCompany: 1659,
  },
  {
    Symbol: "VCX",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xi măng Yên Bình",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 8.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "VDB",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Vận tải và Chế biến Than Đông Bắc",
    CategoryName: "Năng lượng",
    Price: 0.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "VDG",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Vạn Đạt Group",
    CategoryName: null,
    Price: 15.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "VDL",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Thực phẩm Lâm Đồng",
    CategoryName: "Hàng tiêu dùng",
    Price: 12,
    TotalCompany: 1659,
  },
  {
    Symbol: "VDN",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Vinatex Đà Nẵng",
    CategoryName: "Hàng tiêu dùng",
    Price: 18.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "VDP",
    TradeCenterId: 1,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược phẩm Trung ương VIDIPHA",
    CategoryName: "Y tế",
    Price: 34.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "VDS",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán Rồng Việt",
    CategoryName: "Tài chính",
    Price: 18,
    TotalCompany: 1659,
  },
  {
    Symbol: "VDT",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty cổ phần Lưới thép Bình Tây",
    CategoryName: "Nguyên vật liệu",
    Price: 19.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "VE1",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng điện VNECO 1",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "VE2",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Xây dựng điện VNECO 2",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10,
    TotalCompany: 1659,
  },
  {
    Symbol: "VE3",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Xây dựng điện VNECO 3",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 8.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "VE4",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây dựng điện VNECO4",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 259.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "VE8",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Xây dựng điện VNECO 8",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "VE9",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Xây dựng VNECO 9",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "VEA",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Tổng Công ty Máy động lực và máy nông nghiệp Việt Nam - CTCP",
    CategoryName: "Nông nghiệp",
    Price: 39.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "VEC",
    TradeCenterId: 9,
    CategoryId: 347,
    CompanyName: "Tổng Công ty cổ phần Điện tử và Tin học Việt Nam",
    CategoryName: "Công nghệ",
    Price: 7.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "VED",
    TradeCenterId: 8,
    CategoryId: 347,
    CompanyName: "Công ty cổ phần Phát triển Thể thao Điện tử Việt Nam",
    CategoryName: "Công nghệ",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "VEF",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Trung tâm Hội chợ Triển lãm Việt Nam",
    CategoryName: "Dịch vụ",
    Price: 180.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "VES",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư và Xây dựng điện Mê Ca Vneco",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 1.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "VET",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Thuốc thú y Trung ương Navetco",
    CategoryName: "Nông nghiệp",
    Price: 17.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "VFC",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần VINAFCO ",
    CategoryName: "Công nghiệp",
    Price: 104.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "VFG",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Khử trùng Việt Nam",
    CategoryName: "Nông nghiệp",
    Price: 88,
    TotalCompany: 1659,
  },
  {
    Symbol: "VFM",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Quản lý quỹ Đầu tư Việt Nam",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "VFR",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Vận tải và Thuê tàu",
    CategoryName: "Công nghiệp",
    Price: 12.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "VFS",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán Nhất Việt",
    CategoryName: "Tài chính",
    Price: 14.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "VFW",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Quản lý quỹ Lộc Việt",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "VGC",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Tổng Công ty Viglacera - CTCP",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 42.15,
    TotalCompany: 1659,
  },
  {
    Symbol: "VGG",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Tổng Công ty cổ phần May Việt Tiến",
    CategoryName: "Hàng tiêu dùng",
    Price: 45,
    TotalCompany: 1659,
  },
  {
    Symbol: "VGI",
    TradeCenterId: 9,
    CategoryId: 348,
    CompanyName: "Tổng Công ty cổ phần Đầu tư Quốc tế Viettel",
    CategoryName: "Viễn thông",
    Price: 85.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "VGL",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "CTCP Mạ kẽm công nghiệp Vingal - Vnsteel",
    CategoryName: "Nguyên vật liệu",
    Price: 20.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "VGP",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Cảng Rau quả",
    CategoryName: "Công nghiệp",
    Price: 30.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "VGR",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Cảng Xanh VIP",
    CategoryName: "Công nghiệp",
    Price: 60.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "VGS",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Ống thép Việt Đức VG PIPE",
    CategoryName: "Nguyên vật liệu",
    Price: 31.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "VGT",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Tập đoàn Dệt may Việt Nam",
    CategoryName: "Hàng tiêu dùng",
    Price: 13.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "VGV",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Tổng Công ty Tư vấn Xây dựng Việt Nam - CTCP",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 29.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "VHC",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Vĩnh Hoàn",
    CategoryName: "Nông nghiệp",
    Price: 73.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "VHD",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư Phát triển Nhà và Đô thị VINAHUD",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "VHE",
    TradeCenterId: 2,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Dược liệu và Thực phẩm Việt Nam",
    CategoryName: "Y tế",
    Price: 3,
    TotalCompany: 1659,
  },
  {
    Symbol: "VHF",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Xây dựng và Chế biến lương thực Vĩnh Hà",
    CategoryName: "Hàng tiêu dùng",
    Price: 18.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "VHG",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Đầu tư Phát triển Việt Trung Nam",
    CategoryName: "Nông nghiệp",
    Price: 1.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "VHH",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư Kinh doanh nhà Thành Đạt",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "VHL",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Viglacera Hạ Long",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "VHM",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Vinhomes",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 41.65,
    TotalCompany: 1659,
  },
  {
    Symbol: "VHW",
    TradeCenterId: 8,
    CategoryId: 339,
    CompanyName: "CTCP Nước Khoáng Vĩnh Hảo",
    CategoryName: "Hàng tiêu dùng",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "VIB",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại cổ phần Quốc tế Việt Nam",
    CategoryName: "Tài chính",
    Price: 18.15,
    TotalCompany: 1659,
  },
  {
    Symbol: "VIC",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Tập đoàn Vingroup - Công ty Cổ phần",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 40.45,
    TotalCompany: 1659,
  },
  {
    Symbol: "VID",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Đầu tư Phát triển Thương mại Viễn Đông",
    CategoryName: "Nguyên vật liệu",
    Price: 5.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "VIE",
    TradeCenterId: 9,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Công nghệ Viễn thông VITECO ",
    CategoryName: "Công nghệ",
    Price: 4.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "VIF",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Tổng Công ty Lâm nghiệp Việt Nam - CTCP",
    CategoryName: "Nguyên vật liệu",
    Price: 17.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "VIG",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán Đầu tư Tài chính Việt Nam",
    CategoryName: "Tài chính",
    Price: 5.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "VIH",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Viglacera Hà Nội",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "VIM",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Khoáng sản Viglacera",
    CategoryName: "Nguyên vật liệu",
    Price: 20.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "VIN",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "CTCP Giao nhận Kho vận Ngoại thương Việt Nam ",
    CategoryName: "Hàng tiêu dùng",
    Price: 20.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "VIP",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Vận tải Xăng dầu VIPCO",
    CategoryName: "Năng lượng",
    Price: 14.15,
    TotalCompany: 1659,
  },
  {
    Symbol: "VIR",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Du lịch Quốc tế Vũng Tàu",
    CategoryName: "Dịch vụ",
    Price: 5.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "VIT",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Viglacera Tiên Sơn",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 20,
    TotalCompany: 1659,
  },
  {
    Symbol: "VIW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Tổng Công ty Đầu tư Nước và Môi trường Việt Nam - CTCP",
    CategoryName: "Dịch vụ",
    Price: 10,
    TotalCompany: 1659,
  },
  {
    Symbol: "VIX",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Chứng khoán VIX",
    CategoryName: "Tài chính",
    Price: 9.88,
    TotalCompany: 1659,
  },
  {
    Symbol: "VJC",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Hàng không VietJet",
    CategoryName: "Công nghiệp",
    Price: 101,
    TotalCompany: 1659,
  },
  {
    Symbol: "VKC",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần VKC Holdings",
    CategoryName: "Công nghiệp",
    Price: 1,
    TotalCompany: 1659,
  },
  {
    Symbol: "VKP",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Nhựa Tân Hóa",
    CategoryName: "Nguyên vật liệu",
    Price: 0.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "VLA",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Đầu tư và Phát triển Công nghệ Văn  Lang",
    CategoryName: "Dịch vụ",
    Price: 10.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "VLB",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "CTCP Xây dựng và Sản xuất Vật liệu Xây dựng Biên Hòa",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 39.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "VLC",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Tổng Công ty Chăn nuôi Việt Nam - CTCP",
    CategoryName: "Nông nghiệp",
    Price: 16.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "VLD",
    TradeCenterId: 8,
    CategoryId: 0,
    CompanyName: "Công ty Cổ phần Bất Động Sản Viettronics",
    CategoryName: "",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "VLF",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Lương thực Thực phẩm Vĩnh Long",
    CategoryName: "Nông nghiệp",
    Price: 1.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "VLG",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "CTCP Vinalines Logistics - Việt Nam",
    CategoryName: "Công nghiệp",
    Price: 9.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "VLP",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Công trình công cộng Vĩnh Long",
    CategoryName: "Dịch vụ",
    Price: 1.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "VLW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Cấp nước Vĩnh Long",
    CategoryName: "Dịch vụ",
    Price: 29,
    TotalCompany: 1659,
  },
  {
    Symbol: "VMA",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Công nghiệp Ô tô – Vinacomin",
    CategoryName: "Công nghiệp",
    Price: 3.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "VMC",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Vimeco",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "VMD",
    TradeCenterId: 1,
    CategoryId: 342,
    CompanyName: "Công ty cổ phần Y Dược phẩm Vimedimex",
    CategoryName: "Y tế",
    Price: 18.75,
    TotalCompany: 1659,
  },
  {
    Symbol: "VMG",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Thương mại và Dịch vụ Dầu khí Vũng Tàu",
    CategoryName: "Năng lượng",
    Price: 3.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "VMK",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty cổ phần Vimarko",
    CategoryName: null,
    Price: 16.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "VMS",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "CTCP Phát triển Hàng hải",
    CategoryName: "Công nghiệp",
    Price: 20,
    TotalCompany: 1659,
  },
  {
    Symbol: "VMT",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "CTCP Giao nhận Vận tải Miền Trung",
    CategoryName: "Công nghiệp",
    Price: 19,
    TotalCompany: 1659,
  },
  {
    Symbol: "VNA",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Vận tải Biển Vinaship",
    CategoryName: "Công nghiệp",
    Price: 22.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "VNB",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "CTCP Sách Việt Nam",
    CategoryName: "Hàng tiêu dùng",
    Price: 10.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "VNC",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Tập đoàn Vinacontrol",
    CategoryName: "Dịch vụ",
    Price: 39.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "VND",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Chứng khoán VNDIRECT",
    CategoryName: "Tài chính",
    Price: 13.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "VNE",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Tổng công ty Cổ phần Xây dựng điện Việt Nam",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.68,
    TotalCompany: 1659,
  },
  {
    Symbol: "VNF",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Vinafreight",
    CategoryName: "Công nghiệp",
    Price: 14.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "VNG",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Du lịch Thành Thành Công",
    CategoryName: "Dịch vụ",
    Price: 7.66,
    TotalCompany: 1659,
  },
  {
    Symbol: "VNH",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Đầu tư Việt Việt Nhật",
    CategoryName: "Nông nghiệp",
    Price: 1.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "VNL",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Logistics Vinalink",
    CategoryName: "Công nghiệp",
    Price: 16.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "VNM",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Sữa Việt Nam",
    CategoryName: "Hàng tiêu dùng",
    Price: 63.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "VNP",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Nhựa Việt Nam",
    CategoryName: "Hàng tiêu dùng",
    Price: 10.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "VNR",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Tổng Công ty Cổ phần Tái bảo hiểm quốc gia Việt Nam",
    CategoryName: "Tài chính",
    Price: 22.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "VNS",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Ánh Dương Việt Nam",
    CategoryName: "Công nghiệp",
    Price: 10.15,
    TotalCompany: 1659,
  },
  {
    Symbol: "VNT",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Giao nhận Vận tải Ngoại thương",
    CategoryName: "Công nghiệp",
    Price: 28.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "VNX",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Quảng cáo và Hội chợ Thương mại",
    CategoryName: "Dịch vụ",
    Price: 16.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "VNY",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Thuốc Thú y Trung ương I ",
    CategoryName: "Nông nghiệp",
    Price: 6,
    TotalCompany: 1659,
  },
  {
    Symbol: "VNZ",
    TradeCenterId: 9,
    CategoryId: 347,
    CompanyName: "Công ty cổ phần VNG",
    CategoryName: "Công nghệ",
    Price: 361.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "VOC",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Tổng Công ty Công nghiệp Dầu thực vật Việt Nam - CTCP",
    CategoryName: "Hàng tiêu dùng",
    Price: 17.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "VOS",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Vận tải biển Việt Nam",
    CategoryName: "Công nghiệp",
    Price: 15.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "VPA",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "CTCP Vận tải Hóa dầu VP",
    CategoryName: "Công nghiệp",
    Price: 3.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "VPB",
    TradeCenterId: 1,
    CategoryId: 341,
    CompanyName: "Ngân hàng Thương mại Cổ phần Việt Nam Thịnh Vượng",
    CategoryName: "Tài chính",
    Price: 18.65,
    TotalCompany: 1659,
  },
  {
    Symbol: "VPC",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Đầu tư và Phát triển Năng lượng Việt Nam",
    CategoryName: "Dịch vụ",
    Price: 1.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "VPD",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty cổ phần Phát triển Điện lực Việt Nam",
    CategoryName: "Năng lượng",
    Price: 26.95,
    TotalCompany: 1659,
  },
  {
    Symbol: "VPG",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Đầu tư Thương mại Xuất nhập khẩu Việt Phát",
    CategoryName: "Nguyên vật liệu",
    Price: 12.15,
    TotalCompany: 1659,
  },
  {
    Symbol: "VPH",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Vạn Phát Hưng",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 7,
    TotalCompany: 1659,
  },
  {
    Symbol: "VPI",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Đầu tư Văn Phú - Invest",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 57.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "VPR",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần VINAPRINT",
    CategoryName: "Dịch vụ",
    Price: 18.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "VPS",
    TradeCenterId: 1,
    CategoryId: 338,
    CompanyName: "CTCP Thuốc sát trùng Việt Nam",
    CategoryName: "Nông nghiệp",
    Price: 8.73,
    TotalCompany: 1659,
  },
  {
    Symbol: "VPW",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Cấp thoát nước số 1 Vĩnh Phúc",
    CategoryName: "Dịch vụ",
    Price: 9.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "VQC",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Giám định Vinacomin",
    CategoryName: "Dịch vụ",
    Price: 13,
    TotalCompany: 1659,
  },
  {
    Symbol: "VRC",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Bất động sản và Đầu tư VRC",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 10.5,
    TotalCompany: 1659,
  },
  {
    Symbol: "VRE",
    TradeCenterId: 1,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Vincom Retail",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 18.15,
    TotalCompany: 1659,
  },
  {
    Symbol: "VRG",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "CTCP Phát triển đô thị và Khu công nghiệp Cao su Việt Nam",
    CategoryName: "Công nghiệp",
    Price: 23.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "VSA",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Đại lý Hàng hải Việt Nam  ",
    CategoryName: "Công nghiệp",
    Price: 23,
    TotalCompany: 1659,
  },
  {
    Symbol: "VSC",
    TradeCenterId: 1,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Tập đoàn Container Việt Nam",
    CategoryName: "Công nghiệp",
    Price: 17.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "VSE",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Dịch vụ Đường cao tốc Việt Nam",
    CategoryName: "Công nghiệp",
    Price: 6.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "VSF",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Tổng Công ty Lương thực Miền Nam - Công ty cổ phần",
    CategoryName: "Nông nghiệp",
    Price: 34,
    TotalCompany: 1659,
  },
  {
    Symbol: "VSG",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Container Phía Nam",
    CategoryName: "Công nghiệp",
    Price: 2.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "VSH",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Thủy điện Vĩnh Sơn - Sông Hinh",
    CategoryName: "Năng lượng",
    Price: 50.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "VSI",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Đầu tư và Xây dựng Cấp thoát nước",
    CategoryName: "Dịch vụ",
    Price: 18,
    TotalCompany: 1659,
  },
  {
    Symbol: "VSM",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Container Miền Trung",
    CategoryName: "Công nghiệp",
    Price: 18.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "VSN",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Việt Nam Kỹ nghệ Súc sản",
    CategoryName: "Hàng tiêu dùng",
    Price: 18.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "VST",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Vận tải và Thuê tàu biển Việt Nam",
    CategoryName: "Công nghiệp",
    Price: 3,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTA",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Vitaly",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 3.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTB",
    TradeCenterId: 1,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Viettronics Tân Bình",
    CategoryName: "Hàng tiêu dùng",
    Price: 10.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTC",
    TradeCenterId: 2,
    CategoryId: 347,
    CompanyName: "Công ty Cổ phần Viễn thông VTC",
    CategoryName: "Công nghệ",
    Price: 8.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTD",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Vietourist Holdings",
    CategoryName: "Dịch vụ",
    Price: 6.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTE",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Viễn thông Điện tử Vinacap",
    CategoryName: "Công nghiệp",
    Price: 5.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTG",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Du lịch tỉnh Bà Rịa - Vũng Tàu",
    CategoryName: "Dịch vụ",
    Price: 8.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTH",
    TradeCenterId: 2,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Tập đoàn Việt Thái",
    CategoryName: "Dịch vụ",
    Price: 7.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTI",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Sản xuất – Xuất nhập khẩu Dệt May",
    CategoryName: "Hàng tiêu dùng",
    Price: 4,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTJ",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Thương mại và Đầu tư Vinataba",
    CategoryName: "Hàng tiêu dùng",
    Price: 5,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTK",
    TradeCenterId: 9,
    CategoryId: 348,
    CompanyName: "Công ty cổ phần Tư vấn Thiết kế Viettel",
    CategoryName: "Viễn thông",
    Price: 69.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTL",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Vang Thăng Long",
    CategoryName: "Hàng tiêu dùng",
    Price: 8,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTM",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "CTCP Vận tải và Đưa đón thợ mỏ - Vinacomin",
    CategoryName: "Công nghiệp",
    Price: 21.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTO",
    TradeCenterId: 1,
    CategoryId: 340,
    CompanyName: "Công ty Cổ phần Vận tải Xăng dầu VITACO",
    CategoryName: "Năng lượng",
    Price: 13.35,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTP",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Tổng Công ty cổ phần Bưu chính Viettel",
    CategoryName: "Dịch vụ",
    Price: 123,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTQ",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty Cổ phần Việt Trung Quảng Bình",
    CategoryName: "Nông nghiệp",
    Price: 38.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTR",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "CTCP Du lịch và Tiếp thị Giao thông Vận tải Việt Nam - Vietravel",
    CategoryName: "Dịch vụ",
    Price: 19.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTS",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Viglacera Từ Sơn",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 12.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTT",
    TradeCenterId: 2,
    CategoryId: 349,
    CompanyName: null,
    CategoryName: null,
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTV",
    TradeCenterId: 2,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Năng lượng và Môi trường VICEM",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTX",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Vận tải đa phương thức Vietranstimex",
    CategoryName: "Công nghiệp",
    Price: 8,
    TotalCompany: 1659,
  },
  {
    Symbol: "VTZ",
    TradeCenterId: 2,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Sản xuất và Thương mại Nhựa Việt Thành",
    CategoryName: "Nguyên vật liệu",
    Price: 15.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "VUA",
    TradeCenterId: 9,
    CategoryId: 341,
    CompanyName: "Công ty cổ phần Chứng khoán Stanley Brothers",
    CategoryName: "Tài chính",
    Price: 12.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "VVN",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Tổng Công ty cổ phần Xây dựng công nghiệp Việt Nam",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 4.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "VVS",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty cổ phần Đầu tư Phát triển máy Việt Nam",
    CategoryName: "Dịch vụ",
    Price: 16,
    TotalCompany: 1659,
  },
  {
    Symbol: "VW3",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "Công ty cổ phần Viwaseen3",
    CategoryName: "Công nghiệp",
    Price: 10.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "VWS",
    TradeCenterId: 9,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Nước và Môi trường Việt Nam",
    CategoryName: "Dịch vụ",
    Price: 19.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "VXB",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Vật liệu xây dựng Bến Tre",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 28.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "VXP",
    TradeCenterId: 9,
    CategoryId: 338,
    CompanyName: "Công ty cổ phần Thuốc Thú y Trung ương VETVACO",
    CategoryName: "Nông nghiệp",
    Price: 30.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "VXT",
    TradeCenterId: 9,
    CategoryId: 344,
    CompanyName: "CTCP Kho vận và Dịch vụ Thương mại",
    CategoryName: "Nguyên vật liệu",
    Price: 10.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "WCS",
    TradeCenterId: 2,
    CategoryId: 343,
    CompanyName: "Công ty Cổ phần Bến xe Miền Tây",
    CategoryName: "Công nghiệp",
    Price: 255,
    TotalCompany: 1659,
  },
  {
    Symbol: "WSB",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Bia Sài Gòn - Miền Tây",
    CategoryName: "Hàng tiêu dùng",
    Price: 50.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "WSS",
    TradeCenterId: 2,
    CategoryId: 341,
    CompanyName: "Công ty Cổ phần Chứng khoán Phố Wall",
    CategoryName: "Tài chính",
    Price: 4.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "WTC",
    TradeCenterId: 9,
    CategoryId: 343,
    CompanyName: "CTCP Vận tải thủy - Vinacomin",
    CategoryName: "Công nghiệp",
    Price: 11.2,
    TotalCompany: 1659,
  },
  {
    Symbol: "X20",
    TradeCenterId: 2,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần X20",
    CategoryName: "Hàng tiêu dùng",
    Price: 10.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "X26",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần 26",
    CategoryName: "Hàng tiêu dùng",
    Price: 26.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "X77",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Thành An 77",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 0.3,
    TotalCompany: 1659,
  },
  {
    Symbol: "XDC",
    TradeCenterId: 9,
    CategoryId: 0,
    CompanyName: "Công ty TNHH MTV Xây dựng Công trình Tân Cảng",
    CategoryName: "",
    Price: 65,
    TotalCompany: 1659,
  },
  {
    Symbol: "XDH",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư Xây dưng Dân dụng Hà Nội",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 13,
    TotalCompany: 1659,
  },
  {
    Symbol: "XHC",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty cổ phần Xuân Hòa Việt Nam",
    CategoryName: "Hàng tiêu dùng",
    Price: 23.9,
    TotalCompany: 1659,
  },
  {
    Symbol: "XLV",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xây lắp và Dịch vụ Sông Đà",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 9,
    TotalCompany: 1659,
  },
  {
    Symbol: "XMC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Đầu tư và Xây dựng Xuân Mai",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 6.8,
    TotalCompany: 1659,
  },
  {
    Symbol: "XMD",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty cổ phần Xuân Mai - Đạo Tú ",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 5,
    TotalCompany: 1659,
  },
  {
    Symbol: "XMP",
    TradeCenterId: 9,
    CategoryId: 340,
    CompanyName: "CTCP Thủy điện Xuân Minh",
    CategoryName: "Năng lượng",
    Price: 16,
    TotalCompany: 1659,
  },
  {
    Symbol: "XPH",
    TradeCenterId: 9,
    CategoryId: 339,
    CompanyName: "Công ty Cổ phần Xà phòng Hà Nội",
    CategoryName: "Hàng tiêu dùng",
    Price: 5.7,
    TotalCompany: 1659,
  },
  {
    Symbol: "YAN",
    TradeCenterId: 8,
    CategoryId: 346,
    CompanyName: "CTCP Công nghệ và Tầm nhìn Yêu âm nhạc",
    CategoryName: "Dịch vụ",
    Price: 0,
    TotalCompany: 1659,
  },
  {
    Symbol: "YBC",
    TradeCenterId: 9,
    CategoryId: 345,
    CompanyName: "Công ty Cổ phần Xi măng và Khoáng sản Yên Bái",
    CategoryName: "Bất động sản và Xây dựng",
    Price: 8.4,
    TotalCompany: 1659,
  },
  {
    Symbol: "YBM",
    TradeCenterId: 1,
    CategoryId: 344,
    CompanyName: "Công ty Cổ phần Khoáng sản Công nghiệp Yên Bái",
    CategoryName: "Nguyên vật liệu",
    Price: 10.6,
    TotalCompany: 1659,
  },
  {
    Symbol: "YEG",
    TradeCenterId: 1,
    CategoryId: 346,
    CompanyName: "Công ty Cổ phần Tập đoàn Yeah1",
    CategoryName: "Dịch vụ",
    Price: 11.1,
    TotalCompany: 1659,
  },
  {
    Symbol: "YTC",
    TradeCenterId: 9,
    CategoryId: 342,
    CompanyName: "Công ty Cổ phần Xuất nhập khẩu Y tế Thành phố Hồ Chí Minh",
    CategoryName: "Y tế",
    Price: 46.8,
    TotalCompany: 1659,
  },
];
