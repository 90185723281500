import { Card, Tabs } from "antd";
import { FundsProvider } from "../../context/FundsContext";
import ListFunds from "./ListFunds";
import AdvanceFunds from "./AdvanceFunds";

const Funds = () => {
  const items = [
    {
      key: "1",
      label: "Thông tin chung",
      children: <ListFunds />,
    },
    {
      key: "2",
      label: "Phân tích theo tháng",
      children: <AdvanceFunds />,
    },
  ];

  return (
    <FundsProvider>
      <Card bordered={false} title="Danh mục đầu tư các quỹ" styles={{ body: { padding: 0 } }}>
        <Tabs
          size="small"
          defaultActiveKey="2"
          items={items}
          style={{ padding: "0 1.5rem", margin: 0 }}
          tabBarStyle={{ margin: 0 }}
        />
      </Card>
    </FundsProvider>
  );
};

export default Funds;
