import { useState, useEffect, useCallback } from "react";
import { Table, Select, Space, Input, Spin, Flex, InputNumber } from "antd";
import { fetchDataWithGroupOrder } from "../../../services/realmServices";
import { formatNumberWithCommas, formatToPercentage } from "../../../services/common";
import { useFundsContext } from "../../../context/FundsContext";
import { mongoFundPortfoliosPivotScript } from "../../../utils/mongoScript";
import EChartCustom from "../../../components/common/EChartCustom";

const chartTypes = [
  {
    value: "line",
    label: "Line",
  },
  {
    value: "bar",
    label: "Bar",
  },
];

const queryTypesSample = {
  quantity: "quantity",
  market_price: "market_price",
  portfolio_rate: "portfolio_rate",
};

const queryTypes = [
  {
    value: "quantity",
    label: "Số lượng CP (n)",
  },
  {
    value: "market_price",
    label: "Gía thị trường (n)",
  },
  {
    value: "portfolio_rate",
    label: "Tỉ trọng (%)",
  },
];

const getGrowIndicator = (data) => {
  const currentMonth = new Date().getMonth();
  let count = 0;

  for (let i = currentMonth; i > 1; i--) {
    if (data[`${i}`] && data[`${i}`].quantity) {
      if (data[`${i - 1}`] && data[`${i - 1}`].quantity) {
        if (data[`${i}`].quantity > data[`${i - 1}`].quantity) {
          count++;
        } else {
          return count;
        }
      } else {
        return count + 1;
      }
    } else return count;
  }

  return count;
};

const AdvancePortfolios = ({ isReload }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({});

  const { fundsContext } = useFundsContext();

  const defaultYear = new Date().getFullYear();
  const [year, setYear] = useState(defaultYear);
  const [fundSelected, setFundSelected] = useState(null);
  const [type, setType] = useState("quantity");
  const [searchValue, setSearchValue] = useState("");
  const [chartType, setChartType] = useState("line");

  const fetchDataFromRealm = useCallback(async (year, fundCode, searchByStockCode) => {
    try {
      let queryObject = mongoFundPortfoliosPivotScript;

      const filterBy = { $match: { [`data_fund.status`]: 1 } };
      let hasFilter = false;

      if (year) {
        filterBy.$match.year = year;
        hasFilter = true;
      }

      if (fundCode) {
        filterBy.$match[`data_fund.fund_code`] = fundCode;
        hasFilter = true;
      }

      if (searchByStockCode) {
        filterBy.$match.stock_code = { $regex: searchByStockCode, $options: "i" };
        hasFilter = true;
      }

      if (hasFilter) queryObject = [filterBy, ...queryObject];

      setLoading(true);
      const result = await fetchDataWithGroupOrder("funds_portfolio", queryObject);
      if (result.length === 0) {
        setData([]);
      }

      const converedData = result.map((e, index) => {
        for (let i = 1; i <= 11; i++) {
          if (e[`${i}`]) {
            if (i === 1) e[`${i}`].prev = e[`${i}`];
            else if (e[`${i - 1}`]) e[`${i}`].prev = e[`${i - 1}`];
          }
        }

        const growIndicator = getGrowIndicator(e);

        return {
          ...e,
          growIndicator,
          key: index.toString(),
          index: index + 1,
          stock_code: e.stock_code,
          fund_code: e.fund_code,
        };
      });
      setData(converedData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDataFromRealm(year, fundSelected, searchValue);
  }, [isReload, year, fundSelected, searchValue, fetchDataFromRealm]);

  const handleChange = (_pagination, _filters, sorter) => {
    setSortedInfo(sorter);
  };

  const getMonthsHeader = () => {
    const header = [];
    for (let i = 1; i <= 11; i++) {
      header.push({
        title: `T${i.toString()}`,
        dataIndex: `${i.toString()}`,
        key: `${i.toString()}`,
        align: "center",
        ellipsis: true,
        render: (data) => {
          if (!data)
            return (
              <Flex vertical>
                <b>0</b>
                <b style={{ color: "white", fontSize: "0.6rem" }}>0</b>
              </Flex>
            );
          let dataShow = data[`${type}`];

          if (type === queryTypesSample.quantity) dataShow = formatNumberWithCommas(Math.floor(dataShow / 1000));
          else if (type === queryTypesSample.portfolio_rate) {
            dataShow = formatToPercentage(dataShow);
          } else {
            dataShow = formatNumberWithCommas(Math.floor(dataShow / 1000));
          }

          if (data.prev) {
            const change = Math.floor((data[`${type}`] - data.prev[`${type}`]) / 1000);
            if (change > 0) {
              return (
                <Flex vertical>
                  <b>{dataShow}</b>
                  <b style={{ color: "green", fontSize: "0.6rem" }}>+{change}</b>
                </Flex>
              );
            } else if (change === 0) {
              return (
                <Flex vertical>
                  <b>{dataShow}</b>
                  <b style={{ color: "green", fontSize: "0.6rem" }}>--</b>
                </Flex>
              );
            } else {
              return (
                <Flex vertical>
                  <b>{dataShow}</b>
                  <b style={{ color: "red", fontSize: "0.6rem" }}>{change}</b>
                </Flex>
              );
            }
          } else {
            return (
              <Flex vertical>
                <b>{dataShow}</b>
                <b style={{ color: "green", fontSize: "0.6rem" }}>+{dataShow}</b>
              </Flex>
            );
          }
        },
      });
    }

    return header;
  };

  const monthsHeader = getMonthsHeader();

  const columns = [
    {
      title: "I",
      dataIndex: "index",
      key: "index",
      align: "center",
      width: "4%",
      ellipsis: true,
      render: (_text, _record, index) => <b>{index + 1}</b>,
    },
    {
      title: "Mã",
      dataIndex: "stock_code",
      key: "stock_code",
      align: "center",
      width: "5%",
      ellipsis: true,
      render: (data) => <b>{data}</b>,
    },
    {
      title: "Quỹ",
      dataIndex: "fund_code",
      key: "fund_code",
      align: "center",
      width: "6%",
      ellipsis: true,
      render: (data) => <b>{data}</b>,
    },
    ...monthsHeader,
    {
      title: "Chart",
      dataIndex: "index",
      key: "index",
      align: "center",
      ellipsis: true,
      width: "15%",
      fixed: "left",
      render: (index) => {
        const rowData = data[index - 1];
        const dataChart = {
          xAxis: [],
          series: [],
        };

        for (let i = 1; i <= 11; i++) {
          dataChart.xAxis.push(`T${i}`);
          if (rowData && rowData[i] && rowData[i][type]) {
            if (type === queryTypesSample.portfolio_rate) dataChart.series.push(Math.floor(rowData[i][type] * 100));
            else if (type === queryTypesSample.quantity) dataChart.series.push(Math.floor(rowData[i][type] / 1000));
            else dataChart.series.push(Math.floor(rowData[i][type] / 1000));
          } else dataChart.series.push(1);
        }

        return <EChartCustom data={dataChart} type={chartType} style={{ height: "5rem", width: "100%" }} />;
      },
    },
    {
      title: "C",
      dataIndex: "growIndicator",
      key: "growIndicator",
      align: "center",
      ellipsis: true,
      sorter: (a, b) => a.growIndicator - b.growIndicator,
      sortOrder: sortedInfo.columnKey === "growIndicator" ? sortedInfo.order : null,
    },
  ];

  return (
    <>
      <Flex justify="space-between">
        <Space size={12}>
          <Select
            allowClear
            showSearch
            placeholder="Quỹ đầu tư"
            onChange={(value) => setFundSelected(value)}
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            options={fundsContext}
            style={{ width: 250 }}
          />
          <InputNumber defaultValue={defaultYear} onChange={(value) => setYear(value)} />
          <Input
            placeholder="Mã cổ phiếu"
            size="small"
            style={{ width: 150, height: "2rem", fontWeight: "400" }}
            onPressEnter={(e) => setSearchValue(e.target.value)}
          />
          <Select
            placeholder="Loại thông tin"
            defaultValue={"quantity"}
            onChange={(value) => setType(value)}
            options={queryTypes}
            style={{ width: 150 }}
          />
          <Select
            placeholder="Loại biểu đồ"
            defaultValue={"line"}
            onChange={(value) => setChartType(value)}
            options={chartTypes}
            style={{ width: 100 }}
          />
          {loading && <Spin />}
        </Space>

        <h4>Tổng: {data.length}</h4>
      </Flex>
      <Table
        columns={columns}
        dataSource={data}
        onChange={handleChange}
        className="ant-border-space"
        pagination={false}
        scroll={{
          y: "80vh",
        }}
      />
    </>
  );
};
export default AdvancePortfolios;
